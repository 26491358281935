import { Outlet, useParams } from "react-router-dom";
import * as Icons from "../../abk-shared/interfaces/abk9SvgIcons";
import {
  DATABASE_PLACEHOLDER,
  LOCAL_BROWSER_CONFIG,
  USER_PROFILE,
} from "../../core/constants/urls";
import useDrawerStore from "../../core/stores/useDrawerStore";
import React, { useEffect } from "react";
import useAppBarState from "../../core/stores/useAppBarState";
import useGetVersion from "../Startseite/hooks/useGetVersion";
import { changeFavIcon } from "../../core/utility/favIcon";
import WrapperTemplate from "../../core/components/templates/WrapperTemplate";

const UserLayout: React.FC = () => {
  const db = useParams().db;
  const { setAppBarTitle } = useAppBarState();
  const { setCurrentDrawerItems } = useDrawerStore();

  const { data: sysinfo } = useGetVersion();

  useEffect(() => {
    const drawerItems = [
      {
        text: "Profil",
        svgIcon: Icons.Menu_User,
        route: USER_PROFILE.replace(DATABASE_PLACEHOLDER, db!),
        title: "Profil",
      },
      {
        text: "Lokale Browsereinstellung",
        svgIcon: Icons.Menu_Admin_DatabaseInfo,
        route: LOCAL_BROWSER_CONFIG.replace(DATABASE_PLACEHOLDER, db!),
        title: "Lokale Browsereinstellung",
      },
    ];

    setCurrentDrawerItems(drawerItems);
    changeFavIcon("/assets/favicons/Menu.User.Tabicon.svg");
  }, [db, setCurrentDrawerItems]);

  useEffect(() => {
    if (sysinfo) {
      setAppBarTitle(`Benutzer  ${sysinfo.AuthUser}`);
    } else {
      setAppBarTitle("Benutzerprofil");
    }
  }, [setAppBarTitle, sysinfo]);

  return (
    <>
      <WrapperTemplate includeTitle includeDescription>
        <Outlet />
      </WrapperTemplate>
    </>
  );
};

export default UserLayout;
