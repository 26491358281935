import { NumericTextBox } from "@progress/kendo-react-inputs";
import React from "react";
import ABKFormFieldInputWrapper from "../ABKFormFieldInputWrapper";
import { ABK_READ_ONLY } from "../../../theme/customClassNames";
import ABKLabel from "../ABKLabel";

interface AbkFormFieldInputProps {
  editorId: string;
  label: string;
  value: number | null;
  setValue: (value: number | null) => void;
  readOnly: boolean;
}

const ABKNumericInput: React.FC<AbkFormFieldInputProps> = (props) => {
  return (
    <ABKFormFieldInputWrapper>
      <ABKLabel editorId={props.editorId} className="k-d-flex">
        {props.label}
      </ABKLabel>
      <NumericTextBox
        className={props.readOnly ? ABK_READ_ONLY : ""}
        id={props.editorId}
        name={props.editorId}
        value={props.value}
        onChange={(e) => props.setValue(e.target.value)}
        readOnly={props.readOnly}
        spinners={!props.readOnly}
        // Defaultmäßig sind Kommas als Separator verwendet, das ist ein US-Standard, nicht österreichisch
        format={{ useGrouping: false }}
      />
    </ABKFormFieldInputWrapper>
  );
};

export default ABKNumericInput;
