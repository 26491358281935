import {
  DropDownList,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { ExpansionPanelActionEvent } from "@progress/kendo-react-layout";
import ABKControlledExpansionPanel from "../../../../../abk-shared/components/atoms/ABKControlledExpansionPanel";
import ABKFormFieldInput from "../../../../../abk-shared/components/atoms/ABKFormFieldInput";
import ABKFormFieldInputWrapper from "../../../../../abk-shared/components/atoms/ABKFormFieldInputWrapper";
import ABKFormRow from "../../../../../abk-shared/components/atoms/ABKFormRow";
import ABKInfoPopup from "../../../../../abk-shared/components/atoms/ABKInfoPopup";
import ABKLabel from "../../../../../abk-shared/components/atoms/ABKLabel";
import { formatDateTimeToBrowserDefault } from "../../../../../abk-shared/utility/dateUtils";
import { Cont } from "../../../../../core/interfaces/Cont";
import { Versioned } from "../../../../../core/interfaces/Versioned";

interface DetailsPanelProps {
  currentForm: Versioned<Cont>;
  container: Cont | undefined;
  lastChanged: string | undefined;
  dropdownProps: {
    options: {
      value: string;
      label: string;
    }[];
    handleLockStateChange: (event: DropDownListChangeEvent) => void;
  };
  isExpanded: boolean;
  onAction: ((event: ExpansionPanelActionEvent) => void) | undefined;
}

const DetailsPanel = ({
  currentForm,
  container,
  lastChanged,
  dropdownProps,
  isExpanded,
  onAction,
}: DetailsPanelProps) => {
  return (
    <ABKControlledExpansionPanel
      expanded={isExpanded}
      title={<p style={{ margin: "0" }}>Details</p>}
      key="details"
      className="detail-section"
      onAction={onAction}
    >
      {isExpanded && (
        <>
          <ABKFormRow>
            <ABKFormFieldInput
              editorId="besitzer"
              label="Besitzer"
              value={currentForm?.value.OWNER || container?.OWNER}
            />
            <ABKFormFieldInput
              editorId="containerId"
              label="Container ID"
              value={currentForm?.value.CONTID || container?.CONTID}
              tooltip="Eindeutige interne ID der ABK-Datei"
            />
          </ABKFormRow>

          <ABKFormRow>
            <ABKFormFieldInput
              editorId="letzteAenderung"
              label="Letzte Änderung"
              value={
                lastChanged ? formatDateTimeToBrowserDefault(lastChanged) : ""
              }
            />
            <ABKFormFieldInput
              editorId="containertyp"
              label="Container Typ"
              value={currentForm?.value.CONTTYPE || container?.CONTTYPE}
              tooltip="Interner Typ-Code der ABK Datei"
            />
          </ABKFormRow>

          <ABKFormRow isHalfRow={true}>
            <ABKFormFieldInputWrapper>
              <ABKLabel editorId="lockstate">
                Sperrstatus
                <ABKInfoPopup popupContent={"Das Verwenden der ABK-Datei kann eingeschränkt werden. Bei Sperre und Hinweis wird die Notiz beim Öffnen angezeigt."} />
              </ABKLabel>
              <DropDownList
                id="lockstate"
                name="lockstate"
                data={dropdownProps.options}
                textField="label"
                dataItemKey="value"
                value={dropdownProps.options.find(
                  (option) => option.value === currentForm.value.LOCKSTATE
                )}
                onChange={dropdownProps.handleLockStateChange}
              />
            </ABKFormFieldInputWrapper>
          </ABKFormRow>
        </>
      )}
    </ABKControlledExpansionPanel>
  );
};

export default DetailsPanel;
