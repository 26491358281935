import { ABKPageLoader } from "../../../../abk-shared/components/atoms/ABKPageLoader";
import "./style.css";
import axios, { AxiosRequestConfig } from "axios";
import { useQuery } from "@tanstack/react-query";

const ServerInfoPage = () => {
  const config: AxiosRequestConfig = { headers: { Accept: "text/html" } };

  const { data, isLoading } = useQuery({
    queryKey: ["sysinfoHTML"],
    queryFn: async () => axios.get("/api/sysinfo", config),
  });

  if (isLoading) {
    return <ABKPageLoader />;
  }

  if (!data) {
    return <div>Keine Daten gefunden</div>;
  }

  return (
    <div style={{ width: "100%" }}>
      <div dangerouslySetInnerHTML={{ __html: data.data.toString() }} />
    </div>
  );
};

export default ServerInfoPage;
