import React from "react";
import MobileTemplate from "./MobileTemplate";
import MainPageTemplate from "./MainPageTemplate";
import useIsMobileTemplate from "../../../abk-shared/utility/useIsMobileTemplate";

export interface WrapperTemplateProps {
  children?: React.ReactNode;
  includeTitle: boolean;
  includeDescription: boolean;
  appBarIncludesDropDown?: boolean;
  isDesktop?: boolean;
}

const WrapperTemplate = ({ children }: WrapperTemplateProps) => {
  const isMobileTemplate = useIsMobileTemplate();

  if (!isMobileTemplate) {
    return (
      <MainPageTemplate includeTitle includeDescription>
        {children}
      </MainPageTemplate>
    );
  }

  return (
    <MobileTemplate includeDescription includeTitle>
      {children}
    </MobileTemplate>
  );
};

export default WrapperTemplate;
