import { Button } from "@progress/kendo-react-buttons";
import ABKNotificationCounter, {
  ABKNotificationCounterProps,
} from "../../../../abk-shared/components/atoms/ABKNotificationCounter";
import { Loader } from "@progress/kendo-react-indicators";

interface InsideNotificationProps {
  containerName: string | React.ReactNode;
  jobText: string;
  hasLoader?: boolean;
  counterProps?: ABKNotificationCounterProps;
  buttonProps?: {
    text: string;
    onClick: () => void;
  };
}

const InsideNotification = ({
  containerName,
  jobText,
  hasLoader = false,
  counterProps,
  buttonProps,
}: InsideNotificationProps) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: 8,
      }}
    >
      {hasLoader && (
        <span
          className="k-icon k-svg-icon k-svg-i-check-outline k-notification-status"
          aria-hidden="true"
        >
          <Loader type="infinite-spinner" size="small" />
        </span>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <span className="notification-message">
          {containerName}
          <br />
          <span style={{ fontStyle: "italic" }}>{jobText}</span>
        </span>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          {counterProps ? (
            <ABKNotificationCounter {...counterProps} />
          ) : (
            <div style={{ height: "1rem" }} />
          )}
          {buttonProps && (
            <Button
              themeColor="primary"
              onClick={(event) => {
                event.stopPropagation();
                buttonProps.onClick();
              }}
            >
              {buttonProps.text}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default InsideNotification;
