import ABKExpansionPanel from "../../../../../../abk-shared/components/atoms/ABKExpansionPanel";
import ABKFormCheckbox from "../../../../../../abk-shared/components/atoms/ABKFormCheckbox";
import ABKFormFieldInput from "../../../../../../abk-shared/components/atoms/ABKFormFieldInput";
import ABKForm from "../../../../../../abk-shared/components/molecules/ABKForm";
import ABKMultipleInputsLine from "../../../../../../abk-shared/components/molecules/ABKMultipleInputsLine";
import { Eintrag } from "../../../../interfaces/parameterlisten";
import { useEintragBearbeitenState } from "../useEintragBearbeitenState";

const ParameterPanel = () => {
  const { pageState, setPageState } = useEintragBearbeitenState();

  return (
    <ABKExpansionPanel title="Parameter">
      <ABKForm
        sections={[
          {
            formElements: [
              <ABKMultipleInputsLine
                elementLeft={
                  <ABKFormFieldInput
                    editorId={"VARNAME"}
                    label={"Parameterkennung"}
                    value={pageState?.VARNAME}
                    setValue={(value: string) => {
                      setPageState({ ...pageState, VARNAME: value } as Eintrag);
                    }}
                  />
                }
                elementRight={
                  <ABKFormCheckbox
                    editorId={"REQUIRED"}
                    label={"Eingabe erforderlich"}
                    value={pageState?.REQUIRED}
                    setValue={(value: boolean) => {
                      setPageState({
                        ...pageState,
                        REQUIRED: value,
                      } as Eintrag);
                    }}
                  />
                }
              />,

              <ABKMultipleInputsLine
                elementLeft={
                  <ABKFormCheckbox
                    editorId={"FAVORIT"}
                    label={"Favorit"}
                    value={pageState?.FAVORIT}
                    setValue={(value: boolean) => {
                      setPageState({ ...pageState, FAVORIT: value } as Eintrag);
                    }}
                  />
                }
              />,
            ],
          },
        ]}
      />
    </ABKExpansionPanel>
  );
};

export default ParameterPanel;
