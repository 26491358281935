import React from "react";
import {
  Splitter,
  SplitterOnChangeEvent,
  SplitterPaneProps,
} from "@progress/kendo-react-layout";
import Sidebar, { FunctionOnNotificationClick } from "./SideBar";
import { INotification } from "./NotificationBox";
import useLocalStorageState from "use-local-storage-state";

interface ABKSideBarProps {
  leftSideBarContent: React.ReactNode;
  notifications: INotification[];
  onCloseNotification: (notification: INotification) => void;
  infoHubOptions?: React.ReactNode;
  onNotificationClick?: FunctionOnNotificationClick;
}

const ABKSideBar: React.FC<ABKSideBarProps> = ({
  leftSideBarContent,
  notifications,
  onCloseNotification,
  infoHubOptions,
  onNotificationClick,
}: ABKSideBarProps) => {
  const [panes, setPanes] = useLocalStorageState<Array<SplitterPaneProps>>(
    "SidebarState",
    {
      defaultValue: [
        { collapsible: false },
        { size: "20rem", min: "150px", max: "80%", collapsible: false },
      ],
    }
  );

  const onChange = (event: SplitterOnChangeEvent) => {
    setPanes(event.newState);
  };

  return (
    <Splitter panes={panes} onChange={onChange}>
      <div className="pane-content">{leftSideBarContent}</div>
      <Sidebar
        notifications={notifications}
        onCloseNotification={onCloseNotification}
        infoHubOptions={infoHubOptions}
        onNotificationClick={onNotificationClick}
      />
    </Splitter>
  );
};

export default ABKSideBar;
