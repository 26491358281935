import React from "react";
import { GridColumnPropsCustom } from "../../interfaces/GridColumns";
import { KEY_REMOVE_FROM_FILTERING } from "../../constants";
import { forEachColumns } from "../useGridColumns/columnUtils";

export default function useFieldsFiltered(columns: GridColumnPropsCustom[]) {
  return React.useMemo(() => {
    const result: string[] = [];

    forEachColumns(columns, (column, hasChildren) => {
      if (hasChildren) return;

      const shouldSkipColumn =
        column.hasOwnProperty(KEY_REMOVE_FROM_FILTERING) &&
        column[KEY_REMOVE_FROM_FILTERING];
      if (shouldSkipColumn) return;

      if (column.field) result.push(column.field);
    });

    return result;
  }, [columns]);
}
