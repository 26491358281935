import { useQuery } from "@tanstack/react-query";
import { Cont } from "../../../core/interfaces/Cont";
import { BACKEND_URL } from "../../../core/constants/urls";
import axios from "axios";

export interface ApiResponse {
  Meta: string | null;
  Items: Cont[];
}

export const QUERY_KEY_GET_CONTAINER = "Container";

export enum RequestType {
  "AlleVorlagen" = "vorl_all",
  "ZuletztVerwendeteVorlagen" = "vorl_lastused",
  "ZuletztVerwendeteContainer" = "lastused",
  "AlleContainer" = "all",
  "MeineContainer" = "my",
  "MeineFavoriten" = "favs",
}

const useGetCont = (
  datenbank: string | undefined,
  requestType: RequestType = RequestType.AlleContainer
) => {
  const fetchCont = async (): Promise<Cont[]> => {
    try {
      const url = `${BACKEND_URL}/api/db/${datenbank}/cont?kind=${requestType}`;
      const response = await axios.get(url);
      const data: ApiResponse = response.data;

      return data.Items; // Return the array of DB directly
    } catch (error) {
      console.error("Fetching container failed:", error);
      throw error;
    }
  };

  return useQuery({
    queryKey: [datenbank, QUERY_KEY_GET_CONTAINER, requestType],
    queryFn: fetchCont,
    staleTime: 0, // Fetch data immediately
    enabled: !!datenbank,
  });
};

export default useGetCont;
