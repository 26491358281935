import { useEffect } from "react";
import useGetDB from "../../../../../modules/Startseite/hooks/useGetDB";
import useCurrentDBStore from "../../../../stores/useCurrentDBStore";
import "./dropdown.css";
import { DATABASE_PLACEHOLDER, OPEN } from "../../../../constants/urls";
import ABKPopUpButton from "../../../molecules/ABKPopUpButton";
import { SvgIcon } from "@progress/kendo-react-common";
import * as Icons from "../../../../../abk-shared/interfaces/abk9SvgIcons";
import ABKNavigationButtonItem from "../../../../../abk-shared/components/atoms/ABKNavigationButtonItem";
import { useNavigate } from "react-router-dom";

const DBDropDown = ({ isDesktop }: { isDesktop: boolean }) => {
  const { data: databases } = useGetDB();
  const { currentDB, setCurrentDB } = useCurrentDBStore();
  const navigate = useNavigate();

  useEffect(() => {
    const storedDB = localStorage.getItem("currentDB");
    if (
      storedDB &&
      databases &&
      databases.some((item) => item.DBNAME === storedDB)
    ) {
      const storedDBObject = databases.find((item) => item.DBNAME === storedDB);
      setCurrentDB(storedDBObject!);
    } else {
      if (databases && databases.length > 0) {
        setCurrentDB(databases[0]);
      }
    }
  }, [databases, setCurrentDB]);

  return (
    <ABKPopUpButton
      toolTipText="Aktive Datenbank"
      buttonIsAnchor={true}
      insideButtonElement={
        isDesktop ? (
          <span>{currentDB?.DBNAME}</span>
        ) : (
          <SvgIcon icon={Icons.Data_Datatype_DB} height={24} width={24} />
        )
      }
      hasBorderRight={true}
      insidePopupElement={
        <div style={{ display: "flex", flexDirection: "column" }}>
          {databases?.map((item, index) => (
            <div
              key={index}
              onClick={() => {
                const storedDBObject = databases.find(
                  (db) => db.DBNAME === item.DBNAME
                );
                setCurrentDB(storedDBObject!);
              }}
            >
              <ABKNavigationButtonItem
                text={item.DBNAME}
                onClick={() => {
                  navigate(OPEN.replace(DATABASE_PLACEHOLDER, item.DBNAME));
                }}
                icon="Data.Datatype.DB"
                active={currentDB?.DBNAME === item.DBNAME}
              />
            </div>
          ))}
        </div>
      }
    />
  );
};

export default DBDropDown;
