import { create } from "zustand";

interface MainPageTemplateState {
  mainPageTemplateTitle: string | React.ReactElement;
  setMainPageTemplateTitle: (title: string | React.ReactElement) => void;
  mainPageTemplateDescription: string;
  setMainPageTemplateDescription: (description: string) => void;
}

export const MAIN_PAGE_TEMPLATE = {
  TITLE: "Datei öffnen",
  DESCRIPTION:
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae numquam sequi pariatur aperiam illo expedita quod ratione alias, provident quis eius tempora beatae reprehenderit omnis temporibus nesciunt repellat? Reprehenderit, itaque?",
};

const useMainPageTemplateState = create<MainPageTemplateState>((set) => ({
  mainPageTemplateTitle: MAIN_PAGE_TEMPLATE.TITLE,
  setMainPageTemplateTitle: (title: string | React.ReactElement) => {
    set(() => ({ mainPageTemplateTitle: title }));
  },
  mainPageTemplateDescription: MAIN_PAGE_TEMPLATE.DESCRIPTION,
  setMainPageTemplateDescription: (description: string) => {
    set(() => ({ mainPageTemplateDescription: description }));
  },
}));

export default useMainPageTemplateState;
