import React from "react";
import ReactDOM from "react-dom/client";
import "./style/index.scss";
import reportWebVitals from "./reportWebVitals";
import {
  loadMessages,
  LocalizationProvider,
  LocalizationService,
} from "@progress/kendo-react-intl";
import { deMessages } from "./core/assets/messages/de";
import AppLayout from "./AppLayout";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

class CustomLocalizationService extends LocalizationService {
  toLanguageString(key: string, defaultValue: string) {
    return super.toLanguageString(key, defaultValue);
  }
}

class CustomLocalizationProvider extends LocalizationProvider {
  getLocalizationService() {
    return new CustomLocalizationService(this.props.language);
  }
}

loadMessages(deMessages, "de");

root.render(
  <React.StrictMode>
    <CustomLocalizationProvider language="de">
      <AppLayout />
    </CustomLocalizationProvider>
  </React.StrictMode>
);

reportWebVitals();
