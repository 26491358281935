import { Input } from "@progress/kendo-react-inputs";
import React from "react";
import ABKFormFieldInputWrapper from "../ABKFormFieldInputWrapper";
import ABKInfoPopup from "../ABKInfoPopup";
import ABKLabel from "../ABKLabel";

interface AbkFormFieldInputProps {
  editorId: string;
  tooltip?: string;
  title?: string;
  label: string;
  placeHolder?: string;
  value: string | undefined | React.ReactElement;
  setValue?: (value: string) => void;
  children?: React.ReactNode;
  classNameLabel?: string;
  useJSXForValue?: boolean;
}

const ABKFormFieldInput: React.FC<AbkFormFieldInputProps> = (props) => {
  const readOnly = !props.setValue;

  return (
    <ABKFormFieldInputWrapper>
      <ABKLabel editorId={props.editorId}>
        {props.label}
        {props.children}
        {props.tooltip && 
        <ABKInfoPopup popupContent={props.tooltip} />
        }
      </ABKLabel>
      {readOnly && props.useJSXForValue ? (
        <span className={props.classNameLabel}>{props.value}</span>
      ) : (
        <div>
          <Input
            type="text"
            className={props.classNameLabel}
            id={props.editorId}
            name={props.editorId}
            placeholder={readOnly ? "" : props.placeHolder}
            title={props.value as string | undefined}
            value={props.value as string | undefined}
            {...(readOnly
              ? { readOnly: true }
              : {
                  onChange: (e) =>
                    props.setValue && props.setValue(e.target.value as string),
                })}
          />
        </div>
      )}
    </ABKFormFieldInputWrapper>
  );
};

export default ABKFormFieldInput;
