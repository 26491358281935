import { BACKEND_URL } from "../../constants/urls";
import { useQuery } from "@tanstack/react-query";
import fetchJobs from "./fetchJobs";

export const QUERY_KEY_JOBS = "Jobs";

const useGetAllJobs = (
  database: string | undefined,
  timeFilter: string,
  refetchInterval: number | false = false
) => {
  /*
    Gets all the Jobs of a specific database from the backend.

    @param database: The database name.
    @param timeFilter: The time filter.
  */

  const url = `${BACKEND_URL}/api/db/${database}/jobs?since=${timeFilter}`;

  return useQuery({
    queryKey: [database, QUERY_KEY_JOBS],
    queryFn: () => fetchJobs(url),
    enabled: !!database,
    refetchInterval: refetchInterval,
  });
};

export default useGetAllJobs;
