import { BreadcrumbLinkProps } from "@progress/kendo-react-layout";
import { Link } from "react-router-dom";
import { DATABASE_PLACEHOLDER } from "../../../constants/urls";

function createBreadcrumbLink(
  event: BreadcrumbLinkProps,
  database: string | undefined,
  contId: string | undefined
) {
  const breadcrumbId = event.id;
  const breadcrumb = (event as any).data.find(
    (breadcrumb: any) => breadcrumb.id === breadcrumbId
  );

  const disabled = event.disabled;
  return (
    <Link
      to={createLinkURL(breadcrumb?.path, database, contId)}
      aria-current={disabled}
      role="link"
      id={breadcrumbId}
      tabIndex={disabled ? -1 : 0}
      className={`k-breadcrumb-root-link k-breadcrumb-icontext-link ${disabled ? "k-disabled" : ""}`}
      dir="ltr"
      aria-disabled={disabled}
    >
      {event.icon}
      <span className="k-breadcrumb-item-text">{event.text}</span>
    </Link>
  );
}

function createLinkURL(
  breadcrumbPath: string | undefined,
  database: string | undefined,
  contId: string | undefined
) {
  const linkDefaultURL = "#";
  if (!breadcrumbPath) return linkDefaultURL;

  let linkURL = breadcrumbPath;

  if (linkURL.includes(DATABASE_PLACEHOLDER)) {
    if (!database) database = "_default_";
    linkURL = linkURL.replace(DATABASE_PLACEHOLDER, database);
  }

  if (!linkURL.includes("%contId%")) return linkURL;

  if (!contId) return linkDefaultURL;
  return linkURL.replace("%contId%", contId);
}

export default createBreadcrumbLink;
