import { getter } from "@progress/kendo-data-query";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { Grid, GridCustomCellProps } from "@progress/kendo-react-grid";
import { loadMessages, LocalizationProvider } from "@progress/kendo-react-intl";
import { debounce } from "lodash";
import React from "react";
import { ABKBasisGridProps } from ".";
import ABKBasisGridContextMenu from "./components/ABKBasisGridContextMenu";
import ABKBasisGridToolbar from "./components/ABKBasisGridToolbar";
import CustomCell from "./components/CustomCell";
import HeaderCustomCell from "./components/HeaderCustomCell";
import LoadingPanel from "./components/LoadingPanel";
import createColumns from "./createColumns";
import { getSelectedItems } from "./utils/helperFunctions";
import useExcelExport from "./hooks/useExcelExport";
import { SELECTED_FIELD } from "./hooks/useGridColumns/COLUMN_SELECTED_FIELD";
import useGridColumns from "./hooks/useGridColumns/useGridColumns";
import useGridContextMenu from "./hooks/useGridContextMenu";
import useGridDataState from "./hooks/useGridDataState/useGridDataState";
import useGridRowSelection from "./hooks/useGridRowSelection";
// Diese Datei war importiert von: https://github.com/telerik/kendo-react-messages/blob/master/messages/de-DE/de-DE.json
import germanMessages from "./de-DE.json";
import { OnRefChangeType } from "react-resize-detector/build/types/types";
import VirtualizedMobileGridRows from "./components/VirtualizedMobileGridRows";
import MessageNoRecords from "./components/MessageNoRecords";
import GroupHeaderCustomCell from "./components/GroupHeaderCustomCell";
import { isContextMenuOnGroupedColumnHeader } from "./utils/groupingFunctions";
import useFieldsWithInternalValue from "./hooks/useFieldsWithInternalValue";

// Inspired from: https://www.telerik.com/kendo-react-ui/components/grid/globalization/
const language = "de-DE";
loadMessages(germanMessages, language);

type Props = ABKBasisGridProps & {
  isMobileGrid: boolean;
  wrapperGridRef: OnRefChangeType<HTMLDivElement>;
  isGroupable?: boolean;
  sortable?: boolean;
};
export default function BasisGrid({
  data,
  columnsDefinition,
  gridId,
  dataItemKey,
  mobileGridRow,
  gridActions,
  onSelectionChange,
  isLoading,
  customCells,
  wrapperGridRef,
  isMobileGrid,
  groupable = true,
  sortable = true,
}: Props) {
  const idGetter = React.useMemo(() => getter(dataItemKey), [dataItemKey]);
  const gridRowSelection = useGridRowSelection(
    data,
    dataItemKey,
    idGetter,
    onSelectionChange
  );

  const columns = columnsDefinition.columns;

  const gridDataState = useGridDataState(
    data,
    columns,
    gridId,
    gridRowSelection.updateSelectedStateAfterFilter
  );

  const isSelectionEnabled = gridActions !== undefined;
  const gridColumns = useGridColumns(
    gridId,
    columnsDefinition,
    wrapperGridRef,
    gridDataState.dataState,
    isSelectionEnabled,
    isMobileGrid
  );

  const headerSelectionValue = gridRowSelection.checkHeaderSelectionValue(
    gridDataState.dataResult.total
  );
  if (isSelectionEnabled) {
    const columnWithCheckbox = gridColumns.columnsDisplayed[0];
    columnWithCheckbox.headerSelectionValue = headerSelectionValue;
  }

  const gridContextMenu = useGridContextMenu(
    gridRowSelection.setDataItemSelected
  );

  const selectedItems = getSelectedItems(
    data,
    gridRowSelection.selectedState,
    idGetter
  );
  const dataResultWithSelectedField =
    gridRowSelection.addSelectedFieldToDataResult(gridDataState.dataResult);

  const excelExport = useExcelExport(
    gridColumns.columnsDisplayed,
    dataResultWithSelectedField.data,
    gridDataState.dataState
  );

  const fieldsWithInternalValue = useFieldsWithInternalValue(columns);

  return (
    <>
      {isLoading && <LoadingPanel />}
      <ABKBasisGridToolbar
        selectedItems={selectedItems}
        filterValue={gridDataState.filterValue}
        onFilterChange={gridDataState.onFilterChange}
        gridActions={gridActions}
        excelExport={{
          disabled: dataResultWithSelectedField.total === 0,
          excelExport: excelExport.excelExport,
        }}
        isMobileGrid={isMobileGrid}
        headerSelectionValue={headerSelectionValue}
        onHeaderSelectionChange={(event) =>
          gridRowSelection.onHeaderSelectionChange(
            event,
            dataResultWithSelectedField.data
          )
        }
        isSelectionEnabled={isSelectionEnabled}
        columns={gridColumns.columns}
        onColumnToggleVisibility={gridColumns.onColumnToggleVisibility}
      />
      <LocalizationProvider language={language}>
        <ExcelExport
          data={dataResultWithSelectedField.data}
          ref={excelExport.ref}
          group={gridDataState.dataState.group}
        >
          {excelExport.columns}
        </ExcelExport>
        {isMobileGrid && mobileGridRow ? (
          dataResultWithSelectedField.total === 0 ? (
            <MessageNoRecords />
          ) : (
            <VirtualizedMobileGridRows
              dataItems={dataResultWithSelectedField.data}
              mobileGridRow={mobileGridRow}
              filterValue={gridDataState.filterValue}
              setDataItemSelected={gridRowSelection.setDataItemSelected}
            />
          )
        ) : (
          <Grid
            className="grid-table"
            data={dataResultWithSelectedField}
            {...gridDataState.dataState}
            onDataStateChange={gridDataState.onDataStateChange}
            sortable={
              sortable
                ? {
                    mode: "multiple",
                  }
                : false
            }
            resizable={true}
            onColumnResize={debounce(gridColumns.onColumnResize, 200)}
            reorderable={true}
            onColumnReorder={gridColumns.onColumnReorder}
            size="small"
            selectable={{
              enabled: true,
              drag: false,
              cell: false,
              mode: "multiple",
            }}
            groupable={groupable}
            onExpandChange={gridDataState.onExpandChange}
            expandField="expanded"
            dataItemKey={dataItemKey}
            selectedField={SELECTED_FIELD}
            onSelectionChange={(event) => {
              gridRowSelection.onSelectionChange(event);
              gridContextMenu.setShow(false);
            }}
            onHeaderSelectionChange={(event) =>
              gridRowSelection.onHeaderSelectionChange(
                event,
                dataResultWithSelectedField.data
              )
            }
            onContextMenu={(event) => {
              /*
                Wir wollen vermeiden, dass ein ContextMenü erscheint, wenn wir
                auf ein Grouped Column Header klicken.
                https://ib-data.atlassian.net/browse/ABK9-774
              */
              if (isContextMenuOnGroupedColumnHeader(event)) return;

              gridContextMenu.handleContextMenuOpen(
                event.syntheticEvent,
                event.dataItem
              );
            }}
            cells={{
              headerCell: (props) => (
                <HeaderCustomCell {...props} columns={columns} />
              ),
              group: {
                groupHeader: (props) => (
                  <GroupHeaderCustomCell {...props} columns={columns} />
                ),
              },
              data: (props: GridCustomCellProps) => (
                <CustomCell
                  {...props}
                  columns={columns}
                  filterValue={gridDataState.filterValue}
                  customCells={customCells}
                />
              ),
            }}
            scrollable="virtual"
            rowHeight={33.8}
            style={{ height: "auto" }}
            total={gridDataState.dataResult.total}
          >
            {gridColumns.columnsDisplayed.map((parentColumn) =>
              createColumns(
                "gridColumn",
                parentColumn,
                data,
                fieldsWithInternalValue,
                gridColumns.setWidth
              )
            )}
          </Grid>
        )}
      </LocalizationProvider>
      <ABKBasisGridContextMenu
        gridContextMenu={gridContextMenu}
        selectedItems={selectedItems}
        gridActions={gridActions}
      />
    </>
  );
}
