import axios from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEY_GET_CONTAINER } from "./useGetCont";
import { ContWithLastChangedModified } from "../../../core/interfaces/Cont";
import { BACKEND_URL } from "../../../core/constants/urls";

const useDeleteCont = (database: string | undefined) => {
  const queryClient = useQueryClient();
  const url = `${BACKEND_URL}/api/db/${database}/cont`;

  return useMutation({
    mutationFn: async (container: ContWithLastChangedModified) => {
      const containerId = container.CONTID;
      return axios.delete(`${url}/${containerId}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [database, QUERY_KEY_GET_CONTAINER],
      });
    },
  });
};

export default useDeleteCont;
