import getContainerNameFromJob from "../../../../molecules/ABKJobNotification/getContainerNameFromJob";
import { InfoItem } from "../../../../../../abk-shared/components/organisms/ABKSideBar/QuickInfo";
import { ABKError } from "../../../../../../abk-shared/interfaces/Error";
import { formatDateTimeToBrowserDefault } from "../../../../../../abk-shared/utility/dateUtils";
import { Job, translateJobStatus } from "../../../../../interfaces/Job";
import { createQuickInfoForABKError } from "../createQuickInfoForError";

export default function createQuickInfoForJob(job: Job) {
  const quickInfo: InfoItem[] = [
    {
      title: "Dateiname",
      content: getContainerNameFromJob(job),
    },
    { title: "Job-art", content: job.DESCRIPTION },
    { title: "Status", content: translateJobStatus(job.STATUS) },
    { title: "Besitzer", content: job.OWNER },
    {
      title: "Startdatum",
      content: formatDateTimeToBrowserDefault(job.TIMESTART),
    },
    {
      title: "Enddatum",
      content: formatDateTimeToBrowserDefault(job.TIMEEND),
    },
    {
      title: "Fehler-Warnung-Info-Counter",
      content: `${job.CNTERR}F - ${job.CNTWARN}W - ${job.CNTINFO}I`,
    },
  ];

  const hasException =
    job.ExceptionInfo &&
    job.ExceptionInfo.Severity !== "" &&
    job.ExceptionInfo.title !== "";
  if (hasException) {
    const { quickInfoContent } = createQuickInfoForABKError(
      job.ExceptionInfo as ABKError
    );
    quickInfo.push(...quickInfoContent);
  }

  return quickInfo;
}
