import { AxiosResponse } from "axios";
import { ParameterListe } from "../interfaces/parameterlisten";

export default function formatParameterlisteWithEtag(
  response: AxiosResponse<ParameterListe, any>
) {
  const value = response.data;
  const etag = response.headers.etag;
  return { value, etag };
}
