import { useNavigate } from "react-router-dom";
import { getFileInfoUrlForContainer } from "../../../../core/utility/cont";

export default function useOnClickContainerCardButton(db: string) {
  const navigate = useNavigate();
  const onClick = (containerID: string) => {
    navigate(getFileInfoUrlForContainer(db, containerID));
  };
  return onClick;
}
