import { create } from "zustand";
import { DB } from "../../abk-shared/interfaces/db";
import { Cont } from "../interfaces/Cont";

interface CurrentDBState {
  currentDB: DB | null;
  setCurrentDB: (db: DB) => void;
  initCurrentDB: () => string;

  selectedFileProperties: Cont | null;
  setSelectedFileProperties: (file: Cont | null) => void;
}

const useCurrentDBStore = create<CurrentDBState>((set) => ({
  currentDB: null,
  setCurrentDB: (db) => {
    localStorage.setItem("currentDB", db.DBNAME);
    set({ currentDB: db });
  },

  selectedFileProperties: null,
  setSelectedFileProperties: (file) => set({ selectedFileProperties: file }),
  initCurrentDB: () => {
    const storedDB = localStorage.getItem("currentDB");
    return storedDB ? storedDB : "";
  },
}));

export default useCurrentDBStore;
