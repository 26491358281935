import { create } from "zustand";
import { INotification } from "../../abk-shared/components/organisms/ABKSideBar/NotificationBox";
import { ABKError } from "../../abk-shared/interfaces/Error";

export interface ErrorNotification extends INotification {
  ABKError?: ABKError;
  JSError: Error;
}

interface ErrorState {
  errors: ErrorNotification[];
  infos: INotification[];
  warnings: INotification[];
  addError: (error: ErrorNotification) => void;
  addInfo: (info: INotification) => void;
  addWarning: (warning: INotification) => void;
  jobNotifications: INotification[];
  clearErrors: () => void;
  clearInfos: () => void;
  clearWarnings: () => void;
  clearAll: () => void;
  removeNotification: (notification: INotification) => void;
}

const useNotificationStore = create<ErrorState>((set) => {
  return {
    errors: [],
    jobNotifications: [],
    addError: (error: ErrorNotification) => {
      set((state) => ({ errors: [...state.errors, error] }));
    },
    clearErrors: () => set({ errors: [] }),
    infos: [],
    addInfo: (info: INotification) => {
      set((state) => ({ infos: [...state.infos, info] }));
    },
    clearInfos: () => set({ infos: [] }),
    warnings: [],
    addWarning: (warning: INotification) => {
      set((state) => ({ warnings: [...state.warnings, warning] }));
    },
    clearWarnings: () => set({ warnings: [] }),
    clearAll: () => set({ errors: [], infos: [], warnings: [] }),
    removeNotification: (notification: INotification) => {
      set((state) => {
        const errors = state.errors.filter((error) => error !== notification);
        const infos = state.infos.filter((info) => info !== notification);
        const warnings = state.warnings.filter(
          (warning) => warning !== notification
        );
        return { errors, infos, warnings };
      });
    },
  };
});

export default useNotificationStore;
