import { useEffect, useState } from "react";
import { useActiveCardState } from "../../../abk-shared/stores/useActiveCardState";


const useIsCardActive = (containerId:string) =>{
    const { containerId: storedActiveContainerId, setActiveCardContainerId } = useActiveCardState();
  const [isCardActive, setIsCardActive] = useState<boolean>(storedActiveContainerId === containerId);

  useEffect(() => {
    if (storedActiveContainerId === "") {
      setIsCardActive(false);
    } else if (storedActiveContainerId === containerId) {
      setIsCardActive(true);
    } else {
      setIsCardActive(false);
    }
  }, [storedActiveContainerId, containerId]);

  const setActiveCard = (containerId: string) =>{
    setActiveCardContainerId(containerId)
  }

  return {isCardActive, setActiveCard};
};

export default useIsCardActive;