import useGetDB from "../../../Startseite/hooks/useGetDB";
import DBGrid from "./dbGrid";
import React from "react";

const DatabasesPage: React.FC = () => {
  const { data: databases, isLoading } = useGetDB();

  if (isLoading) return <h1>Loading...</h1>;

  if (!databases) return <h1>Error</h1>;

  return (
    <div>
      <h1>Databases</h1>
      <DBGrid databases={databases} />
    </div>
  );
};

export default DatabasesPage;
