import { create } from "zustand";
import { QuickinfoContent } from "../components/organisms/ABKSideBar/QuickInfo";

interface QuickInfoState {
  content: QuickinfoContent;
  setQuickInfo: (
    quickInfo: QuickinfoContent,
    variableDisplayed?: any,
    clipboardContent?: string
  ) => void;
  resetQuickInfo: () => void;
  variableDisplayed: any;
  quickInfoClipboard: string;
}

export const useQuickInfoState = create<QuickInfoState>((set) => ({
  content: [] as QuickinfoContent,
  setQuickInfo: (quickInfo, variableDisplayed, clipboardContent) =>
    set({
      content: quickInfo,
      variableDisplayed,
      quickInfoClipboard: clipboardContent,
    }),
  resetQuickInfo: () => set({ content: [] }),
  variableDisplayed: undefined,
  quickInfoClipboard: "",
}));
