import useJobDialogStore from "./useJobDialogStore";
import getContainerNameFromJob from "../ABKJobNotification/getContainerNameFromJob";
import ABKConfirmationDialog from "../../../../abk-shared/components/molecules/ABKConfirmationDialog";

function ABKConfirmationDialogForJob() {
  const { toggleDialog, job, dialogPromise, dialogType } = useJobDialogStore();
  const jobName = getContainerNameFromJob(job);

  return (
    <ABKConfirmationDialog
      onClose={() => {
        if (dialogPromise) dialogPromise.resolve({ shouldProceed: false });
        toggleDialog();
      }}
      message={
        dialogType === "remove" ? (
          <p>
            Sind Sie sicher, dass Sie die Information zum Job "{jobName}"
            entfernen möchten?
          </p>
        ) : (
          <p>Soll der Job "{jobName}" tatsächlich abgebrochen werden?</p>
        )
      }
      onConfirm={() => {
        if (dialogPromise) dialogPromise.resolve({ shouldProceed: true });
        toggleDialog();
      }}
    />
  );
}

export default ABKConfirmationDialogForJob;
