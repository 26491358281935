import Balken from "../../../abk-shared/components/molecules/ABKSaveBalken";
import useIsMobileTemplate from "../../../abk-shared/utility/useIsMobileTemplate";
import useInitiatePageWithContainerData from "./useInitiatePageWithContainerData";

export const PADDING_BOTTOM = 25;

interface PageTemplateProps {
  children: React.ReactNode;
  balkenProps?: {
    edited: boolean;
    onSave: () => void;
    onReset: () => void;
    conflictMessage?: string;
  };
  db: string;
  contId: string;
}

const PageTemplate = ({
  children,
  balkenProps,
  db,
  contId,
}: PageTemplateProps) => {
  const isMobileTemplate = useIsMobileTemplate();
  const heightBalken = isMobileTemplate ? 90 : 55;
  useInitiatePageWithContainerData(db, contId);

  return (
    <div style={{ flex: "1 1 auto", height: "max-content" }}>
      {children}
      <div
        style={{
          height:
            balkenProps?.edited === true
              ? heightBalken + PADDING_BOTTOM
              : PADDING_BOTTOM,
        }}
      />
      {balkenProps && (
        <Balken
          edited={balkenProps.edited}
          onSave={balkenProps.onSave}
          onReset={balkenProps.onReset}
          conflictMessage={balkenProps.conflictMessage}
          height={heightBalken}
        />
      )}
    </div>
  );
};

export default PageTemplate;
