import Dexie, { type EntityTable, Table } from "dexie";
import { Cont } from "../interfaces/Cont";
import { ParameterListe } from "../../modules/Datei/interfaces/parameterlisten";
import { StringDecoder } from "string_decoder";

interface ContainerInfoDraft {
  database: string;
  contId: string;
  etag: string;
  latestChange?: number;
  edited: Cont;
  sections: Map<string, boolean>;
}

interface ParameterListeDraft {
  database: string;
  contId: string;
  dataId: string;
  etag: string;
  edited: ParameterListe;
  sections: Map<string, boolean>
}

const db = new Dexie("Drafts") as Dexie & {
  containerInfoDrafts: Table<ContainerInfoDraft, string>;
  parameterListeDrafts: Table<ParameterListeDraft, [string, string]>;
};

// Schema declaration:
db.version(1).stores({
  containerInfoDrafts: "++contId, database",
  parameterListeDrafts: "[contId+dataId], database",
});

export type {
  ContainerInfoDraft,
  ParameterListeDraft,
};
export { db };
