import React from "react";
import notfound from "../core/assets/images/notfound1.svg";

type ObjectFit = "fill" | "contain" | "cover" | "none" | "scale-down";

const NotFound: React.FC = () => {
  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100vw", // 100% der Bildschirmbreite
    height: "100vh", // 100% der Bildschirmhöhe
    overflow: "hidden", // Um sicherzustellen, dass das Bild nicht über den Container hinausragt
  };

  const imageStyle = {
    width: "100%", // 100% der Container-Breite
    height: "100%", // 100% der Container-Höhe
    objectFit: "cover" as ObjectFit,
  };

  return (
    <div style={containerStyle}>
      <img src={notfound} alt="404 Error" style={imageStyle} />
    </div>
  );
};
export default NotFound;
