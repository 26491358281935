import abk from "../../../../core/assets/images/abk.png";

const AppInfo = () => {
  return (
    <a href="https://www.abk.at/">
      <img src={abk} alt="ABK Logo" />
    </a>
  );
};

export default AppInfo;
