import { Checkbox } from "@progress/kendo-react-inputs";
import ABKBasisGrid from "../../../../abk-shared/components/organisms/ABKBasisGrid";
import { PropsCustomCell } from "../../../../abk-shared/components/organisms/ABKBasisGrid/components/CustomCell";
import { KEY_REMOVE_FROM_FILTERING } from "../../../../abk-shared/components/organisms/ABKBasisGrid/constants";
import { Eintrag } from "../../interfaces/parameterlisten";
import EintraegeCard from "./EintraegeCard";
import { useEintragBearbeitenState } from "./EintragBearbeitenDialog/useEintragBearbeitenState";
import parTypeMapper, { hideParMapper } from "./maps";

const BoldText = ({
  text,
  props,
}: {
  text: string;
  props: PropsCustomCell;
}) => {
  const isBold = props.dataItem.PARTYP === "Set";
  const isGreen = props.dataItem.PARTYP === "Head";

  return (
    <td {...props.tdProps}>
      <span
        style={{
          fontWeight: isBold || isGreen ? "bold" : "normal",
          color: isGreen ? "#999900" : "black",
        }}
      >
        {text}
      </span>
    </td>
  );
};

const GridCheckBox = ({
  tdProps,
  booleanValue,
}: {
  tdProps: any;
  booleanValue: boolean;
}) => {
  if (!booleanValue) {
    return <td {...tdProps} />;
  }

  return (
    <td {...tdProps}>
      <Checkbox value={booleanValue} />
    </td>
  );
};

interface GridAction {
  icon: string;
  text: string;
  onClick: () => void;
}

const PLEintraegeGrid = ({
  eintraege,
  defaultActions,
}: {
  eintraege: Eintrag[];
  defaultActions: GridAction[];
}) => {
  const { setCurrentEintrag } = useEintragBearbeitenState();

  return (
    <ABKBasisGrid
      groupable={false}
      sortable={false}
      data={eintraege}
      onSelectionChange={(event) => {
        const selectedItem = event[0];
        setCurrentEintrag(selectedItem as Eintrag);
      }}
      columnsDefinition={{
        columns: [
          {
            field: "VARNAME",
            title: "Parameterkennung",
            orderIndex: 1,
            minWidth: 100,
          },
          {
            field: "PARTYP",
            title: "Kategorie",
            orderIndex: 2,
            minWidth: 100,
          },
          {
            field: "MENGENABH",
            title: "Mengenabh.",
            orderIndex: 3,
            minWidth: 100,
            [KEY_REMOVE_FROM_FILTERING]: true,
          },
          {
            field: "BEZ",
            title: "Bezeichnung",
            orderIndex: 4,
            minWidth: 100,
          },
          {
            field: "EH",
            title: "Einheit",
            orderIndex: 5,
            minWidth: 100,
          },
          {
            field: "FAVORIT",
            title: "Favorit",
            orderIndex: 6,
            minWidth: 100,
            [KEY_REMOVE_FROM_FILTERING]: true,
          },
          {
            field: "HIDEPAR",
            title: "Ausblenden",
            orderIndex: 7,
            minWidth: 100,
          },
          {
            field: "SUMMEBILDEN",
            title: "Berechnungsart",
            orderIndex: 8,
            minWidth: 100,
          },
          {
            field: "REQUIRED",
            title: "Erford.",
            orderIndex: 9,
            minWidth: 100,
            [KEY_REMOVE_FROM_FILTERING]: true,
          },
        ],
      }}
      customCells={[
        {
          columnField: "REQUIRED",
          render: (props: PropsCustomCell) => (
            <GridCheckBox
              tdProps={props.tdProps}
              booleanValue={props.dataItem.REQUIRED}
            />
          ),
        },
        {
          columnField: "FAVORIT",
          render: (props: PropsCustomCell) => (
            <GridCheckBox
              tdProps={props.tdProps}
              booleanValue={props.dataItem.FAVORIT}
            />
          ),
        },
        {
          columnField: "MENGENABH",
          render: (props: PropsCustomCell) => (
            <GridCheckBox
              tdProps={props.tdProps}
              booleanValue={props.dataItem.MENGENABH}
            />
          ),
        },
        {
          columnField: "BEZ",
          render: (props: PropsCustomCell) => (
            <BoldText text={props.dataItem.BEZ} props={props} />
          ),
        },
        {
          columnField: "PARTYP",
          render: (props: PropsCustomCell) => (
            <BoldText
              text={parTypeMapper.get(props.dataItem.PARTYP) || ""}
              props={props}
            />
          ),
        },
        {
          columnField: "VARNAME",
          render: (props: PropsCustomCell) => (
            <BoldText text={props.dataItem.VARNAME} props={props} />
          ),
        },
        {
          columnField: "EH",
          render: (props: PropsCustomCell) => (
            <BoldText text={props.dataItem.EH} props={props} />
          ),
        },
        {
          columnField: "SUMMEBILDEN",
          render: (props: PropsCustomCell) => (
            <BoldText text={props.dataItem.SUMMEBILDEN} props={props} />
          ),
        },
        {
          columnField: "HIDEPAR",
          render: (props: PropsCustomCell) => (
            <BoldText
              text={hideParMapper.get(props.dataItem.HIDEPAR) || ""}
              props={props}
            />
          ),
        },
      ]}
      gridId="pl"
      dataItemKey="ITEMID"
      gridActions={(selectedItems: any[]) => {
        const numberOfSelectedItems = selectedItems.length;
        if (numberOfSelectedItems <= 1) {
          const filterTexts = ["Löschen"];
          const filteredActions = defaultActions.filter(
            (action) => !filterTexts.includes(action.text)
          );
          return [...filteredActions];
        }

        return [
          <>{`${numberOfSelectedItems} ausgewählt`}</>,
          ...defaultActions,
        ];
      }}
      mobileGridRow={(
        rowRef,
        item,
        filterValue,
        isSelected,
        setDataItemSelected
      ) => (
        <EintraegeCard
          rowRef={rowRef}
          item={item as Eintrag}
          filterValue={filterValue}
          isSelected={isSelected}
          setDataItemSelected={setDataItemSelected}
        />
      )}
    />
  );
};

export default PLEintraegeGrid;
