import { Address } from "../../../abk-shared/components/atoms/ABKAddressSelect";

export interface Herausgeber {
  firma: Firma;
  person: Kontaktperson;
}

export interface Kommunikation {
  adresse: Address;
  email: string;
  telefon: string;
  fax: string;
  www: string;
  zusatzinfo: string;
}

export interface Firma {
  name: string;
  kommunikation: Kommunikation;
}

export interface Kontaktperson {
  vorname: string;
  nachname: string;
  kommunikation: Kommunikation;
}

export interface ParameterListe {
  Meta: {
    Rowchanged: string;
    Right: string;
  };
  DBNAME: string;
  CONTID: string;
  DATAID: string;
  SUBTYPE: string;
  Kenndaten: Kenndaten;
  NOTIZ: string;
  ANMERK: string;
  REFCONTID: string;
  REFDATAID: string;
  ONDTEXP: boolean;
}

export interface Kenndaten {
  KENNUNG: string;
  VERSIONSNR: string;
  VERSIONSDATE: string | null;
  STATUS: ParameterListeStatus;
  BEZ: string;
  BESCH: string;
  DLURL: string;
  Herausgeber: Herausgeber;
  LOGO: string;
  LOGOFORMAT: LogoFormat;
  FUNKTGSKENN: string;
  FUNKTGSDATAID: string;
  PRODGSKENN: string;
  PRODGSDATAID: string;
}

export enum LogoFormat {
  JPEG = "JPEG",
  PNG = "PNG",
  GIF = "GIF",
  EMPTY = "",
}

export enum ParameterListeStatus {
  Entwurf = "E",
  Freigegeben = "F",
}

export interface Eintrag {
  Meta: {
    InstanceApiUrl: string;
    Rowchanged: string;
    Right: string;
  };
  DBNAME: string;
  CONTID: string;
  DATAID: string;
  ITEMID: string;
  ITEMTYPE: string;
  BEZ: string;
  KOMMENTAR: string;
  Funktion: string[];
  Produkt: string[];
  VARNAME: string;
  PIKTID: string;
  IFCReferenzen: {
    Entity: string;
    Set: string;
    Property: string;
  }[] | null;
  FAVORIT: boolean;
  REQUIRED: boolean;
  SHOWIF: string;
  EH: string;
  NUMNK: number;
  NUMMIN: {
    value: number;
  };
  NUMMAX: {
    value: number;
  };
  SUMMEBILDEN: boolean;
  MENGENABH: boolean;
  FORMEL: string;
  AWList_Num: number[];
  AWList_Text: {
    Eintrag: string;
    Bezeichnung: string;
  }[] | null;
  AWUSEREN: boolean;
}
