export default function shouldDisplayJSXInsteadOfInput(
  value: string,
  readOnly: boolean
) {
  /*
    Wenn wir ein Empty String haben, wollen wir trotzdem ein Input zeigen,
    sodass noch ein Abstand zwischen den Felder bleibt.
  */
  const shouldDisplayEmptyInput = value.trim() === "";
  return readOnly && !shouldDisplayEmptyInput;
}
