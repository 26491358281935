import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { QUERY_KEY_PARAMETERLISTE } from "./useGetSingleParameterliste";

const useGetAllContainerParameterlisten = (db: string, contId: string) => {
  const getAllContainerParameterlisten = () =>
    axios.get(`/api/db/${db}/cont/${contId}/pl`);

  return useQuery({
    queryKey: [QUERY_KEY_PARAMETERLISTE, db, contId],
    queryFn: () => getAllContainerParameterlisten(),
  });
};

export default useGetAllContainerParameterlisten;
