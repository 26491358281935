import { useState, useMemo, RefObject } from "react";
import useResizeObserver, { RoundingFunction } from "use-resize-observer";
import _ from "lodash";

// From: https://codesandbox.io/p/sandbox/use-resize-observer-throttle-and-debounce-8uvsg?file=%2Fsrc%2FuseThrottledResizeObserver.js%3A1%2C1-12%2C1
function useThrottledResizeObserver<T extends Element>(
  waitInMilliseconds: number,
  opts?: {
    ref?: RefObject<T> | T | null | undefined;
    box?: ResizeObserverBoxOptions;
    round?: RoundingFunction;
  }
) {
  const [size, setSize] = useState({});
  const onResize = useMemo(
    () => _.throttle(setSize, waitInMilliseconds),
    [waitInMilliseconds]
  );
  const response = useResizeObserver({ ...opts, onResize });

  return { ...response, ...size };
}

export default useThrottledResizeObserver;
