import * as React from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { useNavigate } from "react-router-dom";

interface DialogFensterProps {
  onClose: () => void;
  action: string;
}
const DialogFenster: React.FC<DialogFensterProps> = () => {
  const [visible, setVisible] = React.useState(true);
  const navigate = useNavigate();

  const handleYesClick = () => {
    setVisible(false); // Verstecke den Dialog
    navigate(`/startseite/$database/ansichten/zuletzt-verwendet`); // Navigiere zur gewünschten Seite
  };

  const handleNoClick = () => {
    // Perform the action for "Nein" button
    // For example, you can stay on the current page or perform other logic
    setVisible(false); // Close the dialog
  };

  return (
    <div>
      {visible && (
        <Dialog title={"Achtung!"} onClose={() => setVisible(false)}>
          <p style={{ margin: "25px", textAlign: "center" }}>
            Sind Sie sich sicher, dass Sie diese Seite verlassen wollen?
          </p>
          <DialogActionsBar>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={handleYesClick}
            >
              Ja
            </button>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={handleNoClick}
            >
              Nein
            </button>
          </DialogActionsBar>
        </Dialog>
      )}
    </div>
  );
};

export default DialogFenster;
