import { create } from "zustand";
import { persist } from "zustand/middleware";
import {
  GridColumnPropsCustom,
  GridColumnsDefinition,
} from "../../interfaces/GridColumns";
import { createColumnsFromColumnsDisplayed } from "./columnUtils";

type GridColumns = {
  columns: GridColumnPropsCustom[];
  setColumns: (columns: GridColumnPropsCustom[]) => void;
};

const createPersistedColumnsStore = (
  gridId: string,
  columnsDefinition: GridColumnsDefinition
) =>
  create<GridColumns>()(
    persist(
      (set) => ({
        columns: columnsDefinition.columns,
        setColumns: (columnsDisplayed: GridColumnPropsCustom[]) =>
          set(({ columns }) => {
            const newColumns = createColumnsFromColumnsDisplayed(
              columns,
              columnsDisplayed
            );
            return { columns: newColumns };
          }),
      }),
      {
        name: `grid-${gridId}-columns`,
        version: columnsDefinition.version ?? 1,
        migrate: columnsDefinition.migrate,
      }
    )
  );

export default createPersistedColumnsStore;
