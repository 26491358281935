import {
  GridSelectionChangeEvent,
  GridHeaderSelectionChangeEvent,
  getSelectedState,
} from "@progress/kendo-react-grid";
import React from "react";
import { getSelectedItems } from "../utils/helperFunctions";
import { DataResult } from "@progress/kendo-data-query";
import { SELECTED_FIELD } from "./useGridColumns/COLUMN_SELECTED_FIELD";
import { CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import { GenericObject } from "../../../../interfaces/GenericObject";
import { ungroupDataItems } from "../utils/groupingFunctions";

export type SelectedState = Record<string, boolean | number[]>;
export type FunctionSetDataItemSelected = (
  dataItem: GenericObject,
  isSelected?: boolean,
  shouldOnlySelectCurrentItem?: boolean
) => void;
export type FunctionSelectionChange = (itemsSelected: GenericObject[]) => void;

function useGridRowSelection(
  data: GenericObject[],
  dataItemKey: string,
  idGetter: Function,
  onSelectionChangeFromParent?: FunctionSelectionChange
) {
  const [selectedState, setSelectedState] = React.useState<SelectedState>({});

  const triggerOnSelectionChangeFromParent = React.useCallback(
    (newSelectedState: SelectedState) => {
      if (!onSelectionChangeFromParent) return;

      const selectedItemsUpdated = getSelectedItems(
        data,
        newSelectedState,
        idGetter
      );
      onSelectionChangeFromParent(selectedItemsUpdated);
    },
    [data, idGetter, onSelectionChangeFromParent]
  );

  const onSelectionChange = React.useCallback(
    (event: GridSelectionChangeEvent) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: dataItemKey,
      });
      setSelectedState(newSelectedState);
      triggerOnSelectionChangeFromParent(newSelectedState);
    },
    [selectedState]
  );

  const onHeaderSelectionChange = React.useCallback(
    (
      event: GridHeaderSelectionChangeEvent | CheckboxChangeEvent,
      allItems: GenericObject[]
    ) => {
      const checkboxElement = event.syntheticEvent.target;
      const checked = (checkboxElement as HTMLInputElement).checked;
      const newSelectedState = {} as SelectedState;

      const dataItemsUngrouped = ungroupDataItems(allItems);
      dataItemsUngrouped.forEach((item) => {
        newSelectedState[idGetter(item)] = checked;
      });
      setSelectedState(newSelectedState);
      triggerOnSelectionChangeFromParent(newSelectedState);
    },
    []
  );

  const setDataItemSelected: FunctionSetDataItemSelected = React.useCallback(
    (
      dataItem: GenericObject,
      isSelected = true,
      shouldOnlySelectCurrentItem = false
    ) => {
      // Deep copy to not change the previous state
      const newSelectedState = structuredClone(selectedState);
      const itemKey = dataItem[dataItemKey];

      if (shouldOnlySelectCurrentItem) {
        for (const itemId of Object.keys(newSelectedState))
          newSelectedState[itemId] = false;
      }

      newSelectedState[itemKey] = isSelected;
      setSelectedState(newSelectedState);
      triggerOnSelectionChangeFromParent(newSelectedState);
    },
    [selectedState]
  );

  const checkHeaderSelectionValue = React.useCallback(
    (dataResultTotal: number) => {
      let nbSelectedItems = 0;
      for (const isSelected of Object.values(selectedState)) {
        if (isSelected) nbSelectedItems++;
      }
      if (nbSelectedItems === 0) return false;

      const allItemsAreSelected = nbSelectedItems === dataResultTotal;
      return allItemsAreSelected;
    },
    [selectedState]
  );

  const addSelectedFieldToDataResult = (dataResult: DataResult) => {
    const addSelectedFieldToItem = (item: GenericObject) => {
      if (item.items) {
        return {
          ...item,
          items: item.items.map(addSelectedFieldToItem),
        };
      }

      return {
        ...item,
        [SELECTED_FIELD]: selectedState[idGetter(item)],
      };
    };
    const newData = dataResult.data.map(addSelectedFieldToItem);

    const newDataResult = { ...dataResult, data: newData };
    return newDataResult;
  };

  /*
    Wenn wir den Filter ändern, wollen wir, dass die Liste der ausgewählten
    Items sich aktualisiert.
    Das behebe den folgenden Bug:
     - Auf der Checkbox im Header klicken, um alle Dateien auszuwählen
     - In der Suche Text eingeben, z.B. FSV
     -> Die Header Checkbox ist nicht mehr selektiert!
     -> Die Elemente, die vorher ausgewählt waren, bleiben selektiert, auch wenn das Grid sie nicht mehr zeigt!
  */
  const updateSelectedStateAfterFilter = React.useCallback(
    (dataResult: DataResult) => {
      const newSelectedState: SelectedState = {};

      for (const dataItem of dataResult.data) {
        const isGrouped = dataItem.hasAggregates;
        if (isGrouped) continue; // Grouping und Auswahl ist jetzt nicht unterstützt

        const itemUniqueIdentifier = dataItem[dataItemKey];
        if (selectedState[itemUniqueIdentifier])
          newSelectedState[itemUniqueIdentifier] = true;
      }
      setSelectedState(newSelectedState);
      triggerOnSelectionChangeFromParent(newSelectedState);
    },
    [selectedState, dataItemKey]
  );

  return {
    selectedState,
    onSelectionChange,
    onHeaderSelectionChange,
    setDataItemSelected,
    checkHeaderSelectionValue,
    addSelectedFieldToDataResult,
    updateSelectedStateAfterFilter,
  };
}

export default useGridRowSelection;
