import { QueryClient } from "@tanstack/react-query";
import { INotification } from "../../../../../abk-shared/components/organisms/ABKSideBar/NotificationBox";
import { InfoItem } from "../../../../../abk-shared/components/organisms/ABKSideBar/QuickInfo";
import { ErrorNotification } from "../../../../stores/useNotificationStore";
import {
  createQuickInfoForABKError,
  createQuickInfoForJSError,
} from "./createQuickInfoForError";
import generateQuickInfoForLogsAndFiles from "./job/generateQuickInfoForLogsAndFiles";

async function getQuickInfoForNotification(
  notification: INotification | ErrorNotification,
  queryClient: QueryClient
) {
  const result = {
    quickInfoContent: [] as InfoItem[],
    quickInfoClipboard: "",
  };

  const abkError = (notification as ErrorNotification).ABKError;
  if (notification.type === "error") {
    if (abkError) return createQuickInfoForABKError(abkError);

    const jsError = (notification as any).JSError;
    if (jsError) return createQuickInfoForJSError(jsError);
  }

  const job = notification.quickInfoData?.job;
  if (job) {
    const quickInfoForLogsAndFiles = await generateQuickInfoForLogsAndFiles(
      queryClient,
      job
    );
    const quickInfo = notification.quickInfo ? notification.quickInfo : [];
    result.quickInfoContent = [...quickInfo, ...quickInfoForLogsAndFiles];
    return result;
  }

  if (notification.quickInfo) {
    result.quickInfoContent = notification.quickInfo;
    return result;
  }

  result.quickInfoContent = [
    { title: "Notification", content: notification.message },
    { title: "Type", content: notification.type?.toUpperCase() || "NONE" },
  ];
  return result;
}

export default getQuickInfoForNotification;
