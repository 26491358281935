import "./style.css";

const ABKFakeLink = ({
  onClick,
  children,
}: {
  onClick: () => void;
  children: React.ReactNode;
}) => {
  return (
    <span className="fake-link" onClick={onClick}>
      {children}
    </span>
  );
};

export default ABKFakeLink;
