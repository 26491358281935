import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { PORTAL_URL } from "../../../../core/constants/urls";

interface FehlerDialogProps {
  setAuthError: (value: boolean) => void;
}

const FehlerDialog = ({ setAuthError }: FehlerDialogProps) => {
  return (
    <Dialog title={"Fehler!"} onClose={() => setAuthError(false)}>
      <div className="dialog-content">
        <h2>Fehler 401 !</h2>
        <p>Session abgelaufen. Bitte einlogen!</p>
        <DialogActionsBar>
          <Button
            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
            onClick={() => {
              let currentUrl = window.location.href;
              window.location.href =
                PORTAL_URL +
                "/api/login/createSession?webserverUrl=" +
                currentUrl;
            }}
          >
            Session verlängern und Änderungen verwerfen
          </Button>
          <Button
            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
            onClick={() => setAuthError(false)}
          >
            Schließen
          </Button>
        </DialogActionsBar>
      </div>
    </Dialog>
  );
};

export default FehlerDialog;
