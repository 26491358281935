import { useRef, useLayoutEffect, useEffect } from "react";
import useThrottledResizeObserver from "../../../../abk-shared/utility/useThrottledResizeObserver";
import { useLocation } from "react-router-dom";

/*
  Wir brauchen ein Margin, ansonsten kann das folgende Problem auftreten:

  Wenn wir runterscrollen, und der Header verschwindet, vergrößert sich der
  Bereich unter dem Header. Aber ein Problem passiert, wenn dieser Bereich
  keine Scrollbar mehr verlangt, weil er seine völlige Größe erreicht hat:
  seine Scrollbar verschwindet, und dann ist der Scroll zu 0 gesetzt, und
  der Header erscheint wieder.
*/
const MARGIN_HEADER_HEIGHT = 40;

function useScrollableHeader() {
  const location = useLocation();

  const headerRef = useRef<HTMLDivElement>(null);
  const scrollableContainerRef = useRef<HTMLDivElement>(null);

  const initialHeaderHeight = useRef(100);
  const scrollThreshold = initialHeaderHeight.current + MARGIN_HEADER_HEIGHT;

  const { width } = useThrottledResizeObserver<HTMLDivElement>(100, {
    ref: headerRef,
  });

  useLayoutEffect(() => {
    if (headerRef.current) headerRef.current.style.height = "auto";

    const timeoutId = setTimeout(() => {
      if (headerRef.current) {
        const header = headerRef.current as HTMLElement;
        const rect = header.getBoundingClientRect();
        initialHeaderHeight.current = rect.height;

        /*
          Initialize these header styles. If they are not initialized, the
          CSS transition will not trigger the first time, but will be
          triggered the next times.
        */
        headerRef.current.style.opacity = "1";
        headerRef.current.style.height = `${initialHeaderHeight.current}px`; // Original height
      }
    }, 0);

    return () => clearTimeout(timeoutId);
  }, [location, width]);

  useEffect(() => {
    const handleScroll = () => {
      const container = scrollableContainerRef.current;
      if (container) {
        const scrollTop = container.scrollTop;

        if (headerRef.current) {
          const shouldHideHeader = scrollTop > scrollThreshold;
          if (shouldHideHeader) {
            headerRef.current.style.opacity = "0";
            headerRef.current.style.height = "0px";
          }

          const shouldDisplayHeader = scrollTop === 0;
          if (shouldDisplayHeader) {
            headerRef.current.style.opacity = "1";
            headerRef.current.style.height = `${initialHeaderHeight.current}px`; // Original height
          }
        }
      }
    };

    const scrollableContainer = scrollableContainerRef.current;
    if (scrollableContainer) {
      scrollableContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      scrollableContainer?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return { headerRef, scrollableContainerRef };
}

export default useScrollableHeader;
