import React from "react";
import { GridColumnPropsCustom } from "../interfaces/GridColumns";
import { forEachColumns } from "./useGridColumns/columnUtils";

export default function useFieldsWithInternalValue(
  columns: GridColumnPropsCustom[]
) {
  return React.useMemo(() => {
    const fieldsWithInternalValue: string[] = [];
    forEachColumns(columns, (column) => {
      if (column.hasInternalValue && column.field) {
        fieldsWithInternalValue.push(column.field);
      }
    });

    return fieldsWithInternalValue;
  }, [columns]);
}
