import { Link, useNavigate } from "react-router-dom";
import { ContWithLastChangedModified } from "../../../../core/interfaces/Cont";
import { useQuickInfoState } from "../../../../abk-shared/stores/useQuickInfoState";
import {
  getFileInfoUrlForContainer,
  useContainersWithFormattedDate,
} from "../../../../core/utility/cont";
import useDuplicateCont from "../../hooks/useDuplicateCont";
import useGetCont, { RequestType } from "../../hooks/useGetCont";
import useSaveContAsTemplate from "../../hooks/useSaveContAsTemplate";
import ContainerLink from "../ContainerLink";
import React from "react";
import useDeleteCont from "../../hooks/useDeleteCont";
import { DeleteDialogResponse } from "./DeleteDialog";
import { ContainerToExport, ExportDialogResponse } from "./ExportDialog";
import usePostExport from "./ExportDialog/usePostExport";
import { PropsCustomCell } from "../../../../abk-shared/components/organisms/ABKBasisGrid/components/CustomCell";
import { SvgIcon } from "@progress/kendo-react-common";
import HighlightedCell from "../../../../abk-shared/components/organisms/ABKBasisGrid/components/HighlightedCell";
import { AbkIconsMap } from "../../../../abk-shared/interfaces/abk9SvgIcons";
import {
  GridColumnPropsCustom,
  GridColumnsMigrateFunction,
  PersistedColumns,
} from "../../../../abk-shared/components/organisms/ABKBasisGrid/interfaces/GridColumns";
import useQuickInfoOnGridSelection from "../../../../core/components/templates/MainPageTemplate/generateSidebarElements/useQuickInfoOnGridSelection";
import { DATA_KEY } from "../../../../abk-shared/components/organisms/ABKBasisGrid/constants";

const COLUMN_FIELDS = {
  DATEINAME: "BEZ",
  TYP: "ContTypeInfo.TXT",
  BESITZER: "OWNER",
  ÄNDERUNGSDATUM: "LASTCHANGED",
  ANMERKUNG: "INTANMERK",
};

const useVerwalten = (
  database: string,
  requestType: RequestType,
  setExportDialogPromise: React.Dispatch<
    React.SetStateAction<{
      resolve: (value: ExportDialogResponse) => void;
    } | null>
  >,
  setDeleteDialogPromise: React.Dispatch<
    React.SetStateAction<{
      resolve: (value: DeleteDialogResponse) => void;
    } | null>
  >
) => {
  const { data, isLoading, isFetching } = useGetCont(database, requestType);
  const containersWithFormattedDate = useContainersWithFormattedDate(data);

  // Inspired from: https://www.telerik.com/kendo-react-ui/components/grid/columns/width/#toc-setting-a-minimum-column-width
  const columnsDefinition = React.useMemo(() => {
    const columns = [
      {
        field: "Datei",
        title: "Datei",
        orderIndex: 1,
        children: [
          {
            field: COLUMN_FIELDS.DATEINAME,
            title: "Dateiname",
            minWidth: 100,
            orderIndex: 2,
          },
          {
            field: COLUMN_FIELDS.TYP,
            title: "Typ",
            minWidth: 50,
            manualWidth: 50,
            removeFromFiltering: true,
            orderIndex: 3,
          },
          {
            field: COLUMN_FIELDS.BESITZER,
            title: "Besitzer",
            minWidth: 100,
            orderIndex: 4,
          },
          {
            field: COLUMN_FIELDS.ÄNDERUNGSDATUM,
            title: "Änderungsdatum",
            minWidth: 100,
            orderIndex: 5,
            hasInternalValue: true,
          },
        ],
      },
      {
        field: "Notizen",
        title: "Notizen",
        orderIndex: 6,
        children: [
          {
            field: COLUMN_FIELDS.ANMERKUNG,
            title: "Anmerkung",
            minWidth: 100,
            orderIndex: 7,
          },
        ],
      },
    ] as GridColumnPropsCustom[];

    const version = 4;

    const migrate: GridColumnsMigrateFunction = (persistedColumns, version) => {
      if (version === 1) {
        const newPersistedColumns = persistedColumns as PersistedColumns;
        const datei = newPersistedColumns.columns[0];
        const lastChanged = datei.children![3];
        // Wurde in Version 2 hinzugefügt, um die Daten zu sortieren
        lastChanged.hasInternalValue = true;

        return newPersistedColumns;
      }

      if (version === 2) {
        const newPersistedColumns = persistedColumns as PersistedColumns;
        const datei = newPersistedColumns.columns[0];
        const lastChanged = datei.children![3];
        // Wurde in Version 3 unbenannt
        delete (lastChanged as any).hasDisplayedValue;
        lastChanged.hasInternalValue = true;

        return newPersistedColumns;
      }

      if (version === 3) {
        const newPersistedColumns = persistedColumns as PersistedColumns;
        const notiz = newPersistedColumns.columns[1];
        const anmerkung = notiz.children![3];
        // Wurde in Version 4 unbenannt von `ANMERK` nach `INTANMERK`
        anmerkung.field = COLUMN_FIELDS.ANMERKUNG;

        return newPersistedColumns;
      }

      return persistedColumns as PersistedColumns;
    };

    return { columns, version, migrate };
  }, []);

  const customCells = React.useMemo(
    () => [
      {
        columnField: COLUMN_FIELDS.DATEINAME,
        render: (props: PropsCustomCell) => (
          <HighlightedCell
            {...props}
            /*
              Not all "tdProps" are passed, to disable the default onClick event for
              the row, and allow the user to click on the link.
            */
            tdProps={{
              onContextMenu: props.tdProps?.onContextMenu,
              // Remove the default padding, the Link inside the cell will take all the space
              style: { ...props.tdProps?.style, padding: 0 },
            }}
          >
            <ContainerLink
              container={props.dataItem}
              filterValue={props.filterValue}
            />
          </HighlightedCell>
        ),
      },
      {
        columnField: COLUMN_FIELDS.TYP,
        render: (props: PropsCustomCell) => (
          <td {...props.tdProps} title={props.dataItem.ContTypeInfo.TXT}>
            <SvgIcon
              width={24}
              icon={AbkIconsMap.get(props.dataItem.ContTypeInfo.ICONID)}
            />
          </td>
        ),
      },
    ],
    []
  );

  const generateQuickInfoForOneItem = async (
    item: ContWithLastChangedModified
  ) => {
    return [
      {
        title: "Dateiname",
        content: (
          <Link to={getFileInfoUrlForContainer(item.DBNAME, item.CONTID)}>
            {item.BEZ || ""}
          </Link>
        ),
      },
      {
        title: "Besitzer",
        content: item.OWNER || "",
      },
      {
        title: "Änderungsdatum",
        content: item.LASTCHANGED[DATA_KEY.DISPLAYED] || "",
      },
      {
        title: "Anmerkung",
        content: item.INTANMERK || "Keine Anmerkung vorhanden",
      },
    ];
  };
  const onSelectionChange = useQuickInfoOnGridSelection(
    generateQuickInfoForOneItem
  );

  const { resetQuickInfo, variableDisplayed } = useQuickInfoState();

  const [selectedContainers, setSelectedContainers] = React.useState<
    ContWithLastChangedModified[]
  >([]);
  const [isExportDialogOpen, setIsExportDialogOpen] = React.useState(false);
  const toggleExportDialog = () => setIsExportDialogOpen(!isExportDialogOpen);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
  const toggleDeleteDialog = () => setIsDeleteDialogOpen(!isDeleteDialogOpen);

  const [isGridLoading, setIsGridLoading] = React.useState(false);
  React.useEffect(() => {
    setIsGridLoading(isFetching);
  }, [isFetching]);

  const navigate = useNavigate();
  const mutationDelete = useDeleteCont(database);
  const mutationDuplicate = useDuplicateCont(database);
  const mutationSaveAsTemplate = useSaveContAsTemplate(database);
  const mutationExport = usePostExport();

  const containerActionHandlers = {
    open: (_event: any, selectedContainers: ContWithLastChangedModified[]) => {
      const containerSelected = selectedContainers[0];
      const containerId = containerSelected.CONTID;
      if (database === undefined) return;

      navigate(getFileInfoUrlForContainer(database, containerId));
    },
    delete: async (
      _event: any,
      selectedContainers: ContWithLastChangedModified[]
    ) => {
      toggleDeleteDialog();
      setSelectedContainers(selectedContainers);
      const { shouldProceed } = await new Promise<DeleteDialogResponse>(
        (resolve) => {
          setDeleteDialogPromise({ resolve });
        }
      );
      if (!shouldProceed) return;

      setIsGridLoading(true);
      for (const container of selectedContainers) {
        try {
          await mutationDelete.mutateAsync(container);
        } catch {
          // Fahre fort, auch wenn es einen Fehler gibt, um das Loading zu beenden.
          setIsGridLoading(false);
        }
      }
      // setIsGridLoading(false) wird vom "useEffect" für "isFetching" zurückgesetzt

      if (
        containersAreDisplayedInQuickInfo(selectedContainers, variableDisplayed)
      )
        resetQuickInfo();
    },
    duplicate: async (
      _event: any,
      selectedContainers: ContWithLastChangedModified[]
    ) => {
      setIsGridLoading(true);
      const containerSelected = selectedContainers[0];
      const containerId = containerSelected.CONTID;
      try {
        await mutationDuplicate.mutateAsync(containerId);
      } catch {
        setIsGridLoading(false);
      }
      // setIsGridLoading(false) wird vom "useEffect" für "isFetching" zurückgesetzt
    },
    saveAsTemplate: async (
      _event: any,
      selectedContainers: ContWithLastChangedModified[]
    ) => {
      setIsGridLoading(true);
      const containerSelected = selectedContainers[0];
      const containerId = containerSelected.CONTID;
      try {
        await mutationSaveAsTemplate.mutateAsync(containerId);
      } catch {
        setIsGridLoading(false);
      }
      // setIsGridLoading(false) wird vom "useEffect" für "isFetching" zurückgesetzt
    },
    export: async (
      _event: any,
      selectedContainers: ContWithLastChangedModified[]
    ) => {
      toggleExportDialog();
      setSelectedContainers(selectedContainers);
      const { shouldProceed, containersToExport } =
        await new Promise<ExportDialogResponse>((resolve) => {
          setExportDialogPromise({ resolve });
        });
      if (!shouldProceed) return;

      setIsGridLoading(true);
      for (const container of containersToExport as ContainerToExport[]) {
        try {
          await mutationExport.mutateAsync({
            dbName: container.dbName,
            contId: container.contId,
            exportFormat: container.exportFormat,
          });
        } catch {}
      }
      setIsGridLoading(false);
    },
  };

  const gridActions = React.useCallback(
    (selectedItems: any[]) => {
      const commonActions = [
        {
          icon: "Edit.Delete",
          text: "Löschen",
          onClick: containerActionHandlers.delete,
        },
        {
          icon: "General.Export",
          text: "Exportieren",
          onClick: containerActionHandlers.export,
        },
      ];

      const numberOfSelectedItems = selectedItems.length;
      if (numberOfSelectedItems <= 1) {
        return [
          {
            icon: "Navigation.Open",
            text: "Öffnen",
            onClick: containerActionHandlers.open,
          },
          {
            icon: "Grid.Copy",
            text: "Duplizieren",
            onClick: containerActionHandlers.duplicate,
          },
          {
            icon: "Symbol.Star",
            text: "Als Vorlage speichern",
            onClick: containerActionHandlers.saveAsTemplate,
          },
          ...commonActions,
        ];
      }

      return [<>{`${numberOfSelectedItems} ausgewählt`}</>, ...commonActions];
    },
    [containerActionHandlers]
  );

  const shouldReturnLoader = !database || isLoading;

  return {
    shouldReturnLoader,
    onSelectionChange,
    containers: containersWithFormattedDate,
    columnsDefinition,
    gridActions,
    customCells,
    toggleDeleteDialog,
    isDeleteDialogOpen,
    toggleExportDialog,
    isExportDialogOpen,
    selectedContainers,
    isGridLoading,
  };
};

function containersAreDisplayedInQuickInfo(
  selectedContainers: ContWithLastChangedModified[],
  variableDisplayed: ContWithLastChangedModified | ContWithLastChangedModified[]
) {
  if (!Array.isArray(selectedContainers)) return false;

  if (selectedContainers.length === 1) {
    if (Array.isArray(variableDisplayed)) return false;
    return selectedContainers.includes(variableDisplayed);
  }

  if (!Array.isArray(variableDisplayed)) return false;
  return selectedContainers.every(
    (selectedContainer, index) => selectedContainer === variableDisplayed[index]
  );
}

export default useVerwalten;
