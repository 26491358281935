import { DrawerItemsKeys } from "../utils/generateDrawerItems";
import CEKFileInfo from "./CEK";
import CGSFileInfo from "./CGS";
import CPLFileInfo from "./CPL";

export interface FileInfo {
  drawerItems: DrawerItemsKeys[];
}

const FileTypeMap: Map<string, FileInfo> = new Map([
  ["cpl", CPLFileInfo],
  ["cgs", CGSFileInfo],
  ["cek", CEKFileInfo],
]);

export default FileTypeMap;
