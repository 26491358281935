import ABKFormFieldInput from "../../../../../../abk-shared/components/atoms/ABKFormFieldInput";
import ABKForm from "../../../../../../abk-shared/components/molecules/ABKForm";
import {
  Kenndaten,
  ParameterListe,
} from "../../../../interfaces/parameterlisten";
import UploadLogo from "./UploadLogo";
import DropDownStatus from "./DropDownStatus";
import ABKControlledExpansionPanel from "../../../../../../abk-shared/components/atoms/ABKControlledExpansionPanel";
import DownloadLink from "./DownloadLink";
import Version from "./Version";
import KenndatenDate from "./KenndatenDate";
import ABKFormRow from "../../../../../../abk-shared/components/atoms/ABKFormRow";

type AllgemeinFormGeneralProps = {
  pageState: ParameterListe;
  setPageState: (parameterliste: ParameterListe) => void;
  readOnly: boolean;
};
export interface AllgemeinFieldInputProps extends AllgemeinFormGeneralProps {
  kenndaten: Kenndaten;
}

interface AllgemeinFormProps extends AllgemeinFormGeneralProps {
  isOpen: boolean;
  updateSections: () => void;
}

const AllgemeinForm = ({
  pageState,
  setPageState,
  isOpen,
  updateSections,
  readOnly,
}: AllgemeinFormProps) => {
  const kenndaten = pageState?.Kenndaten || {};

  return (
    <ABKControlledExpansionPanel
      title="Allgemein"
      expanded={isOpen}
      onAction={updateSections}
    >
      <ABKFormRow>
        <ABKForm
          sections={[
            {
              formElements: [
                <ABKFormRow>
                  <ABKFormFieldInput
                    editorId="bezeichnung"
                    label="Bezeichnung"
                    value={kenndaten.BEZ || ""}
                    setValue={
                      readOnly
                        ? undefined
                        : (value) =>
                            setPageState({
                              ...pageState,
                              Kenndaten: {
                                ...kenndaten,
                                BEZ: value,
                              },
                            })
                    }
                  />
                </ABKFormRow>,
                <ABKFormRow>
                  <ABKFormFieldInput
                    editorId="kennung"
                    label="Kennung"
                    value={kenndaten.KENNUNG || ""}
                    setValue={
                      readOnly
                        ? undefined
                        : (value) =>
                            setPageState({
                              ...pageState,
                              Kenndaten: {
                                ...kenndaten,
                                KENNUNG: value,
                              },
                            })
                    }
                  />
                </ABKFormRow>,
                <div className="version-date-status-container">
                  <Version
                    readOnly={readOnly}
                    setPageState={setPageState}
                    pageState={pageState}
                    kenndaten={kenndaten}
                  />
                  <KenndatenDate
                    readOnly={readOnly}
                    setPageState={setPageState}
                    pageState={pageState}
                    kenndaten={kenndaten}
                  />
                  <DropDownStatus
                    pageState={pageState}
                    setPageState={setPageState}
                  />
                </div>,
                <ABKFormRow>
                  <DownloadLink
                    pageState={pageState}
                    setPageState={setPageState}
                    kenndaten={kenndaten}
                    readOnly={readOnly}
                  />
                </ABKFormRow>,
              ],
            },
          ]}
        />
        {/* Zusätzliches <div>, um dem HTML-Markup von `ABKForm` zu entsprechen  */}
        <div className="k-flex-1">
          <div className="wrapper-form-section">
            <UploadLogo
              disabled={readOnly}
              pageState={pageState}
              setPageState={setPageState}
              kenndaten={kenndaten}
            />
          </div>
        </div>
      </ABKFormRow>
    </ABKControlledExpansionPanel>
  );
};

export default AllgemeinForm;
