import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { ParameterListe } from "../interfaces/parameterlisten";
import { Versioned } from "../../../core/interfaces/Versioned";
import formatParameterlisteWithEtag from "./formatParameterlisteWithEtag";
import useGetAllContainerParameterlisten from "./useGetAllContainerParameterlisten";

export const QUERY_KEY_PARAMETERLISTE = "pl";

const useGetSingleParameterliste = (db: string, contId: string) => {
  const { data } = useGetAllContainerParameterlisten(db, contId);

  const firstParameterlisteDataId = data?.data?.Items?.[0]?.DATAID;

  const getSingleParameterliste = async () => {
    const url = `/api/db/${db}/cont/${contId}/pl/${firstParameterlisteDataId}`;
    const response = await axios.get<ParameterListe>(url);
    return formatParameterlisteWithEtag(response);
  };

  return useQuery<Versioned<ParameterListe>>({
    queryKey: [QUERY_KEY_PARAMETERLISTE, db, contId, firstParameterlisteDataId],
    queryFn: () => getSingleParameterliste(),
    enabled: !!firstParameterlisteDataId,
  });
};

export default useGetSingleParameterliste;
