import { EditorToolsSettings, EditorTools } from "@progress/kendo-react-editor";

const customUnderlineSettings: EditorToolsSettings.InlineFormatSettings= {
    mark: "u",

    props: {
        title: "Unterstrichen (Strg+U)",
        svgIcon: EditorToolsSettings.underline.props.svgIcon,
    },
    messages: {},
};

const OenormUnderline = EditorTools.createInlineFormatTool(customUnderlineSettings);

export default OenormUnderline;