import { useIsValidDbAndContIdInURL } from "../../../../core/utility/useIsValidDB";
import DateiProtocolPage from "./DateiProtocolPage";

const DateiProtocolPageWrapper = () => {
  const { isValid, db, contId } = useIsValidDbAndContIdInURL();
  if (!isValid) return <></>;

  return (
    <DateiProtocolPage
      key={`datei-protocol-${db}-${contId}`}
      db={db as string}
      contId={contId as string}
    />
  );
};

export default DateiProtocolPageWrapper;
