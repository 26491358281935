import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import useTabNavigationStore, {
  TabNavigationItem,
} from "../../../stores/useTabNavigationStore";

const AbkTabNavigation = () => {
  const { tabs, selectedTab, setSelectedTab } = useTabNavigationStore();
  const navigate = useNavigate();

  return (
    <>
      {tabs && tabs.length > 0 && (
        <div className="abk-tab-navigation">
          <TabStrip
            className="abk-tabstrip"
            selected={selectedTab}
            onSelect={(e) => {
              setSelectedTab(e.selected);
              navigate(tabs[e.selected].to);
            }}
          >
            {tabs.map((view: TabNavigationItem) => (
              <TabStripTab key={view.id} title={view.label} />
            ))}
          </TabStrip>
        </div>
      )}
    </>
  );
};

export default AbkTabNavigation;
