import "@progress/kendo-react-layout";
import * as urls from "../../../constants/urls";
import {
  USER_AVAS_OPTIONS,
  USER_BROWSER_OPTIONS,
  USER_OPTIONS,
  USER_VIEW_OPTIONS,
} from "../../../constants/urls";
import * as Icons from "../../../../abk-shared/interfaces/abk9SvgIcons";
import { SvgIcon } from "@progress/kendo-react-common";
import { MAIN_PAGE_TEMPLATE } from "../../../stores/useMainPageTemplateState";
import { TitlesHelp } from "../../../constants/titles";

export default {
  // Startseite

  open: {
    id: "open",
    text: "Öffnen",
    title: MAIN_PAGE_TEMPLATE.TITLE,
    icon: (
      <SvgIcon icon={Icons.Menu_Start_FileOpen} style={{ marginRight: 5 }} />
    ),
    path: urls.TEMPLATES,
    description: MAIN_PAGE_TEMPLATE.DESCRIPTION,
  },

  new: {
    id: "new",
    text: "Erstellen",
    title: "Datei erstellen",
    icon: (
      <SvgIcon icon={Icons.Menu_Start_FileNew} style={{ marginRight: 5 }} />
    ),
    path: urls.TEMPLATES,
    description: MAIN_PAGE_TEMPLATE.DESCRIPTION,
  },

  files: {
    id: "files",
    text: "Verwalten",
    title: "Dateien verwalten",
    icon: (
      <SvgIcon icon={Icons.Menu_Start_FileManage} style={{ marginRight: 5 }} />
    ),
    path: urls.FILES,
    description: MAIN_PAGE_TEMPLATE.DESCRIPTION,
  },

  import: {
    id: "import",
    text: "Importieren",
    title: "Dateien importieren",
    icon: <SvgIcon icon={Icons.General_Import} style={{ marginRight: 5 }} />,
    path: urls.IMPORT,
    description: MAIN_PAGE_TEMPLATE.DESCRIPTION,
  },

  export: {
    id: "export",
    text: "Exportieren",
    title: "Dateien exportieren",
    icon: <SvgIcon icon={Icons.General_Export} style={{ marginRight: 5 }} />,
    path: urls.EXPORT /**/,
    description: MAIN_PAGE_TEMPLATE.DESCRIPTION,
  },

  // Datei

  file: {
    id: ":contId",
    text: "Datei",
    title: "öffnen",
    icon: <SvgIcon icon={Icons.View_Show} style={{ marginRight: 5 }} />,
    path: urls.FILE_INFO,
    description: MAIN_PAGE_TEMPLATE.DESCRIPTION,
  },

  info: {
    id: "info",
    text: "Datei-Info",
    title: "Datei-Info",
    icon: (
      <SvgIcon icon={Icons.Menu_File_FileInfo} style={{ marginRight: 5 }} />
    ),
    path: urls.FILE_INFO,
    description: MAIN_PAGE_TEMPLATE.DESCRIPTION,
  },

  [urls.FILE_PROTOCOL_SUFFIX]: {
    id: urls.FILE_PROTOCOL_SUFFIX,
    text: "Datei-Protokoll",
    title: "Datei-Protokoll",
    icon: <SvgIcon icon={Icons.Menu_File_FileLog} style={{ marginRight: 5 }} />,
    path: urls.FILE_PROTOCOL,
    description: MAIN_PAGE_TEMPLATE.DESCRIPTION,
  },

  [urls.FILE_KENNDATEN_SUFFIX]: {
    id: urls.FILE_KENNDATEN_SUFFIX,
    text: "Kenndaten",
    title: "Kenndaten",
    icon: <SvgIcon icon={Icons.Dummy_Missing} style={{ marginRight: 5 }} />,
    path: urls.FILE_KENNDATEN,
    description: MAIN_PAGE_TEMPLATE.DESCRIPTION,
  },

  [urls.FILE_EINTRAEGE_SUFFIX]: {
    id: urls.FILE_EINTRAEGE_SUFFIX,
    text: "Einträge",
    title: "Einträge",
    icon: <SvgIcon icon={Icons.Dummy_Missing} style={{ marginRight: 5 }} />,
    path: urls.FILE_EINTRAEGE,
    description: MAIN_PAGE_TEMPLATE.DESCRIPTION,
  },

  /*   info: {
           id: ":contId",
           text: "Info",
           icon: <SvgIcon icon={FileInfo_042} style={{marginRight: 5}} />,
           path: FILE_INFO,
       description:
      MAIN_PAGE_TEMPLATE.DESCRIPTION,},
       */

  // Admin

  admin: {
    id: "admin",
    text: "Admin-Center",
    title: "Admin-Center",
    icon: <SvgIcon icon={Icons.Menu_Admin} style={{ marginRight: 5 }} />,
    path: urls.ADMIN,
    description: MAIN_PAGE_TEMPLATE.DESCRIPTION,
  },

  "db-status": {
    id: "db-status",
    text: "Datenbank-Status",
    title: "Datenbank-Status",
    icon: (
      <SvgIcon
        icon={Icons.Menu_Admin_DatabaseState}
        style={{ marginRight: 5 }}
      />
    ),
    path: urls.ADMIN_DB_STATUS,
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae numquam sequi pariatur aperiam illo expedita quod ratione alias, provident quis eius tempora beatae reprehenderit omnis temporibus nesciunt repellat? Reprehenderit, itaque?",
  },

  [urls.ADMIN_DB_PROTOCOL_SUFFIX]: {
    id: urls.ADMIN_DB_PROTOCOL_SUFFIX,
    text: "Datenbank-Protokoll",
    title: "Datenbank-Protokoll",
    icon: (
      <SvgIcon icon={Icons.Menu_Admin_DatabaseLog} style={{ marginRight: 5 }} />
    ),
    path: urls.ADMIN_DB_PROTOCOL,
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae numquam sequi pariatur aperiam illo expedita quod ratione alias, provident quis eius tempora beatae reprehenderit omnis temporibus nesciunt repellat? Reprehenderit, itaque?",
  },

  "server-status": {
    id: "server-status",
    text: "Server-Status",
    title: "Server-Status",
    icon: (
      <SvgIcon icon={Icons.Menu_Admin_ServerState} style={{ marginRight: 5 }} />
    ),
    path: urls.ADMIN_SERVER_STATUS,
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae numquam sequi pariatur aperiam illo expedita quod ratione alias, provident quis eius tempora beatae reprehenderit omnis temporibus nesciunt repellat? Reprehenderit, itaque?",
  },

  "server-log": {
    id: "server-log",
    text: "Server-Log",
    title: "Server-Log",
    icon: (
      <SvgIcon icon={Icons.Menu_Admin_ServerLog} style={{ marginRight: 5 }} />
    ),
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae numquam sequi pariatur aperiam illo expedita quod ratione alias, provident quis eius tempora beatae reprehenderit omnis temporibus nesciunt repellat? Reprehenderit, itaque?",
  },

  "server-info": {
    id: "server-info",
    text: "Server-Info",
    title: "Server-Info",
    icon: (
      <SvgIcon icon={Icons.Menu_Admin_ServerInfo} style={{ marginRight: 5 }} />
    ),
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae numquam sequi pariatur aperiam illo expedita quod ratione alias, provident quis eius tempora beatae reprehenderit omnis temporibus nesciunt repellat? Reprehenderit, itaque?",
  },

  org: {
    id: "org",
    text: "Organisation",
    title: "Organisation",
    icon: (
      <SvgIcon icon={Icons.Menu_Admin_Company} style={{ marginRight: 5 }} />
    ),
    path: urls.ADMIN_ORG,
    description: MAIN_PAGE_TEMPLATE.DESCRIPTION,
  },

  departments: {
    id: "departments",
    text: "Abteilungen",
    title: "Abteilungen",
    icon: (
      <SvgIcon icon={Icons.Menu_Admin_Departments} style={{ marginRight: 5 }} />
    ),
    path: urls.ADMIN_DEPARTMENTS,
    description: MAIN_PAGE_TEMPLATE.DESCRIPTION,
  },

  users: {
    id: "users",
    text: "Benutzer",
    title: "Benutzer",
    icon: <SvgIcon icon={Icons.Menu_Admin_Users} style={{ marginRight: 5 }} />,
    path: urls.ADMIN_USERS,
    description: MAIN_PAGE_TEMPLATE.DESCRIPTION,
  },

  databases: {
    id: "databases",
    text: "Datenbanken",
    title: "Datenbanken",
    icon: (
      <SvgIcon
        icon={Icons.Menu_Admin_DatabaseInfo}
        style={{ marginRight: 5 }}
      />
    ),
    path: urls.ADMIN_DATABASES,
    description: MAIN_PAGE_TEMPLATE.DESCRIPTION,
  },

  licenses: {
    id: "licenses",
    text: "Lizenzen",
    title: "Lizenzen",
    icon: (
      <SvgIcon icon={Icons.Menu_Admin_License} style={{ marginRight: 5 }} />
    ),
    path: urls.ADMIN_LICENSES,
    description: MAIN_PAGE_TEMPLATE.DESCRIPTION,
  },

  // User

  user: {
    id: "user",
    text: "Benutzer",
    title: "Benutzer",
    icon: <SvgIcon icon={Icons.Menu_User} style={{ marginRight: 5 }} />,
    path: urls.USER,
    description: MAIN_PAGE_TEMPLATE.DESCRIPTION,
  },

  profile: {
    id: "profile",
    text: "Profil",
    title: "Profil",
    icon: <SvgIcon icon={Icons.Menu_User} style={{ marginRight: 5 }} />,
    path: urls.USER_PROFILE,
    description: MAIN_PAGE_TEMPLATE.DESCRIPTION,
  },

  [urls.LOCAL_BROWSER_CONFIG_SUFFIX]: {
    id: urls.LOCAL_BROWSER_CONFIG_SUFFIX,
    text: "Lokale Browsereinstellung",
    title: "Lokale Browsereinstellung",
    icon: (
      <SvgIcon
        icon={Icons.Menu_Admin_DatabaseInfo}
        style={{ marginRight: 5 }}
      />
    ),
    path: urls.LOCAL_BROWSER_CONFIG,
    description: MAIN_PAGE_TEMPLATE.DESCRIPTION,
  },

  options: {
    id: "options",
    text: "Optionen",
    icon: <SvgIcon icon={Icons.Navigation_Open} style={{ marginRight: 5 }} />,
    path: USER_OPTIONS,
    description: MAIN_PAGE_TEMPLATE.DESCRIPTION,
    title: "Optionen",
  },
  view: {
    id: "view",
    text: "Optionen Ansicht",
    icon: <SvgIcon icon={Icons.View_Expand} style={{ marginRight: 5 }} />,
    path: USER_VIEW_OPTIONS,
    description: MAIN_PAGE_TEMPLATE.DESCRIPTION,
    title: "Optionen Ansicht",
  },
  browser: {
    id: "browser",
    text: "Lokale Browseroptionen",
    icon: (
      <SvgIcon icon={Icons.Symbol_ChevronDown} style={{ marginRight: 5 }} />
    ),
    path: USER_BROWSER_OPTIONS,
    description: MAIN_PAGE_TEMPLATE.DESCRIPTION,
    title: "Lokale Browseroptionen",
  },
  ava: {
    id: "ava",
    text: "AVA-Optionen",
    icon: <SvgIcon icon={Icons.Edit_Cancel} style={{ marginRight: 5 }} />,
    path: USER_AVAS_OPTIONS,
    description: MAIN_PAGE_TEMPLATE.DESCRIPTION,
    title: "AVA-Optionen",
  },

  // Help

  help: {
    id: "help",
    text: "Hilfe-Center",
    icon: <SvgIcon icon={Icons.Menu_Helpcenter} style={{ marginRight: 5 }} />,
    path: urls.HELP_START,
    description: MAIN_PAGE_TEMPLATE.DESCRIPTION,
  },

  appinfo: {
    id: "appinfo",
    text: TitlesHelp.AppInfo,
    title: TitlesHelp.AppInfo,
    icon: <SvgIcon icon={Icons.Menu_Help_AppInfo} style={{ marginRight: 5 }} />,
    path: urls.HELP_ABOUT,
    description: MAIN_PAGE_TEMPLATE.DESCRIPTION,
  },

  sysinfo: {
    id: "sysinfo",
    text: TitlesHelp.SystemInfo,
    title: TitlesHelp.SystemInfo,
    icon: <SvgIcon icon={Icons.Menu_Help_SysInfo} style={{ marginRight: 5 }} />,
    path: urls.ADMIN_SYSINFO,
    description: MAIN_PAGE_TEMPLATE.DESCRIPTION,
  },

  [urls.HELP_ADMIN_HELP_SUFFIX]: {
    id: "admin-help",
    text: TitlesHelp.AdminHelp,
    title: TitlesHelp.AdminHelp,
    icon: (
      <SvgIcon icon={Icons.Menu_Admin_ServerLog} style={{ marginRight: 5 }} />
    ),
    path: urls.HELP_ADMIN_HELP,
    description: MAIN_PAGE_TEMPLATE.DESCRIPTION,
  },

  version: {
    id: "version",
    text: "Versionsinfo",
    icon: <SvgIcon icon={Icons.Log_Info} style={{ marginRight: 5 }} />,
    path: urls.HELP_VERSION,
    description: MAIN_PAGE_TEMPLATE.DESCRIPTION,
  },

  support: {
    id: "support",
    text: "Support",
    icon: <SvgIcon icon={Icons.Menu_Help_Support} style={{ marginRight: 5 }} />,
    path: urls.HELP_SUPPORT,
    title: "Support",
    description: MAIN_PAGE_TEMPLATE.DESCRIPTION,
  },

  impressum: {
    id: "impressum",
    text: "Impressum",
    title: "Impressum",
    icon: <SvgIcon icon={Icons.Symbol_AtSign} style={{ marginRight: 5 }} />,
    path: urls.HELP_IMPRESSUM,
    description: MAIN_PAGE_TEMPLATE.DESCRIPTION,
  },

  datenschutz: {
    id: "datenschutz",
    text: "Datenschutz",
    title: "Datenschutz",
    icon: <SvgIcon icon={Icons.Edit_CancelRed} style={{ marginRight: 5 }} />,
    path: urls.HELP_DATENSCHUTZ,
    description: MAIN_PAGE_TEMPLATE.DESCRIPTION,
  },
};
