import React from "react";
import ABKEselsOhr from "../../../abk-shared/components/atoms/ABKEselsOhr";
import AbkTabNavigation from "../organisms/AbkTabNavigation";
import ABKMobileDrawer from "../../../abk-shared/components/molecules/ABKMobileDrawer";
import ABK9LOGO from "../../assets/Logo.ABK9.Long.svg";
import { DrawerSelectEvent } from "@progress/kendo-react-layout";
import ExtendedDrawerItemProps from "../../interfaces/ExtendedDrawerItemProps";
import { useNavigate } from "react-router-dom";
import ABKMobileSidebar from "../../../abk-shared/components/molecules/ABKMobileSidebar";
import { INotification } from "../../../abk-shared/components/organisms/ABKSideBar/NotificationBox";
import ABKBreadcrumbs from "../molecules/ABKBreadcrumbs";
import InfoBox from "./MainPageTemplate/InfoBox";
import { FunctionOnNotificationClick } from "../../../abk-shared/components/organisms/ABKSideBar/SideBar";

interface Props {
  children: React.ReactNode;
  currentDrawerItems: ExtendedDrawerItemProps[];
  onDrawerItemSelect: (e: DrawerSelectEvent) => void;
  notifications: INotification[];
  onCloseNotification: (notification: INotification) => void;
  infoHubOptions?: React.ReactNode;
  onNotificationClick?: FunctionOnNotificationClick;
  headerRef: React.RefObject<HTMLDivElement>;
  includeTitle: boolean;
  includeDescription: boolean;
  scrollableContainerRef: React.RefObject<HTMLDivElement>;
}

const MobileStructure = ({
  children,
  currentDrawerItems,
  notifications,
  onCloseNotification,
  onNotificationClick,
  headerRef,
  includeTitle,
  includeDescription,
  scrollableContainerRef,
  infoHubOptions,
}: Props) => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [sidebarOpen, setSidebarOpen] = React.useState(false);

  const navigate = useNavigate();

  return (
    <>
      {drawerOpen && (
        <ABKMobileDrawer
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          logopath={ABK9LOGO}
          currentDrawerItems={currentDrawerItems}
          onDrawerItemSelect={(item) => {
            navigate(item.route);
            setDrawerOpen(false);
          }}
        />
      )}
      {sidebarOpen && (
        <ABKMobileSidebar
          open={sidebarOpen}
          onClose={() => setSidebarOpen(false)}
          notifications={notifications}
          onCloseNotification={onCloseNotification}
          onNotificationClick={onNotificationClick}
          infoHubOptions={infoHubOptions}
        />
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: "8px",
          alignItems: "center",
          backgroundColor: "#DFDFDF",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <ABKEselsOhr
            icon="Navigation.Menu"
            position="left"
            onClick={() => setDrawerOpen(true)}
          />
          <ABKBreadcrumbs />
        </div>
        <ABKEselsOhr
          icon="Menu.File.FileInfo"
          position="right"
          onClick={() => setSidebarOpen(true)}
        />
      </div>
      <div
        style={{
          height: "100%",
          overflow: "auto",
          display: "flex",
          flex: 1,
          width: "100%",
          backgroundColor: "#DFDFDF",
          paddingTop: "0.25rem",
          paddingBottom: "0",
        }}
      >
        <div className="k-drawer-content" style={{ flex: 1 }}>
          <InfoBox
            headerRef={headerRef}
            includeTitle={includeTitle}
            includeDescription={includeDescription}
          />
          <AbkTabNavigation />
          <div
            ref={scrollableContainerRef}
            className="abk-viewport"
            id="outlet-container"
            style={{ flex: 1 }}
          >
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileStructure;
