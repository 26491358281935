import { FileInfo } from "../FileTypeMap";

const CGSFileInfo: FileInfo = {
  drawerItems: [
    "Datei-Info",
    "Datei-Protokoll",
    "Kenndaten",
    "Aufbau",
    "Einträge",
    "Grafiken",
    "Weitere Gliederungssysteme",
    "Weitere Parameterlisten",
  ],
};

export default CGSFileInfo;
