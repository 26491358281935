import { AbkIconsMap } from "../../../interfaces/abk9SvgIcons";
import { SvgIcon } from "@progress/kendo-react-common";
import "./style.scss";

export interface NavigationButtonItemProps {
  text: string;
  icon: string;
  active?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement>;
  disabled?: boolean;
  hasSeparator?: boolean;
  title?: string;
}

const ABKNavigationButtonItem = ({
  text,
  icon,
  active = false,
  onClick,
  disabled = false,
  hasSeparator,
  title,
}: NavigationButtonItemProps) => {
  const handleClick = (event: any) => {
    if (disabled) return;

    if (onClick) {
      onClick(event);
      return;
    }
  };

  const color = createColor(disabled, active);
  return (
    <div
      className="app-navigator-item"
      onClick={handleClick}
      style={{ borderBottom: hasSeparator ? "solid 1px #6e6b64" : "none" }}
      aria-disabled={disabled}
      title={title}
    >
      <div className="icon-container">
        <SvgIcon icon={AbkIconsMap.get(icon)} color={color} />
      </div>
      <p style={{ color: color }}>{text}</p>
    </div>
  );
};

function createColor(disabled: boolean, active: boolean) {
  if (disabled) return "#aaaaaa";
  if (active) return "#999900";
  return "#6e6b64";
}

export default ABKNavigationButtonItem;
