import { SvgIcon } from "@progress/kendo-react-common";
import "./style.css";
import ABKNavigationButtonItem, {
  NavigationButtonItemProps,
} from "../../atoms/ABKNavigationButtonItem";
import ABKPopUpButton from "../../molecules/ABKPopUpButton";
import * as Icons from "../../../../abk-shared/interfaces/abk9SvgIcons";

interface MenuButtonItem {
  items?: NavigationButtonItemProps[];
  isDesktop?: boolean;
}

const MenuButton = ({ items, isDesktop }: MenuButtonItem) => {
  return (
    <ABKPopUpButton
      toolTipText="App-Menü"
      buttonIsAnchor={true}
      insideButtonElement={
        isDesktop ? (
          <SvgIcon icon={Icons.Menu_Appmenu} />
        ) : (
          <SvgIcon icon={Icons.Menu_Appmenu} height={24} width={24} />
        )
      }
      insidePopupElement={
        <div>
          {items?.map((item, index) => (
            <ABKNavigationButtonItem key={index} {...item} />
          ))}
        </div>
      }
    />
  );
};

export default MenuButton;
