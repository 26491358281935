import { EditorToolsSettings, EditorTools } from "@progress/kendo-react-editor";

const customItalicSettings: EditorToolsSettings.InlineFormatSettings = {
    mark: "i",
    altMarks: ["em"],

    props: {
        title: "Kursiv (Strg+I)",
        svgIcon: EditorToolsSettings.italic.props.svgIcon,
    },
    messages: {},
};

const OenormItalic = EditorTools.createInlineFormatTool(customItalicSettings);

export default OenormItalic;