import React from "react";
import { GridContextMenuHandler } from "../hooks/useGridContextMenu";
import { Popup } from "@progress/kendo-react-popup";
import ABKNavigationButtonItem from "../../../atoms/ABKNavigationButtonItem";
import {
  CreateGridActionsFunction,
  GridActionObject,
} from "../interfaces/GridAction";
import { GenericObject } from "../../../../interfaces/GenericObject";

type Props = {
  gridContextMenu: GridContextMenuHandler;
  selectedItems: GenericObject[];
  gridActions?: CreateGridActionsFunction;
};

const ABKBasisGridContextMenu: React.FC<Props> = ({
  gridContextMenu,
  selectedItems,
  gridActions,
}) => {
  React.useEffect(() => {
    const handleBodyClick = () => {
      gridContextMenu.setShow(false);
    };

    document.body.addEventListener("click", handleBodyClick);
    return () => {
      document.body.removeEventListener("click", handleBodyClick);
    };
  }, []);

  const actions = gridActions ? gridActions(selectedItems) : [];

  const menuItems = actions.map((action, index) => {
    if (React.isValidElement(action)) {
      return (
        <p key={index} className="k-px-lg">
          <i>{action}</i>
        </p>
      );
    }

    const actionObject = action as GridActionObject;
    return (
      <ABKNavigationButtonItem
        text={actionObject.text}
        onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) => {
          actionObject.onClick(event, selectedItems);
        }}
        icon={actionObject.icon}
        key={actionObject.text}
      />
    );
  });

  return (
    <Popup show={gridContextMenu.show} offset={gridContextMenu.offset.current}>
      {menuItems}
    </Popup>
  );
};

export default ABKBasisGridContextMenu;
