import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { addToJobsDisplayed } from "../../../../../core/db/dbJobs";
import { Job } from "../../../../../core/interfaces/Job";

type MutationPostExport = {
  dbName: string;
  contId: string;
  exportFormat: string;
};

const usePostExport = () => {
  const postExport = async ({
    dbName,
    contId,
    exportFormat,
  }: MutationPostExport) =>
    await axios.post(
      `/api/db/${dbName}/cont/${contId}/run/export?format=${exportFormat}`
    );

  return useMutation({
    mutationFn: postExport,
    onSuccess: (response, variables) => {
      const job: Job = response.data;
      const database = variables.dbName;
      addToJobsDisplayed(job, database);
    },
  });
};

export default usePostExport;
