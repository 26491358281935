import * as React from "react";
import {
  Editor,
  EditorChangeEvent,
  EditorUtils,
  ProseMirror,
} from "@progress/kendo-react-editor";
import { DirectEditorProps } from "prosemirror-view";
import "./insertImageDialog.css";
import "./index.css";

import { insertImagePlugin } from "./insertImagePlugin";
import { insertImageFiles } from "./utils";
import { useState } from "react";
import textHighlight = EditorUtils.textHighlight;

const { Schema, EditorState, keymap } = ProseMirror;
const {
  pasteCleanup,
  sanitize,
  sanitizeClassAttr,
  sanitizeStyleAttr,
  removeAttribute,
  replaceImageSourcesFromRtf,
} = EditorUtils;

const pasteSettings = {
  convertMsLists: true,
  // stripTags: 'span|font'
  attributes: {
    class: sanitizeClassAttr,
    style: sanitizeStyleAttr,
    // keep `width`, `height` and `src` attributes
    width: () => {},
    height: () => {},
    src: () => {},
    // Removes `lang` attribute
    // lang: removeAttribute,

    // removes other (unspecified above) attributes
    "*": removeAttribute,
  },
};

const styles = `
    * {
        font-family: var(--kendo-font-family);
    }
    
    p {
         font-family: var(--kendo-font-family);
    }
    
    .ProseMirror table[border="0"] td {
        border: 1px dashed gray;
    }
    
    .ProseMirror table[border="1"] td {
        border: 1px solid black;
    }
`;

interface AbkOenormEditorProps {
  value: string;
  setValue: (value: string) => void;
  hidden?: boolean;
  tools: Array<any>;
}

const AbkOenormEditor: React.FC<AbkOenormEditorProps> = ({
  value,
  setValue,
  hidden,
  tools,
}) => {
  const [showDialog, setShowDialog] = useState(false);

  const onImageInsert = (args: any) => {
    const { files, view, event } = args;
    const nodeType = view.state.schema.nodes.image;
    const position =
      event.type === "drop"
        ? view.posAtCoords({
            left: event.clientX,
            top: event.clientY,
          })
        : null;
    insertImageFiles({
      view,
      files,
      nodeType,
      position,
    });
    return files.length > 0;
  };

  const onMount = (event: { viewProps: DirectEditorProps; dom: any }) => {
    const state = event.viewProps.state;
    let plugins: any[] = [];

    if (state) {
      plugins = [...state.plugins, insertImagePlugin(onImageInsert)];
      plugins.push(textHighlight());
    }
    // Füge die Plugins für Text-Highlighting und Tabelle-Resizing hinzu
    //plugins.push(...tableResizing());

    // Füge das Keybinding für "Mod-f" hinzu, um das Finden und Ersetzen zu öffnen
    plugins.push(
      keymap({
        "Mod-f": () => {
          setShowDialog(true);
          return true;
        },
      })
    );

    const tableNodeSpec = {
      ...state.schema.spec.nodes.get("table"),
    };
    tableNodeSpec.attrs = tableNodeSpec.attrs || {};
    tableNodeSpec.attrs["border"] = {
      default: "1",
    };
    const newNodes = state.schema.spec.nodes.update("table", tableNodeSpec);

    const newSchema = new Schema({
      nodes: newNodes,
      marks: state.schema.spec.marks,
    });

    const newState = EditorState.create({
      doc: EditorUtils.createDocument(newSchema, value),
      plugins,
    });

    // Erstelle die EditorView mit den aktualisierten Plugins
    const view = new ProseMirror.EditorView(
      {
        mount: event.dom,
      },
      {
        ...event.viewProps,
        state: newState,
      }
    );

    const iframeDocument = event.dom.ownerDocument;
    const style = iframeDocument.createElement("style");
    style.appendChild(iframeDocument.createTextNode(styles));
    iframeDocument.head.appendChild(style);

    return view;
  };

  const handleEditorChange = (e: EditorChangeEvent) => {
    setValue(e.html);
  };

  return (
    <div
      className="abk-oenorm-editor-wrapper"
      style={hidden === true ? { display: "none" } : {}}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <Editor
        tools={tools}
        onPasteHtml={(event) => {
          let html = pasteCleanup(sanitize(event.pastedHtml), pasteSettings);

          // If the pasted HTML contains images with sources pointing to the local file system,
          // `replaceImageSourcesFromRtf` will extract the sources from the RTF and place them to images 'src' attribute in base64 format.
          if (event.nativeEvent.clipboardData) {
            html = replaceImageSourcesFromRtf(
              html,
              event.nativeEvent.clipboardData
            );
          }
          return html;
        }}
        onMount={onMount}
        className={"abk-oenorm-editor"}
        defaultEditMode={"div"}
        contentStyle={{}}
        value={value}
        onChange={handleEditorChange}
      />
    </div>
  );
};

export default AbkOenormEditor;
