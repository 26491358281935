import Highlighter from "react-highlight-words";
import { ContWithLastChangedModified } from "../../../../../core/interfaces/Cont";
import { DATA_KEY } from "../../../../../abk-shared/components/organisms/ABKBasisGrid/constants";

type Props = {
  data: ContWithLastChangedModified;
  filterValue: string;
  shouldDisplayOwner: boolean;
};
const DateienCardBelowBody = ({
  data,
  filterValue,
  shouldDisplayOwner,
}: Props) => (
  <>
    {shouldDisplayOwner ? (
      <div className="besitzer" style={{ paddingLeft: "8px" }}>
        <div className="text">
          Besitzer:{" "}
          <Highlighter
            searchWords={[filterValue]}
            textToHighlight={data.OWNER}
          />
        </div>
      </div>
    ) : null}
    <div className="zuletzt-besucht" style={{ paddingLeft: "8px" }}>
      {data.LASTCHANGED ? (
        <div className="text">
          Zuletzt geändert:{" "}
          <Highlighter
            searchWords={[filterValue]}
            textToHighlight={data.LASTCHANGED[DATA_KEY.DISPLAYED]}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  </>
);

export default DateienCardBelowBody;
