import { useQuery } from "@tanstack/react-query";
import fetchDB from "../../../core/queries/fetchDB";

const useGetDB = () => {
  return useQuery({
    queryKey: ["datenbanken"],
    queryFn: fetchDB,
  });
};

export default useGetDB;
