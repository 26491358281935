import "./style.css";

export interface ABKNotificationCounterProps {
  errorCount: number;
  warningCount: number;
  infoCount: number;
}

const ABKNotificationCounter = ({
  errorCount,
  warningCount,
  infoCount,
}: ABKNotificationCounterProps) => {
  return (
    <div>
      <span className="notification-count error-count">{errorCount}F</span>
      <span className="notification-count warning-count">{warningCount}W</span>
      <span className="notification-count info-count">{infoCount}I</span>
    </div>
  );
};

export default ABKNotificationCounter;
