import DBDropDown from "../../../core/components/organisms/AbkAppBar/DBDropdown";
import SysInfoGrid from "../../../core/components/molecules/SysInfoGrid";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DATABASE_PLACEHOLDER, OPEN } from "../../../core/constants/urls";
import useCurrentDBStore from "../../../core/stores/useCurrentDBStore";

// Legacy: to be removed?
const StartseiteIndex = () => {
  const { currentDB } = useCurrentDBStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentDB) {
      navigate(OPEN.replace(DATABASE_PLACEHOLDER, currentDB.DBNAME));
    }
  }, [currentDB]);

  return (
    <div style={{ padding: 10 }}>
      <h2> Datenbank Auswahl </h2>
      <DBDropDown isDesktop={true} />
      <h2>Sys Info</h2>
      <SysInfoGrid />
    </div>
  );
};

export default StartseiteIndex;
