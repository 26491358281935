import useGetVersion from "../../../../../modules/Startseite/hooks/useGetVersion";
import { useNavigate, useParams } from "react-router-dom";
import { DATABASE_PLACEHOLDER, USER_PROFILE } from "../../../../constants/urls";
import ABKPopUpButton from "../../../molecules/ABKPopUpButton";
import { Loader } from "@progress/kendo-react-indicators";
import { SvgIcon } from "@progress/kendo-react-common";
import * as Icons from "../../../../../abk-shared/interfaces/abk9SvgIcons";
import ABKNavigationButtonItem from "../../../../../abk-shared/components/atoms/ABKNavigationButtonItem";

export const ProfileButton = ({ isDesktop }: { isDesktop: boolean }) => {
  const { data: sysinfo, isLoading } = useGetVersion();
  const db = useParams().db;
  const navigate = useNavigate();

  if (isLoading) <Loader />;

  if (!sysinfo) return null;

  const items = [
    {
      text: "Benutzerprofil",
      onClick: () => {
        navigate(
          USER_PROFILE.replace(DATABASE_PLACEHOLDER, db || "Standard-DB")
        );
      },
      icon: "Menu.User",
    },
    {
      text: "Abmelden",
      onClick: () => {
        window.location.href = `/api/logout`;
      },
      icon: "General.Export",
    },
  ];

  return (
    <ABKPopUpButton
      toolTipText="Angemeldeter Benutzer"
      buttonIsAnchor={true}
      hasBorderRight={true}
      insideButtonElement={
        isDesktop ? (
          <span>
            {sysinfo?.AuthUserDisplay
              ? sysinfo.AuthUserDisplay
              : "Nicht angemeldet"}
          </span>
        ) : (
          <SvgIcon icon={Icons.Menu_User} height={24} width={24} />
        )
      }
      insidePopupElement={
        <>
          {items.map((item) => (
            <ABKNavigationButtonItem
              key={item.text}
              {...item}
              hasSeparator={item.text === "Abmelden" ? false : true}
            />
          ))}
        </>
      }
    />
  );
};

export default ProfileButton;
