import { Button } from "@progress/kendo-react-buttons";
import { db } from "../../core/db/db";
import { dbJobs } from "../../core/db/dbJobs";

const LocalBrowserConfiguration = () => (
  <div>
    <Button
      onClick={() => {
        db.delete();
      }}
    >
      IndexedDB - Drafts Datenbank löschen
    </Button>{" "}
    <Button
      onClick={() => {
        dbJobs.delete();
      }}
    >
      IndexedDB - Jobs Datenbank löschen
    </Button>
  </div>
);

export default LocalBrowserConfiguration;
