import ABKFormFieldInput from "../ABKFormFieldInput";
import ABKFormRow from "../ABKFormRow";
import _ from "lodash";

export interface AddressSelectProps {
  value: Address;
  setValue: (value: Address) => void;
  readOnly: boolean;
}

export interface Address {
  land: string;
  ort: string;
  strasse: string;
  plz: string;
}

function checkIfInitiallyEmpty(initialValue: Address) {
  const emptyAddress: Address = {
    land: "",
    ort: "",
    plz: "",
    strasse: "",
  };
  return _.isEqual(initialValue, emptyAddress);
}

const ABKAddressSelect = ({
  value,
  setValue,
  readOnly,
}: AddressSelectProps) => {
  const changeValue = (field: string, newValue: any) => {
    if (isInitiallyEmpty) {
      const newAddress: Address = { ...value, [field]: newValue, land: "AUT" };
      setValue(newAddress);
    } else {
      const newAddress: Address = { ...value, [field]: newValue };
      setValue(newAddress);
    }
  };

  const isInitiallyEmpty = checkIfInitiallyEmpty(_.cloneDeep(value));

  return (
    <>
      <ABKFormRow>
        <ABKFormFieldInput
          label={"Straße"}
          value={value.strasse}
          setValue={
            readOnly ? undefined : (value) => changeValue("strasse", value)
          }
          editorId={"street"}
        />
        <ABKFormFieldInput
          label="Ort"
          value={value.ort}
          setValue={readOnly ? undefined : (value) => changeValue("ort", value)}
          editorId={"ort"}
        />
      </ABKFormRow>
      <ABKFormRow>
        <ABKFormFieldInput
          label={"Postleitzahl"}
          value={value.plz}
          setValue={
            readOnly
              ? undefined
              : (value) => {
                  if (value.length <= 10) changeValue("plz", value);
                }
          }
          editorId={"plz"}
        />
        <ABKFormFieldInput
          label="Land"
          value={value.land}
          placeHolder="z.B. AUT"
          setValue={
            readOnly
              ? undefined
              : (country) => {
                  if (country.length <= 3) {
                    const newAddress: Address = {
                      ...value,
                      land: country.toUpperCase(),
                    };
                    setValue(newAddress);
                  }
                }
          }
          editorId={"land"}
          tooltip="Land ist ein dreibuchstabiges Länderkürzel laut ISO 3166-1 (Alpha-3)"
        />
      </ABKFormRow>
    </>
  );
};

export default ABKAddressSelect;
