import React from "react";
import { AllgemeinFieldInputProps } from "./AllgemeinForm";
import { isValidDate } from "../../../../../../abk-shared/utility/dateUtils";
import { format } from "date-fns";
import ABKDatePickerInput from "../../../../../../abk-shared/components/atoms/ABKDatePickerInput";

export default function KenndatenDate({
  readOnly,
  setPageState,
  pageState,
  kenndaten,
}: AllgemeinFieldInputProps) {
  const versionDateParsed = new Date(kenndaten.VERSIONSDATE ?? "");
  const versionDate = isValidDate(versionDateParsed) ? versionDateParsed : null;
  const setVersionDate = React.useCallback(
    (date: Date | null) => {
      const dateString = date == null ? null : format(date, "yyyy-MM-dd");
      setPageState({
        ...pageState,
        Kenndaten: {
          ...kenndaten,
          VERSIONSDATE: dateString,
        },
      });
    },
    [pageState, kenndaten]
  );

  return (
    <ABKDatePickerInput
      editorId="datum"
      label="Datum"
      value={versionDate}
      setValue={setVersionDate}
      readOnly={readOnly}
    />
  );
}
