import useCurrentDBStore from "../../../../core/stores/useCurrentDBStore";
import DbProtocolPage from "./DbProtocolPage";

const DbProtocolPageWrapper = () => {
  const { currentDB } = useCurrentDBStore();
  if (!currentDB) return <></>;

  return (
    <DbProtocolPage
      key={`db-protocol-${currentDB.DBNAME}`}
      currentDB={currentDB}
    />
  );
};

export default DbProtocolPageWrapper;
