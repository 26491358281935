import { TextArea, TextAreaChangeEvent } from "@progress/kendo-react-inputs";
import { ExpansionPanelActionEvent, ExpansionPanel } from "@progress/kendo-react-layout";
import ABKControlledExpansionPanel from "../../../../../abk-shared/components/atoms/ABKControlledExpansionPanel";

interface BeschreibungPanelProps {
  title: React.ReactNode;
  isExpanded: boolean;
  onAction: ((event: ExpansionPanelActionEvent) => void) | undefined;
  beschreibung: string;
  setBeschreibung: ((event: TextAreaChangeEvent) => void) | undefined;
}

const BeschreibungPanel = ({ title, isExpanded, onAction, beschreibung, setBeschreibung }: BeschreibungPanelProps) => {
  return <ABKControlledExpansionPanel
  title={title}
  expanded={isExpanded}
  key="beschreibung"
  onAction={onAction}
>
  {isExpanded && (
    <div onKeyDown={(e) => e.stopPropagation()}>
      <TextArea
        defaultValue={"Textarea"}
        autoSize={true}
        style={{
          resize: "none",
          overflowX: "hidden",
        }}
        value={beschreibung}
        onChange={setBeschreibung}
      />
    </div>
  )}
</ABKControlledExpansionPanel>;
};

export default BeschreibungPanel;