import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import useCurrentDBStore from "../../../../../core/stores/useCurrentDBStore";
import React from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { ContWithLastChangedModified } from "../../../../../core/interfaces/Cont";
import { DEFAULT_EXPORT_FORMAT, generateExportFormats } from "./exportFormats";

type ExportFormat = {
  fileEnd: string;
  formatName: string;
};

export type ContainerToExport = {
  dbName: string;
  contId: string;
  exportFormat: string;
};
export type ExportDialogResponse = {
  shouldProceed: boolean;
  containersToExport?: Array<ContainerToExport>;
};

type Props = {
  toggleDialog: () => void;
  selectedContainers: ContWithLastChangedModified[];
  exportDialogPromise: {
    resolve: (value: ExportDialogResponse) => void;
  } | null;
};

const ExportDialog = ({
  toggleDialog,
  selectedContainers,
  exportDialogPromise,
}: Props) => {
  const { currentDB } = useCurrentDBStore();

  const [selectedExportFormat, setSelectedExportFormat] =
    React.useState<ExportFormat>(DEFAULT_EXPORT_FORMAT);
  const [exportFileFormats, setExportFileFormats] = React.useState<
    ExportFormat[]
  >([]);

  React.useLayoutEffect(() => {
    const { exportFormatArray, selectedExportFormat } =
      generateExportFormats(selectedContainers);

    setExportFileFormats(exportFormatArray);
    setSelectedExportFormat(selectedExportFormat);
  }, [selectedContainers]);

  return (
    <Dialog title="Bestätigen" onClose={toggleDialog}>
      <p>Dateien zum Exportieren: {selectedContainers.length} Dateien</p>
      <DropDownList
        data={exportFileFormats}
        textField="formatName"
        value={selectedExportFormat}
        onChange={(e) => setSelectedExportFormat(e.value)}
        style={{ minWidth: 300 }}
      />
      <DialogActionsBar>
        <button
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
          onClick={() => {
            toggleDialog();
            if (exportDialogPromise)
              exportDialogPromise.resolve({ shouldProceed: false });
          }}
        >
          Abbrechen
        </button>
        <button
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
          onClick={() => {
            if (currentDB == null || !exportDialogPromise) return;

            const containersToExport: ContainerToExport[] = [];
            selectedContainers.forEach((file) => {
              containersToExport.push({
                dbName: currentDB.DBNAME,
                contId: file.CONTID,
                exportFormat: selectedExportFormat.fileEnd,
              });
            });

            exportDialogPromise.resolve({
              shouldProceed: true,
              containersToExport,
            });
            toggleDialog();
          }}
        >
          Exportieren
        </button>
      </DialogActionsBar>
    </Dialog>
  );
};

export default ExportDialog;
