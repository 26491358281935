import {
  DropDownList,
  DropDownListProps,
} from "@progress/kendo-react-dropdowns";

import ABKFormFieldInputWrapper from "../ABKFormFieldInputWrapper";
import ABKLabel from "../ABKLabel";

interface Props extends DropDownListProps {
  label: string;
  editorId: string;
}

export default function ABKFormDropdown({
  label,
  editorId,
  ...dropDownListProps
}: Props) {
  return (
    <ABKFormFieldInputWrapper>
      <ABKLabel editorId={editorId}>{label}</ABKLabel>
      <DropDownList name={editorId} title={label} {...dropDownListProps} />
    </ABKFormFieldInputWrapper>
  );
}
