import { SvgIcon } from "@progress/kendo-react-common";
import * as Icons from "../../../../abk-shared/interfaces/abk9SvgIcons";
import React from "react";
import { Offset, Popup, PopupProps } from "@progress/kendo-react-popup";
import ClickAwayListener from "react-click-away-listener";
import "./style.css";

interface ABKPopUpButtonProps {
  buttonStyles?: React.CSSProperties;
  insideButtonElement: React.ReactNode;
  insidePopupElement: React.ReactNode;
  offset?: Offset;
  popUpProps?: PopupProps;
  buttonIsAnchor: boolean;
  hasBorderRight?: boolean;
  toolTipText: string;
  hasAppBarButtonStyles?: boolean;
}

/**
 * ABKPopUpButton component.
 *
 * Eine Komponente, welches ein Popup zeigt auf Knopfdruck!
 *
 * Es bassiert sich auf der KendoReact Popup Komponente, jedoch mit dem Feature, dass wenn man wo anders klickt, das Popup verschwindet.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.buttonStyles - The styles for the button.
 * @param {boolean} props.buttonIsAnchor - Determines if the button is an anchor element.
 * @param {ReactNode} [props.insideButtonElement] - The element inside the button.
 * @param {Object} props.popUpProps - The props for the Popup component.
 * @param {ReactNode} props.insidePopupElement - The element inside the popup.
 * @param {boolean} props.hasBorderRight - Determines if the button has a right border.
 * @returns {JSX.Element} The ABKPopUpButton component.
 */
const ABKPopUpButton = ({
  buttonStyles,
  buttonIsAnchor,
  insideButtonElement = <SvgIcon icon={Icons.Edit_CancelRed} />,
  popUpProps,
  insidePopupElement,
  hasBorderRight,
  toolTipText,
  hasAppBarButtonStyles = true,
}: ABKPopUpButtonProps) => {
  const [show, setShow] = React.useState(false);
  const anchor = React.useRef(null);

  return (
    <>
      <div
        className={hasAppBarButtonStyles ? "button-container" : ""}
        title={toolTipText}
        ref={anchor}
      >
        <div
          className={
            hasAppBarButtonStyles
              ? `border-container ${hasBorderRight ? "border-right" : ""}`
              : ""
          }
        >
          <div
            onClick={() => setShow(true)}
            style={buttonStyles}
            className={
              hasAppBarButtonStyles
                ? `popup-button ${show ? "active" : ""}`
                : ""
            }
          >
            {insideButtonElement}
          </div>
        </div>
      </div>
      <Popup
        {...popUpProps}
        anchor={buttonIsAnchor ? anchor.current : null}
        show={show}
        popupClass={"popup-content"}
      >
        <ClickAwayListener onClickAway={() => setShow(false)}>
          {/*
            Wenn wir auf einem Button klicken, wollen wir,
            dass das Menü sich automatisch schließt.
          */}
          <div onClick={() => setShow(false)}>{insidePopupElement}</div>
        </ClickAwayListener>
      </Popup>
    </>
  );
};

export default ABKPopUpButton;
