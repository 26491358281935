import axios, { AxiosResponse } from "axios";
import { encodeContID } from "../../../core/utility/cont";
import { Cont } from "../../../core/interfaces/Cont";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEY_GET_CONTAINER } from "./useGetCont";

type MutationCreateContFromTemplate = {
  templateID: string;
  database: string;
};

export default function useCreateContFromTemplate() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ templateID, database }: MutationCreateContFromTemplate) => {
      return createContainerFromTemplate(templateID, database);
    },
    onSuccess: (_response, { database }) => {
      queryClient.invalidateQueries({
        queryKey: [database, QUERY_KEY_GET_CONTAINER],
      });
    },
  });
}

export const createContainerFromTemplate = (
  template: string,
  database: string
): Promise<AxiosResponse<Cont>> => {
  const endpoint = `/api/db/${database}/cont`;

  // template string is encoded to http friendly string
  const templateEncoded = encodeContID(template);

  return axios.post<{}, AxiosResponse<Cont>>(
    endpoint,
    {},
    {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
      params: {
        copyfromcontid: templateEncoded,
      },
    }
  );
};
