import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEY_GET_CONTAINER } from "./useGetCont";
import axios from "axios";
import { BACKEND_URL } from "../../../core/constants/urls";

const useDuplicateCont = (database: string | undefined) => {
  const queryClient = useQueryClient();
  const url = `${BACKEND_URL}/api/db/${database}/cont`;

  return useMutation({
    mutationFn: async (containerId: string) => {
      return axios.post(`${url}?copyfromcontid=${containerId}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [database, QUERY_KEY_GET_CONTAINER],
      });
    },
  });
};

export default useDuplicateCont;
