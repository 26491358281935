import { Link, useParams } from "react-router-dom";
import { Cont } from "../../../../core/interfaces/Cont";
import { getFileInfoUrlForContainer } from "../../../../core/utility/cont";
import "./style.css";
import ABKBasisGridHighlighter from "../../../../abk-shared/components/organisms/ABKBasisGrid/components/ABKBasisGridHighlighter";

type Props = {
  container: Cont;
  filterValue: string;
};

const ContainerLink: React.FC<Props> = ({ container, filterValue }) => {
  const params = useParams();
  const database = params.db || "Test-DB";

  return (
    <Link
      className="link k-d-block k-text-ellipsis"
      to={getFileInfoUrlForContainer(database, container.CONTID)}
    >
      <ABKBasisGridHighlighter filterValue={filterValue} node={container.BEZ} />
    </Link>
  );
};

export default ContainerLink;
