import ABKDrawerItem from "../../atoms/ABKDrawerItem";
import { ExtendedDrawerItemProps } from "../AbkDrawer";
import "./style.css";

const DrawerItems = ({
  currentDrawerItems,
  onDrawerItemSelect,
}: {
  currentDrawerItems: ExtendedDrawerItemProps[];
  onDrawerItemSelect: (e: ExtendedDrawerItemProps) => void;
}) => (
  <ul className="k-drawer-items">
    {currentDrawerItems.map((item, index) => (
      <ABKDrawerItem
        key={index}
        item={item}
        onDrawerItemSelect={onDrawerItemSelect}
      />
    ))}
  </ul>
);

export default DrawerItems;
