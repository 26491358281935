import React from "react";
import { GridColumnPropsCustom } from "../../interfaces/GridColumns";
import {
  DEFAULT_MIN_GRID_WIDTH,
  DEFAULT_NB_COLUMNS_DYNAMIC_WIDTH,
} from "./useGridColumns";

export default function useInitializeGridMinimumWidth(
  isMobileGrid: boolean,
  grid: React.MutableRefObject<any>,
  minGridWidth: React.MutableRefObject<number>,
  nbColumnsDynamicWidth: React.MutableRefObject<number>,
  columnsDisplayed: GridColumnPropsCustom[],
  setGridCurrent: React.Dispatch<React.SetStateAction<number>>,
  applyMinWidth: boolean,
  setApplyMinWidth: React.Dispatch<React.SetStateAction<boolean>>
) {
  React.useLayoutEffect(() => {
    if (isMobileGrid) return;

    grid.current = document.querySelector(".k-grid");
    window.addEventListener("resize", handleResize);

    /*
      We reset these two refs before initializing them.

      The reason is that this useEffect will run twice in development mode,
      because of "React.StrictMode". (https://react.dev/reference/react/StrictMode)
      In order to prevent having falsy values of these refs, we reset them.

      These 2 lines should have no impact on a production environment.
    */
    minGridWidth.current = DEFAULT_MIN_GRID_WIDTH;
    nbColumnsDynamicWidth.current = DEFAULT_NB_COLUMNS_DYNAMIC_WIDTH;

    const incrementRefs = (column: GridColumnPropsCustom) => {
      if (column.minWidth !== undefined)
        minGridWidth.current += column.minWidth;
      /*
        If a column has a manualWidth, it will not be counted in the columns being
        able to expand
      */
      if (column.manualWidth === undefined) nbColumnsDynamicWidth.current++;
    };

    columnsDisplayed.forEach((column) => {
      const columnChildren = column.children as GridColumnPropsCustom[];
      const columnHasChildren = Array.isArray(columnChildren);
      if (columnHasChildren) {
        for (const children of columnChildren) incrementRefs(children);
        return;
      }

      incrementRefs(column);
    });

    setGridCurrent(grid.current.offsetWidth);
    setApplyMinWidth(grid.current.offsetWidth < minGridWidth.current);
  }, [isMobileGrid]);

  /*
    Checks if the current display is more or less than the grid minimum width
    defined in the useEffect
  */
  const handleResize = () => {
    if (grid.current.offsetWidth < minGridWidth.current && !applyMinWidth) {
      setApplyMinWidth(true);
    } else if (grid.current.offsetWidth > minGridWidth.current) {
      setGridCurrent(grid.current.offsetWidth);
      setApplyMinWidth(false);
    }
  };
}
