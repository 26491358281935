const parTypeMapper = new Map<string, string>([
  ["Head", "Überschrift"],
  ["Set", "Parameterset"],
  ["Num", "Zahl"],
  ["Text", "Text (einzeilig)"],
  ["Memo", "Text (mehrzeilig)"],
  ["Date", "Datum"],
  ["Bool", "Ja/Nein"],
]);

export default parTypeMapper;

export type ParType =
  | "Head"
  | "Set"
  | "Num"
  | "Text"
  | "Memo"
  | "Date"
  | "Bool";
export const parTypeList: ParType[] = [
  "Head",
  "Set",
  "Num",
  "Text",
  "Memo",
  "Date",
  "Bool",
];

export const hideParMapper = new Map<string, string>([
  ["Show", "Überall anzeigen (ÖNORM)"],
  ["HidePos", "Bei höherer Ebene (Summendarstellung) ausblenden"],
  ["HideSum", "Bei niedrigerer Ebene (Positionswerte) ausblenden"],
  ["Hide", "Immer ausblenden"],
]);

export const hideParList: string[] = ["Show", "HidePos", "HideSum", "Hide"];
