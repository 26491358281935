import { ContWithLastChangedModified } from "../../../../../core/interfaces/Cont";

export const DEFAULT_EXPORT_FORMAT = {
  fileEnd: "abk9zip",
  formatName: "ABK9-Sicherungsdatei (*.abk9zip)",
};

export function getExportFormatForType(type: string) {
  switch (type) {
    case "cpl":
    case "cgs":
      return {
        fileEnd: "onbgs",
        formatName: "Gliederungssystem/Parameterliste (*.onbgs)",
      };
    case "cek":
      return {
        fileEnd: "conbaek",
        formatName: "Elementkatalog - ÖNORM A2063 (*.conbaek)",
      };
    default:
      return DEFAULT_EXPORT_FORMAT;
  }
}

export function generateExportFormats(
  containers: ContWithLastChangedModified[]
) {
  const result = {
    selectedExportFormat: DEFAULT_EXPORT_FORMAT,
    exportFormatArray: [DEFAULT_EXPORT_FORMAT],
  };
  if (containers.length === 0) return result;

  if (containers.length === 1) {
    const containerType = containers[0].CONTTYPE;
    const selectedExportFormat = getExportFormatForType(containerType);

    result.selectedExportFormat = selectedExportFormat;
    result.exportFormatArray.push(selectedExportFormat);
    return result;
  }

  const { areSameType, exportFormatOfFirstFile } =
    containersHaveTheSameType(containers);
  if (!areSameType) return result;

  result.selectedExportFormat = exportFormatOfFirstFile;
  result.exportFormatArray.push(exportFormatOfFirstFile);
  return result;
}

function containersHaveTheSameType(containers: ContWithLastChangedModified[]) {
  const exportFormatOfFirstFile = getExportFormatForType(
    containers[0].CONTTYPE
  );
  const result = {
    exportFormatOfFirstFile: exportFormatOfFirstFile,
    areSameType: false,
  };

  for (const container of containers) {
    if (
      exportFormatOfFirstFile.fileEnd !==
      getExportFormatForType(container.CONTTYPE).fileEnd
    )
      return result;
  }

  result.areSameType = true;
  return result;
}
