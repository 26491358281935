import "./AbkAppBar.css";
import ProfileButton from "./ProfileButton";
import DBDropDown from "./DBDropdown";
import useAppBarState from "../../../stores/useAppBarState";
import ABKAppBarLogo from "../../../assets/Logo.ABK9.Long.svg";
import HelpButton from "./HelpButton";
import TenantsButton from "./TenantsButton";
import BasisAppBar from "../../../../abk-shared/components/organisms/ABKAppBar";
import {
  ADMIN_ORG,
  DATABASE_PLACEHOLDER,
  PORTAL_URL,
  STARTSEITE,
} from "../../../constants/urls";
import { useNavigate, useParams } from "react-router-dom";

interface Props {
  isDropDown?: boolean;
  isDesktop: boolean;
}

const AbkAppBar = ({ isDropDown = false, isDesktop }: Props) => {
  const { appBarTitle } = useAppBarState();
  const db = useParams().db;

  const navigate = useNavigate();

  return (
    <BasisAppBar
      title={appBarTitle}
      isDesktop={isDesktop}
      menuButtonItems={[
        {
          text: "ABK9-Startseite",
          onClick: () => {
            navigate(
              STARTSEITE.replace(DATABASE_PLACEHOLDER, db || "Standard-DB")
            );
          },
          icon: "Logo.ABK9.Icon",
        },
        {
          text: "ABK9-Admin-Center",
          onClick: () => {
            navigate(
              ADMIN_ORG.replace(DATABASE_PLACEHOLDER, db || "Standard-DB")
            );
          },
          icon: "Menu.Admin.Tabicon",
        },
        {
          text: "ABK9-Portal-Server",
          onClick: () => {
            window.location.href = PORTAL_URL;
          },
          icon: "Logo.Portalserver.Icon",
          hasSeparator: true,
        },
        {
          text: "ABK-Kundenportal",
          onClick: () => {
            window.open("https://kundenportal.abk.at", "_blank");
          },
          icon: "Logo.Kundenportal.Icon",
        },
      ]}
      appbarSectionRight={[
        <TenantsButton isDesktop={isDesktop} />,
        isDropDown ? <DBDropDown isDesktop={isDesktop} /> : <></>,
        <ProfileButton isDesktop={isDesktop} />,
        <HelpButton isDesktop={isDesktop} />,
      ]}
      logo={
        <img
          src={ABKAppBarLogo ? ABKAppBarLogo : "./Logo.ABK9.Long.svg"}
          alt="abk_logo"
          className="abk-logo"
          onClick={() => {
            navigate("/");
          }}
          style={{ cursor: "pointer" }}
        />
      }
    />
  );
};

export default AbkAppBar;
