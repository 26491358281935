import { EditorTools, EditorToolsSettings } from "@progress/kendo-react-editor";

const fontBlockToolSettings: EditorToolsSettings.FormatBlockDropDownListSettings =
  {
    ...EditorToolsSettings.formatBlock,
    defaultItem: {
      text: "Normal",
      value: "p",
    },
    items: [
      {
        text: "Überschrift 1",
        value: "h1",
        style: {
          display: "block",
          fontSize: "2em",
          fontWeight: "bold",
          marginLeft: 0,
        },
      },
      {
        text: "Überschrift 2",
        value: "h2",
        style: {
          display: "block",
          fontSize: "1.5em",
          fontWeight: "bold",
          marginLeft: 0,
        },
      },
      {
        text: "Überschrift 3",
        value: "h3",
        style: {
          display: "block",
          fontSize: "1.17em",
          fontWeight: "bold",
          marginLeft: 0,
        },
      },
    ],
  };

// Von KendoReact Support: https://stackblitz.com/edit/react-oxzbdw?file=app%2Fapp.jsx,app%2FCustomFormatBlock.jsx
const FormatBlockTool = (props: any) => {
  /*
    "CustomFormatBlock" sollte so definiert werden.
    Ansonsten wird das einen Bug verursachen:
    - Im Editor mindestens zwei Absätze mit der Formatierung Normal schreiben
    - Für einen Absatz die Formatierung auf z.B. Überschrift 3 ändern
    - Mit dem Cursor in den anderen Absatz gehen
    - In der Formatierungsauswahl steht weiterhin Überschrift 3 obwohl die
      Formatierung hier Normal ist
    - Will man hier die Formatierung auch auf Überschrift 3 ändern, muss man
      zuerst eine andere Formatierung auswählen damit man dann die Formatierung
      Überschrift 3 zuweisen kann
    https://ib-data.atlassian.net/browse/ABK9-840
  */
  const newProps = { ...fontBlockToolSettings };
  const CustomFormatBlock = EditorTools.createFormatBlockDropDownList(newProps);
  return (
    <CustomFormatBlock
      {...props}
      style={{
        width: "230px",
        ...props.style,
      }}
    />
  );
};

export default FormatBlockTool;
