import "./style.css";

const ABKMultipleInputsLine = ({
  elementLeft,
  elementRight,
}: {
  elementLeft: React.ReactNode;
  elementRight?: React.ReactNode;
}) => {
  return (
    <div className="multiple-inputs-line">
      <div style={{ width: "100%" }}>{elementLeft}</div>
      <div style={{ width: "100%" }}>
        {elementRight ? elementRight : <div></div>}
      </div>
    </div>
  );
};

export default ABKMultipleInputsLine;
