import { FileInfo } from "../FileTypeMap";

const CPLFileInfo: FileInfo = {
  drawerItems: [
    "Datei-Info",
    "Datei-Protokoll",
    "Kenndaten",
    "Einträge",
    "Piktogramme",
    "Weitere Parameterlisten",
    "Weitere Gliederungssysteme",
  ],
};

export default CPLFileInfo;
