import { ExpansionPanelActionEvent } from "@progress/kendo-react-layout";
import AbkOenormEditor from "../../../../../core/components/organisms/AbkOenormEditor";
import ABKControlledExpansionPanel from "../../../../../abk-shared/components/atoms/ABKControlledExpansionPanel";
import editorTools from "../../../../../core/components/organisms/AbkOenormEditor/editorTools";
import { useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import * as React from "react";
import CustomHTMLDialog from "../../../../../core/components/organisms/AbkOenormEditor/CustomHTMLDialog";

interface NotizPanelProps {
  title: React.ReactNode;
  isExpanded: boolean;
  onAction: ((event: ExpansionPanelActionEvent) => void) | undefined;
  notiz: string;
  setNotiz: (notiz: string) => void;
}

const NotizPanel = ({
  title,
  isExpanded,
  onAction,
  notiz,
  setNotiz,
}: NotizPanelProps) => {
  const [showDialogWindow, setShowDialogWindow] = useState(false);

  const CustomViewHtmlHook = () => {
    return (
      <Button
        style={{ height: 30 }}
        icon={"view-html"}
        onClick={() => setShowDialogWindow(true)}
        title="HTML-Ansicht"
      >
        HTML
      </Button>
    );
  };

  return (
    <ABKControlledExpansionPanel
      title={title}
      key="notiz"
      className={"notiz-section"}
      expanded={isExpanded}
      onAction={onAction}
      style={{ padding: "0" }}
    >
      {isExpanded && (
        <AbkOenormEditor
          tools={[
            ...editorTools.oenormTools,
            [CustomViewHtmlHook],
            ...editorTools.commonTools,
            ...editorTools.tableTools,
          ]}
          value={notiz}
          setValue={(value) => {
            setNotiz(value);
          }}
          hidden={!isExpanded}
        />
      )}
      {showDialogWindow && (
        <CustomHTMLDialog
          value={notiz}
          setShowDialogWindow={setShowDialogWindow}
        />
      )}
    </ABKControlledExpansionPanel>
  );
};

export default NotizPanel;
