import { ExtendedDrawerItemProps } from "../../molecules/AbkDrawer";
import { SvgIcon } from "@progress/kendo-react-common";

const ABKDrawerItem = ({
  item,
  onDrawerItemSelect,
}: {
  item: ExtendedDrawerItemProps;
  onDrawerItemSelect: (e: ExtendedDrawerItemProps) => void;
}) => (
  <li
    className={`k-drawer-item ${item.selected ? "k-state-selected" : ""}`}
    role="menuitem"
    onClick={() => onDrawerItemSelect(item)}
  >
    <SvgIcon icon={item.svgIcon} />
    <span className="k-item-text">{item.text}</span>
  </li>
);

export default ABKDrawerItem;
