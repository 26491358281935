import { DrawerItemProps, DrawerItem } from "@progress/kendo-react-layout";
import { SvgIcon } from "@progress/kendo-react-common";

const DrawerCustomItem = (props: DrawerItemProps) => {
  return (
    <DrawerItem {...props}>
      <span
        title={props.text}
        // Default CSS class for the span wrapper of SVG icon
        className="k-icon k-svg-icon"
      >
        <SvgIcon icon={props.svgIcon} />
      </span>
      <span className="k-item-text">{props.text}</span>
    </DrawerItem>
  );
};

export default DrawerCustomItem;
