import "./style.css";

const DrawerLogo = ({ logopath }: { logopath: string }) => (
  <img
    src={logopath}
    alt="abk_logo"
    className="mobile-abk-logo"
    onClick={() => {
      window.location.href = "/";
    }}
    style={{ cursor: "pointer" }}
  />
);

export default DrawerLogo;
