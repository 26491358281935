import React from 'react';
import { EditorView } from 'prosemirror-view';
import {Button} from "@progress/kendo-react-buttons";
import { borderStyleIcon } from "@progress/kendo-svg-icons";
interface Props {
    view: EditorView;
}

class CustomTableBorderButton extends React.Component<Props> {
    handleClick = () => {
        const { view } = this.props;
        const { selection } = view.state;
        const domNode = view.domAtPos(selection.anchor);

        let tableNode : Node|null = domNode.node;
        while (tableNode && tableNode.nodeName !== 'TABLE') {
            tableNode = tableNode.parentNode;
        }

        if (tableNode) {
            // @ts-ignore
            const attrs = tableNode.attributes;

            // @ts-ignore
            const toggleValue = attrs["border"].value === "0" ? "1" : "0";
            let tablePos = view.posAtDOM(tableNode,0);
            for(; tablePos > 0; tablePos -= 1) {
                if (view.nodeDOM(tablePos)?.nodeName === "TABLE") {
                    break;
                }
            }
            
            const tr = view.state.tr.setNodeAttribute(tablePos, "border", toggleValue);
            view.dispatch(tr);
            view.focus();
        }
    };

    render() {
        const { view } = this.props;
        if (view) {
            const { selection } = view.state;
            const domNode = view.domAtPos(selection.anchor);
            
            let tableNode: Node | null = domNode.node;
            while (tableNode && tableNode.nodeName !== 'TABLE') {
                tableNode = tableNode.parentNode;
            }
            if (tableNode?.nodeName === 'TABLE') {
                return (
                  // eslint-disable-next-line react/jsx-no-undef
                  <Button onClick={this.handleClick} title={"Tabellenrahmen ein-/ausschalten"} svgIcon={borderStyleIcon}/>
                );
            }
        }
        return <></>
    }
}

export default CustomTableBorderButton;
