import { Cont } from "../../../core/interfaces/Cont";
import { useQuery } from "@tanstack/react-query";
import { BACKEND_URL } from "../../../core/constants/urls";
import { QUERY_KEY_GET_CONTAINER } from "./useGetCont";
import axios from "axios";
import { Versioned } from "../../../core/interfaces/Versioned";

const useGetContInfo = (
  datenbank: string | undefined,
  contID: string | undefined
  // cgs#2023-10-25-21.06.28.0640@testuser@abk.at
) => {
  const fetchCont = async (): Promise<Versioned<Cont>> => {
    const encodedContID = contID!
      .replaceAll("#", "%23")
      .replaceAll("\\", "%5C");
    try {
      const response = await axios.get(
        encodeURI(`${BACKEND_URL}/api/db/${datenbank}/cont/${encodedContID}`)
      );
      const etag = response.headers["etag"];
      return { etag: etag, value: response.data };
    } catch (error) {
      console.error("Fetching container info failed:", error);
      throw error;
    }
  };

  return useQuery({
    queryKey: [datenbank, QUERY_KEY_GET_CONTAINER, "Info", contID],
    queryFn: fetchCont,
    enabled: !!datenbank && !!contID,
    staleTime: 0, // Fetch data immediately
    retry: () => false,
    /*
      Mache keine "refetchOnWindowFocus", ansonsten das kein zu dem folgenden Bug
      führen:

      Eine Datei in 2 Tabs geöffnet,
      in beiden Dateiname ändern,
      dann in einem Tab die Änderung speichern
      → in 2. Tab ist dann meine Änderung ohne Rückfrage weg, wurde automatisch mit
      den aktuellen Daten überschrieben
    */
    refetchOnWindowFocus: false,
  });
};

export default useGetContInfo;
