import { GenericObject } from "../../../../../interfaces/GenericObject";
import { setGroupIds } from "@progress/kendo-react-data-tools";
import { FilterDescriptor, process, State } from "@progress/kendo-data-query";
import { DATA_KEY } from "../../constants";

export function processAndSetGroupIds(
  items: GenericObject[],
  dataState: State,
  fieldsWithInternalValue: string[]
) {
  const { sort, group, filter } = defineSortAndGroupToProcessInternalValue(
    dataState,
    fieldsWithInternalValue
  );
  const dataStateWithAllItems = dataStateWithTakeAllData(items, {
    ...dataState,
    sort,
    group,
    filter,
  });
  const dataResult = process(items, dataStateWithAllItems);
  setGroupIds({ data: dataResult.data, group: dataState.group });
  return dataResult;
}

const dataStateWithTakeAllData = (
  items: GenericObject[],
  currentDataState: State
) => ({
  ...currentDataState,
  take: items.length,
});

function defineSortAndGroupToProcessInternalValue(
  newDataState: State,
  fieldsWithInternalValue: string[]
) {
  const sort = newDataState.sort?.map((sortDescriptor) => {
    let fieldSorted = sortDescriptor.field;
    if (fieldsWithInternalValue.includes(sortDescriptor.field))
      fieldSorted = `${sortDescriptor.field}.${DATA_KEY.DISPLAYED}`;

    return {
      ...sortDescriptor,
      field: fieldSorted,
    };
  });

  const group = newDataState.group?.map((groupDescriptor) => {
    let fieldGrouped = groupDescriptor.field;
    if (fieldsWithInternalValue.includes(groupDescriptor.field))
      fieldGrouped = `${groupDescriptor.field}.${DATA_KEY.DISPLAYED}`;

    return {
      ...groupDescriptor,
      field: fieldGrouped,
    };
  });

  let filter: any = newDataState.filter;
  const columnMenuFilters = (newDataState.filter as any)?.filters;
  const firstColumnMenuFilter = columnMenuFilters?.[0]?.filters;
  const hasColumnMenuFilter = Array.isArray(firstColumnMenuFilter);

  if (hasColumnMenuFilter) {
    const columnMenuFiltersProcessed = columnMenuFilters?.map(
      (columnMenuFilter: any) => {
        const filters = columnMenuFilter?.filters?.map(
          (filterDescriptor: FilterDescriptor) => {
            let fieldFiltered = filterDescriptor.field;
            if (
              typeof fieldFiltered === "string" &&
              fieldsWithInternalValue.includes(fieldFiltered)
            ) {
              fieldFiltered = `${filterDescriptor.field}.${DATA_KEY.DISPLAYED}`;
            }
            return {
              ...filterDescriptor,
              field: fieldFiltered,
            };
          }
        );
        return {
          ...columnMenuFilter,
          filters,
        };
      }
    );

    filter = {
      ...newDataState.filter,
      filters: columnMenuFiltersProcessed,
    };
  }

  return { sort, group, filter };
}

export const generateFiltersToMatchDisplayedValue = (
  fieldsFiltered: string[],
  fieldsWithInternalValue: string[],
  newFilterValue: string
) =>
  fieldsFiltered.map((field) => {
    let fieldFiltered = field;
    if (fieldsWithInternalValue.includes(field))
      fieldFiltered = `${field}.${DATA_KEY.DISPLAYED}`;

    return {
      field: fieldFiltered,
      operator: "contains",
      value: newFilterValue,
    };
  });
