import {
  ExpansionPanel,
  ExpansionPanelActionEvent,
  ExpansionPanelContent,
  ExpansionPanelProps,
} from "@progress/kendo-react-layout";
import React from "react";

export interface ABKControlledExpansionPanelProps extends ExpansionPanelProps {
  children: React.ReactNode;
  expanded: boolean;
  onAction: ((event: ExpansionPanelActionEvent) => void) | undefined;
}

const ABKControlledExpansionPanel = (
  props: ABKControlledExpansionPanelProps
) => {
  return (
    <ExpansionPanel {...props}>
      {props.expanded && (
        <ExpansionPanelContent>{props.children}</ExpansionPanelContent>
      )}
    </ExpansionPanel>
  );
};

export default ABKControlledExpansionPanel;
