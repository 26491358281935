import { create } from "zustand";

interface ActiveCardState {
    containerId: string;
    setActiveCardContainerId: (newContainerId: string) => void;
    resetActiveCardContainerId: () => void;
}

export const useActiveCardState = create<ActiveCardState>((set) => ({
    // initial state
    containerId: "",
    // methods for manipulating state
    setActiveCardContainerId: (newContainerId: string) => 
        set({ containerId: newContainerId }),
    resetActiveCardContainerId: () => set({containerId: ""}),
}))