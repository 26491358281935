export const BACKEND_URL = "";
export const PORTAL_URL = "https://www.abk.today";

export const DATABASE_PLACEHOLDER = "%datenbank%";
const datenbank = `/db/${DATABASE_PLACEHOLDER}`;
export const DEFAULT_DB = "_default_";

// Startseite
export const STARTSEITE = `${datenbank}/start`;
export const OPEN = `${STARTSEITE}/open`;
export const IMPORT = `${STARTSEITE}/import`;
export const EXPORT = `${STARTSEITE}/export`;
export const TEMPLATES = `${STARTSEITE}/new`;
export const FILES = `${STARTSEITE}/files`;

// Tabs (auch Filter-Buttons genannt)
export const LAST_USED = "last-used";
// N.B.: Die anderen sind jetzt nicht hier, sondern direkt in den URLs geschrieben

// Datei
export const FILE_IN_URL = "file";
export const FILE_PREFIX = `${datenbank}/${FILE_IN_URL}`;
export const FILE_INFO = `${FILE_PREFIX}/%contId%/info`;
export const FILE_PROTOCOL_SUFFIX = "protocol";
export const FILE_PROTOCOL = `${FILE_PREFIX}/%contId%/${FILE_PROTOCOL_SUFFIX}`;
export const FILE_CAT = `${FILE_PREFIX}/%contId%/cat`;
export const FILE_KENNDATEN_SUFFIX = "kenndaten";
export const FILE_KENNDATEN = `${FILE_PREFIX}/%contId%/${FILE_KENNDATEN_SUFFIX}`;
export const FILE_KENNDATEN_VORVERSION_SUFFIX = "vorversion";
export const FILE_KENNDATEN_VORVERSION = `${FILE_PREFIX}/%contId%/${FILE_KENNDATEN_SUFFIX}/${FILE_KENNDATEN_VORVERSION_SUFFIX}`;
export const FILE_AUFBAU_SUFFIX = "aufbau";
export const FILE_AUFBAU = `${FILE_PREFIX}/%contId%/${FILE_AUFBAU_SUFFIX}`;
export const FILE_EINTRAEGE_SUFFIX = "items";
export const FILE_EINTRAEGE = `${FILE_PREFIX}/%contId%/${FILE_EINTRAEGE_SUFFIX}`;
export const FILE_GRAFIKEN_SUFFIX = "grafiken";
export const FILE_GRAFIKEN = `${FILE_PREFIX}/%contId%/${FILE_GRAFIKEN_SUFFIX}`;
export const FILE_PIKTOGRAMME_SUFFIX = "piktogramme";
export const FILE_PIKTOGRAMME = `${FILE_PREFIX}/%contId%/${FILE_PIKTOGRAMME_SUFFIX}`;
export const FILE_WEITEREGLIEDERUNGSSYSTEME_SUFFIX =
  "weiteregliederungssysteme";
export const FILE_WEITEREGLIEDERUNGSSYSTEME = `${FILE_PREFIX}/%contId%/${FILE_WEITEREGLIEDERUNGSSYSTEME_SUFFIX}`;
export const FILE_WEITEREPARAMETERLISTEN_SUFFIX = "weitereparameterlisten";
export const FILE_WEITEREPARAMETERLISTEN = `${FILE_PREFIX}/%contId%/${FILE_WEITEREPARAMETERLISTEN_SUFFIX}`;

// Admin
export const ADMIN = `${datenbank}/admin`;
export const ADMIN_ORG = `${ADMIN}/org`;
export const ADMIN_DEPARTMENTS = `${ADMIN}/departments`;
export const ADMIN_DATABASES = `${ADMIN}/databases`;
export const ADMIN_LICENSES = `${ADMIN}/licenses`;
export const ADMIN_USERS = `${ADMIN}/users`;
export const ADMIN_USER_PROFILE = `${ADMIN_USERS}/%userid%/profile`;
export const ADMIN_SYSINFO = `${ADMIN}/sysinfo`;
export const ADMIN_DB_STATUS = `${ADMIN}/db-status`;
export const ADMIN_DB_PROTOCOL_SUFFIX = "db-protocol";
export const ADMIN_DB_PROTOCOL = `${ADMIN}/${ADMIN_DB_PROTOCOL_SUFFIX}`;
export const ADMIN_SERVER_STATUS = `${ADMIN}/server-status`;
export const ADMIN_SERVER_LOG = `${ADMIN}/server-log`;
export const ADMIN_SERVER_INFO = `${ADMIN}/server-info`;

// User
export const USER = `${datenbank}/user`;
export const USER_PROFILE = `${USER}/profile`;
export const LOCAL_BROWSER_CONFIG_SUFFIX = "local-browser-configuration";
export const LOCAL_BROWSER_CONFIG = `${USER}/${LOCAL_BROWSER_CONFIG_SUFFIX}`;
export const USER_OPTIONS = `${USER}/options`;
export const USER_BROWSER_OPTIONS = `${USER_OPTIONS}/browser`;
export const USER_VIEW_OPTIONS = `${USER_OPTIONS}/view`;
export const USER_AVAS_OPTIONS = `${USER_OPTIONS}/ava`;

// HELP
export const HELP = `/help`;
export const HELP_START = `${HELP}/appinfo`;
export const HELP_SYSINFO = `${HELP}/sysinfo`;
export const HELP_ADMIN_HELP_SUFFIX = "admin-help";
export const HELP_ADMIN_HELP = `${HELP}/${HELP_ADMIN_HELP_SUFFIX}`;
export const HELP_ABOUT = `${HELP}/appinfo`;
export const HELP_VERSION = `${HELP}/version`;
export const HELP_SUPPORT = `${HELP}/support`;
export const HELP_IMPRESSUM = `${HELP}/impressum`;
export const HELP_DATENSCHUTZ = `${HELP}/datenschutz`;
