import {
  ParameterListe,
  ParameterListeStatus,
} from "../../../../interfaces/parameterlisten";
import ABKFormDropdown from "../../../../../../abk-shared/components/atoms/ABKFormDropdown";

type Props = {
  pageState: ParameterListe;
  setPageState: (parameterliste: ParameterListe) => void;
};

export default function DropdownStatus({ pageState, setPageState }: Props) {
  const dataItemKey = "id";
  const textField = "text";
  const defaultValue = {
    [textField]: "Entwurf",
    [dataItemKey]: ParameterListeStatus.Entwurf,
  };
  const statusOptions = [
    defaultValue,
    {
      [textField]: "Freigegeben",
      [dataItemKey]: ParameterListeStatus.Freigegeben,
    },
  ];
  const value = statusOptions.find(
    (option) => option[dataItemKey] === pageState?.Kenndaten?.STATUS
  );

  return (
    <ABKFormDropdown
      label="Status"
      name="allgemein-status"
      textField={textField}
      dataItemKey={dataItemKey}
      data={statusOptions}
      value={value}
      onChange={(event) =>
        setPageState({
          ...pageState,
          Kenndaten: {
            ...pageState.Kenndaten,
            STATUS: event.value[dataItemKey],
          },
        })
      }
      editorId={"status"}
    />
  );
}
