import Highlighter from "react-highlight-words";
import { Eintrag } from "../../interfaces/parameterlisten";
import parTypeMapper from "./maps";
import { Chip } from "@progress/kendo-react-buttons";
import CardCheckbox from "../../../Startseite/components/molecules/DateienCard/DateienCardCheckbox";
import { FunctionSetDataItemSelected } from "../../../../abk-shared/components/organisms/ABKBasisGrid/hooks/useGridRowSelection";

type Props = {
  rowRef: React.RefObject<HTMLDivElement>;
  item: Eintrag;
  filterValue: string;
  isSelected: boolean;
  setDataItemSelected: (
    item: Eintrag,
    isSelected: boolean,
    isHead: boolean
  ) => void;
};

const EintraegeCard = ({
  rowRef,
  item,
  filterValue,
  isSelected,
  setDataItemSelected,
}: Props) => {
  const isSet = item.ITEMTYPE === "Set";
  const isHead = item.ITEMTYPE === "Head";
  const isBold = isSet || isHead;

  const isBoldContainerStyles = isBold
    ? {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px",
        backgroundColor: isSelected ? "#DCDCA6" : "#F5F5F5",
        borderBottom: "1px solid black",
      }
    : {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid black",
        backgroundColor: isSelected ? "#DCDCA6" : "transparent",
        padding: "0.25rem 0",
      };

  return (
    <>
      <div
        ref={rowRef}
        title={item.BEZ}
        style={{
          ...isBoldContainerStyles,
          position: "relative",
        }}
        onClick={() => {
          setDataItemSelected(item, !isSelected, isHead);
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <span
            style={{
              fontWeight: "bold",
              color: isHead ? "#999900" : "black",
            }}
          >
            <Highlighter
              searchWords={[filterValue]}
              textToHighlight={item.BEZ || ""}
            />
          </span>
          {!isBold && <span>{parTypeMapper.get(item.ITEMTYPE) || ""}</span>}
        </div>
        {!isBold && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
              marginRight: "19px",
            }}
          >
            <Chip text={item.VARNAME} />
            <CardCheckbox
              data={item}
              isSelected={isSelected}
              setDataItemSelected={
                setDataItemSelected as FunctionSetDataItemSelected
              }
            />
          </div>
        )}
        {isBold && (
          <div
            style={{
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
              right: "19px",
            }}
          >
            <CardCheckbox
              data={item}
              isSelected={isSelected}
              setDataItemSelected={
                setDataItemSelected as FunctionSetDataItemSelected
              }
            />
          </div>
        )}
      </div>
    </>
  );
};

export default EintraegeCard;
