import axios from "axios";
import { Job } from "../interfaces/Job";

export default async function fetchLogs(job: Job) {
  /*
    "job.LogUrl" ist z.B.: "/db/Test-DB/cont/main/jobs/2024-07-12-13.41.16.8800@jlassi@abk.at/log"
    kann auch sein: "/api/db/Test-DB/cont/main/jobs/2024-07-12-13.41.16.8800@jlassi@abk.at/log"
  */
  const logUrl = job.LogUrl.replace("/api/", "/");
  const url = `${window.location.origin}/api${logUrl}`;
  const logs = await axios.get(url);
  return logs.data.Items;
}
