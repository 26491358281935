import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import useCurrentDBStore from "../../../core/stores/useCurrentDBStore";
import { DB } from "../../../abk-shared/interfaces/db";

export const QUERY_KEY_DB = "db";
const useGetDBStatus = () => {
  const { currentDB } = useCurrentDBStore();

  const url = `/api/db/${currentDB?.DBNAME}`;

  const fetchDBStatus = async (): Promise<DB> => {
    const response = await axios.get<DB>(url);
    return response.data;
  };

  return useQuery({
    queryKey: [QUERY_KEY_DB, currentDB?.DBNAME],
    queryFn: fetchDBStatus,
  });
};

export default useGetDBStatus;
