import { Button } from "@progress/kendo-react-buttons";
import { Popup } from "@progress/kendo-react-popup";
import { useEffect, useRef, useState } from "react";
import Info from "../../../../abk-shared/assets/icons/General.Hint.svg";
import "./style.css";

type Props = { popupContent: string | undefined };
const ABKInfoPopup = ({ popupContent }: Props) => {
  const anchor = useRef<Button | null>(null); // For button reference
  const [show, setShow] = useState(false);

  useEffect(() => {
    // Listen for clicks on the document so whenever the user clicks outside the popup, the popup is closed
    const handleOutsidePopupClick = (event: MouseEvent | TouchEvent) => {
      // Check if click was outside the button and popup
      if (anchor.current && !anchor.current.element?.contains(event.target as Node) && show) {
        setShow(false);
      }
    };

    // add event listeners to listen to click/touch events in order to 
    // close the popup once the user touches/clicks somewhere on the screen
    document.body.addEventListener("click", handleOutsidePopupClick);
    document.body.addEventListener("touchstart", handleOutsidePopupClick);

    return () => {
      // Cleanup the event listener on component unmount
      document.body.removeEventListener("click", handleOutsidePopupClick);
      document.body.removeEventListener("touchstart", handleOutsidePopupClick);
    };
  }, [show]);

  const onClick = (event: any) => {
    setShow(!show);
  };

  return (
    <div>
      <Button
        imageUrl={Info}
        imageAlt="info icon"
        className="abk-form-field-info-icon"
        onClick={onClick}
        ref={anchor}
        themeColor={"primary"}
      />
      <Popup
        anchor={anchor.current?.element}
        anchorAlign={{
          horizontal: "right",
          vertical: "center",
        }}
        popupAlign={{
          horizontal: "left",
          vertical: "top",
        }}
        show={show}
        popupClass={"abk-info-icon-popup-content"}
      >
        <div>{popupContent}</div>
        
      </Popup>
    </div>
  );
};

export default ABKInfoPopup;