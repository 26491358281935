import ABKExpansionPanel from "../../../../../../abk-shared/components/atoms/ABKExpansionPanel";

const ZuordnungProduktgruppenPanel = () => {
  return (
    <ABKExpansionPanel title="Zuordnung Produktgruppen">
      Zuordnung Produktgruppen
    </ABKExpansionPanel>
  );
};

export default ZuordnungProduktgruppenPanel;
