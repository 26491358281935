import Highlighter from "react-highlight-words";

type Props = {
  filterValue: string;
  node: string;
};
const ABKBasisGridHighlighter = ({ filterValue, node }: Props) => (
  <Highlighter
    searchWords={[filterValue]}
    textToHighlight={node}
    /*
      Both of these classes are here to have a consistent font-size
      between the highlighted and unhighlighted cells
    */
    highlightClassName="k-table-sm"
    unhighlightClassName="k-table-sm"
  />
);

export default ABKBasisGridHighlighter;
