import ABKPopUpButton from "../../../molecules/ABKPopUpButton";
import useGetVersion from "../../../../../modules/Startseite/hooks/useGetVersion";
import { Loader } from "@progress/kendo-react-indicators";
import { SvgIcon } from "@progress/kendo-react-common";
import * as Icons from "../../../../../abk-shared/interfaces/abk9SvgIcons";
import { PORTAL_URL } from "../../../../constants/urls";
import ABKNavigationButtonItem from "../../../../../abk-shared/components/atoms/ABKNavigationButtonItem";

interface Props {
  isDesktop: boolean;
}

const TenantsButton = ({ isDesktop }: Props) => {
  const { data, isLoading } = useGetVersion();

  if (isLoading) <Loader />;

  if (!data) return null;

  return (
    <ABKPopUpButton
      toolTipText="Aktiver Tenant"
      buttonIsAnchor={true}
      insideButtonElement={
        isDesktop ? (
          <span>{data.TenantnameDisplay}</span>
        ) : (
          <SvgIcon icon={Icons.Navigation_Refresh} height={24} width={24} />
        )
      }
      hasBorderRight={true}
      insidePopupElement={
        <ABKNavigationButtonItem
          text="Tenant wechseln"
          onClick={() => {
            window.location.href = PORTAL_URL;
          }}
          icon="Navigation.Refresh"
        />
      }
    />
  );
};

export default TenantsButton;
