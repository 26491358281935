type Props = {
  title: string;
  panelContent: string;
  shouldShowPreview: boolean;
  contentIsHTML?: boolean;
};

export default function PanelTitle({
  title,
  panelContent,
  shouldShowPreview,
  contentIsHTML = false,
}: Props) {
  const preview = generatePreview(panelContent, contentIsHTML);

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div className="k-expander-title" style={{ minWidth: "fit-content" }}>
        {title}
      </div>
      {shouldShowPreview && <span className="Textvorschau">{preview}</span>}
    </div>
  );
}

function generatePreview(panelContent: string, contentIsHTML: boolean) {
  const defaultValue = "(Ohne Inhalt)";

  if (!contentIsHTML) {
    if (panelContent) return panelContent;
    return defaultValue;
  }

  const stripedContent = stripHtmlTags(panelContent).trim();
  if (!stripedContent) return defaultValue;
  const panelContentWithSpaces = replaceClosingTagsBySpaces(panelContent);
  const panelContentWithoutTags = stripHtmlTags(panelContentWithSpaces);
  return panelContentWithoutTags;
}

const stripHtmlTags = (text: string) => text.replace(/<[^>]*>?/gm, "");

const replaceClosingTagsBySpaces = (text: string) =>
  text.replace(/<\/[^>]*>?/gm, " ");
