import { ExpansionPanelProps } from "@progress/kendo-react-layout";
import React from "react";
import ABKControlledExpansionPanel from "../ABKControlledExpansionPanel";

export interface ABKExpansionPanelProps extends ExpansionPanelProps {
  children: React.ReactNode;
  defaultExpanded?: boolean;
  additionalOnAction?: () => void;
}

const ABKExpansionPanel = (props: ABKExpansionPanelProps) => {
  const [expanded, setExpanded] = React.useState(
    props.defaultExpanded || false
  );
  return (
    <ABKControlledExpansionPanel
      {...props}
      expanded={expanded}
      onAction={() => {
        if (props.additionalOnAction) {
          props.additionalOnAction();
        }
        setExpanded(!expanded);
      }}
    >
      {props.children}
    </ABKControlledExpansionPanel>
  );
};

export default ABKExpansionPanel;
