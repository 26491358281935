import { SvgIcon } from "@progress/kendo-react-common";
import { AbkIconsMap } from "../../../interfaces/abk9SvgIcons";

const ABKEselsOhr = ({
  icon,
  position,
  onClick,
}: {
  icon: string;
  position: "left" | "right";
  onClick?: () => void;
}) => {
  const getPositionStyle = (position: "left" | "right") => {
    if (position === "left") {
      return {
        paddingLeft: "5px",
        borderRadius: "0 4px 4px 0",
      };
    } else if (position === "right") {
      return {
        paddingRight: "5px",
        borderRadius: "4px 0 0 4px",
      };
    }
  };

  const title = position === "left" ? "Navigation" : "Information";
  return (
    <div
      title={title}
      id={`EselsOhr-${position}`}
      style={{
        top: "0px",
        ...getPositionStyle(position),
        backgroundColor: "white",
        width: "40px",
        height: "40px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <SvgIcon icon={AbkIconsMap.get(icon)} height={24} width={24} />
    </div>
  );
};

export default ABKEselsOhr;
