import { KEY_REMOVE_FROM_FILTERING } from "../../constants";

export const SELECTED_FIELD = "selected";

export const WIDTH_COLUMN_SELECTED_FIELD = 40;

const COLUMN_SELECTED_FIELD = {
  field: SELECTED_FIELD,
  locked: true,
  /*
    Deaktiviere die Anordnung für diese Spalte, weil sie "locked" ist.
    From: https://www.telerik.com/kendo-react-ui/components/knowledge-base/grid-disable-reordering/#toc-solution
  */
  orderIndex: 0,
  minWidth: WIDTH_COLUMN_SELECTED_FIELD,
  manualWidth: WIDTH_COLUMN_SELECTED_FIELD,
  headerSelectionValue: false,
  [KEY_REMOVE_FROM_FILTERING]: true,
};

export default COLUMN_SELECTED_FIELD;
