export const deMessages = {
  editor: {
    bold: "Fett",
    italic: "Kursiv",
    underline: "Unterstrichen",
    strikethrough: "Durchgestrichen",
    subscript: "Tiefgestellt",
    superscript: "Hochgestellt",
    hyperlink: "Hyperlink einfügen",
    "hyperlink-dialog-title": "Hyperlink einfügen",
    "hyperlink-dialog-content-address": "Web-Adresse",
    "hyperlink-dialog-content-title": "Titel",
    "hyperlink-dialog-content-newwindow": "Link in einem neuen Fenster öffnen",
    "hyperlink-dialog-cancel": "Abbrechen",
    "hyperlink-dialog-insert": "Einfügen",
    image: "Bild einfügen",
    "image-dialog-title": "Bild einfügen",
    "image-address": "Web-Adresse",
    "image-title": "Titel",
    "image-altText": "Abwechselnder Text",
    "image-width": "Breite (px)",
    "image-height": "Höhe (px)",
    "image-cancel": "Abbrechen",
    "image-insert": "Einfügen",
    viewHtml: "HTML anzeigen",
    HTML: "HTML anzeigen",
    "viewHtml-dialog-title": "HTML anzeigen",
    "viewHtml-cancel": "Abbrechen",
    "viewHtml-update": "Aktualisieren",
    unlink: "Hyperlink entfernen",
    undo: "Rückgängig",
    redo: "Wiederherstellen",
    fontSize: "Schriftgröße Standard",
    fontName: "Schriftart Standard",
    format: "Absatzstil",
    alignLeft: "Linksbündig",
    alignRight: "Rechtsbündig",
    alignCenter: "Zentriert",
    alignJustify: "Blocksatz",
    indent: "Einzug vergrößern",
    outdent: "Einzug verkleinern",
    orderedList: "Nummerierung",
    bulletList: "Aufzählungszeichen",
    insertFile: "Datei einfügen",
    "insertfile-dialog-title": "Datei einfügen",
    "insertfile-dialog-content-address": "Webadresse",
    "insertfile-dialog-content-title": "Titel",
    "insertfile-dialog-cancel": "Absagen",
    "insertfile-dialog-insert": "Einfügung",
    print: "Drucken",
    pdf: "Als PDF exportieren",
    foregroundColor: "Schriftfarbe",
    backgroundColor: "Hintergrundfarbe",
    selectAll: "Wählen Sie Alle",
    cleanFormatting: "Saubere Formatierung",
    createTable: "Tabelle erstellen",
    insertTableHint: "{0} x {1}-Tabelle erstellen",
    addRowBefore: "Zeile oben hinzufügen",
    addRowAfter: "Zeile unten hinzufügen",
    addColumnBefore: "Spalte links hinzufügen",
    addColumnAfter: "Spalte rechts hinzufügen",
    deleteRow: "Zeile löschen",
    deleteColumn: "Spalte löschen",
    deleteTable: "Tabelle löschen",
    mergeCells: "Zellen verbinden",
    splitCell: "Geteilte Zelle",
    "findReplace-tool-title": "Suchen und Ersetzen",
    "findReplace-dialog-title": "Suchen und Ersetzen",
    "findReplace-tab-find": "Suchen",
    "findReplace-tab-replace": "Ersetzen",
    "findReplace-findWhat": "Suche nach",
    "findReplace-replaceWith": "Ersetzen mit",
    "findReplace-replace": "Ersetzen",
    "findReplace-replaceAll": "Alles ersetzen",
    "findReplace-matchCase": "Groß/Kleinschreibung beachten",
    "findReplace-matchWord": "Nur ganzes Wort abgleichen",
    "findReplace-matchCyclic": "Zyklisch suchen",
    "findReplace-useRegExp": "Regulären Ausdruck",
    "findReplace-prevMatch": "Zurück",
    "findReplace-nextMatch": "Weiter",
    "findReplace-matches": "{0} von {1}",
    iframeTitle:
      "Bearbeitbarer Bereich. Drücken Sie Alt + F10 für die Symbolleiste.",
    tableProperties: "Tabelleneigenschaften",
    tablePropertiesRows: "Zeilen",
    tablePropertiesColumns: "Spalten",
    tablePropertiesWidth: "Breite",
    tablePropertiesSizeAuto: "Autoeinstellung",
    tablePropertiesHeight: "Höhe",
    tablePropertiesPosition: "Position",
    tablePropertiesLeft: "Links",
    tablePropertiesCenter: "Mittig",
    tablePropertiesRight: "Rechts",
    tablePropertiesAlignment: "Ausrichtung",
    tablePropertiesAlignLeft: "Ausrichtung-Links",
    tablePropertiesAlignCenter: "Ausrichtung-Mittig",
    tablePropertiesAlignRight: "Ausrichtung-Rechts",
    tablePropertiesNoAlignment: "Keine Ausrichtung",
    tablePropertiesCellSpacing: "Zeilen Abstand",
    tablePropertiesCellPadding: "Zellauffüllung",
    tablePropertiesTableBackground: "Tabellenhintergrund",
    tablePropertiesBorderWidth: "Rahmenbreite",
    tablePropertiesBorderColor: "Rahmenfarbe",
    tablePropertiesBorderStyle: "Rahmenstyle",
    tablePropertiesBorderStyleNone: "keine",
    tablePropertiesBorderStyleSolid: "solide",
    tablePropertiesBorderStyleDotted: "gepunktet",
    tablePropertiesBorderStyleDashed: "gestrichelt",
    tablePropertiesBorderStyleDouble: "doppelt",
    tablePropertiesBorderStyleGroove: "rille",
    tablePropertiesBorderStyleRidge: "",
    tablePropertiesBorderStyleInset: "",
    tablePropertiesBorderStyleOutset: "",
    tablePropertiesBorderStyleInitial: "",
    tablePropertiesBorderStyleInherit: "",
    tablePropertiesBorderStyleHidden: "Versteckt",
    tablePropertiesCollapseBorders: "",
  },
  upload: {
    cancel: "Abbrechen",
    clearSelectedFiles: "Auswahl löschen",
    dropFilesHere: "Dateien hier ablegen zum Hochladen",
    headerStatusUploaded: "Aufgaben beendet.",
    headerStatusUploading: "Hochladen...",
    invalidFileExtension: "Dieser Dateityp ist nicht erlaubt.",
    invalidFiles:
      "Ungültige Dateien. Bitte überprüfen Sie die Upload-Anforderungen.",
    invalidMaxFileSize: "Die Datei ist zu groß.",
    invalidMinFileSize: "Die Datei ist zu klein.",
    remove: "Entfernen",
    retry: "Erneut versuchen",
    select: "Dateien auswählen...",
    uploadSelectedFiles: "Hochladen",
    total: "Gesamt",
    files: "Dateien",
    selectTitle: "Klicken Sie hier, um weitere Dateien auszuwählen",
    selectNoFilesTitle: "Keine Dateien ausgewählt",
    statusUploaded: "Die Dateien wurden erfolgreich hochgeladen.",
    statusUploadFailed: "Das Hochladen der Datei(en) war nicht erfolgreich.",
    dropZoneHint: "Ziehen Sie Dateien hierher, um sie hochzuladen.",
    defaultDropZoneNote: "",
    dropZoneNote: "Nur {0} Dateien sind erlaubt.",
  },
};
