import { Outlet, useParams } from "react-router-dom";
import {
  Menu_Admin_Company,
  Menu_Admin_DatabaseLog,
  Menu_Admin_DatabaseInfo,
  Menu_Admin_DatabaseState,
  Menu_Admin_Departments,
  Menu_Admin_License,
  Menu_Admin_Users,
  Menu_Admin_ServerLog,
  Menu_Admin_ServerInfo,
  Menu_Admin_ServerState,
} from "../../abk-shared/interfaces/abk9SvgIcons";
import {
  ADMIN_DATABASES,
  ADMIN_DEPARTMENTS,
  ADMIN_LICENSES,
  ADMIN_ORG,
  ADMIN_USERS,
  ADMIN_DB_STATUS,
  ADMIN_SERVER_STATUS,
  ADMIN_SERVER_LOG,
  ADMIN_SERVER_INFO,
  ADMIN_DB_PROTOCOL,
  DATABASE_PLACEHOLDER,
} from "../../core/constants/urls";
import useDrawerStore from "../../core/stores/useDrawerStore";
import { useEffect } from "react";
import useAppBarState from "../../core/stores/useAppBarState";
import { changeFavIcon } from "../../core/utility/favIcon";
import WrapperTemplate from "../../core/components/templates/WrapperTemplate";

const AdminLayout: React.FC = () => {
  const db = useParams().db;
  const { setAppBarTitle } = useAppBarState();
  const { setCurrentDrawerItems } = useDrawerStore();

  useEffect(() => {
    const drawerItems = [
      {
        text: "Organisation",
        svgIcon: Menu_Admin_Company,
        route: ADMIN_ORG.replace(DATABASE_PLACEHOLDER, db!),
        title: "Organisation",
      },
      {
        text: "Abteilungen",
        svgIcon: Menu_Admin_Departments,
        route: ADMIN_DEPARTMENTS.replace(DATABASE_PLACEHOLDER, db!),
        title: "Abteilungen",
      },
      {
        text: "Benutzer",
        svgIcon: Menu_Admin_Users,
        route: ADMIN_USERS.replace(DATABASE_PLACEHOLDER, db!),
        title: "Benutzer",
      },
      {
        text: "Lizenzen",
        svgIcon: Menu_Admin_License,
        route: ADMIN_LICENSES.replace(DATABASE_PLACEHOLDER, db!),
        title: "Lizenzen",
      },
      {
        text: "Datenbank-Protokoll",
        svgIcon: Menu_Admin_DatabaseLog,
        route: ADMIN_DB_PROTOCOL.replace(DATABASE_PLACEHOLDER, db!),
        title: "Datenbank-Protokoll",
      },
      {
        text: "Datenbanken",
        svgIcon: Menu_Admin_DatabaseInfo,
        route: ADMIN_DATABASES.replace(DATABASE_PLACEHOLDER, db!),
        title: "Datenbanken",
      },
      {
        text: "Datenbank-Status",
        svgIcon: Menu_Admin_DatabaseState,
        route: ADMIN_DB_STATUS.replace(DATABASE_PLACEHOLDER, db!),
        title: "DB Status",
      },
      {
        text: "Server-Log",
        svgIcon: Menu_Admin_ServerLog,
        route: ADMIN_SERVER_LOG.replace(DATABASE_PLACEHOLDER, db!),
        title: "Server-Log",
      },
      {
        text: "Server-Info",
        svgIcon: Menu_Admin_ServerInfo,
        route: ADMIN_SERVER_INFO.replace(DATABASE_PLACEHOLDER, db!),
        title: "Server-Info",
      },
      {
        text: "Server-Status",
        svgIcon: Menu_Admin_ServerState,
        route: ADMIN_SERVER_STATUS.replace(DATABASE_PLACEHOLDER, db!),
        title: "Server-Status",
      },
    ];
    setAppBarTitle("Admin-Center");
    changeFavIcon("/assets/favicons/Menu.Admin.Tabicon.svg");
    setCurrentDrawerItems(drawerItems);
  }, [db, setAppBarTitle, setCurrentDrawerItems]);

  return (
    <WrapperTemplate includeTitle includeDescription>
      <Outlet />
    </WrapperTemplate>
  );
};

export default AdminLayout;
