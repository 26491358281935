import React from "react";
import SideBarTitle from "../SideBarTitle";
import QuickInfoItem from "./QuickInfoItem";
import ABKNavigationButtonItem from "../../../atoms/ABKNavigationButtonItem";
import ABKButtonCopyToClipboard from "../../../atoms/ABKButtonCopyToClipboard";
import { useQuickInfoState } from "../../../../stores/useQuickInfoState";

export type QuickinfoContent = React.ReactNode | InfoItem[];
export type InfoItem = React.ReactNode | InfoItemObject;

export type InfoItemObject = {
  title: string;
  content: InfoItemContent;
};
export type InfoItemContent = string | React.ReactNode | InfoItem[];

const Quickinfo = () => {
  const { content, quickInfoClipboard } = useQuickInfoState();
  const hasOptions = !!quickInfoClipboard;

  return (
    <>
      <SideBarTitle
        title="Quick-Info"
        hasOptions={hasOptions}
        options={
          hasOptions && (
            <ABKButtonCopyToClipboard
              text={quickInfoClipboard}
              customButton={(isCopied) => (
                <ABKNavigationButtonItem
                  text="Inhalt in die Zwischenablage kopieren"
                  icon={isCopied ? "Log.Check" : "Grid.Copy"}
                />
              )}
            />
          )
        }
      />
      <div className="quick-info">
        {content instanceof Array
          ? content.map((item, index) => {
              const key = `quick-info-item-${index}`;
              if (React.isValidElement(item))
                return <React.Fragment key={key}>{item}</React.Fragment>;

              const infoItem = item as InfoItemObject;
              return (
                <QuickInfoItem
                  key={key}
                  title={infoItem.title}
                  content={infoItem.content}
                />
              );
            })
          : content}
      </div>
    </>
  );
};

export default Quickinfo;
