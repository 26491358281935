import { create } from "zustand";

interface AppBarState {
    appBarTitle: string;
    setAppBarTitle: (title: string) => void;
    openPopup: string | null;
    setOpenPopup: (popup: string | null) => void;
}

const useAppBarState = create<AppBarState>((set) => ({
    appBarTitle: "ABK-Dateien",
    openPopup: null,
    setAppBarTitle: (title: string) => {
        document.title = title;
        set(() => ({ appBarTitle: title }))
    },
    setOpenPopup: (popup: string | null) => {
        set(() => ({ openPopup: popup }))
    },
}))

export default useAppBarState;