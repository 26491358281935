import React from "react";
import useMainPageTemplateState from "../../../stores/useMainPageTemplateState";

interface InfoBoxProps {
  headerRef: React.RefObject<HTMLDivElement>;
  includeTitle: boolean;
  includeDescription: boolean;
}

const InfoBox = ({
  headerRef,
  includeTitle,
  includeDescription,
}: InfoBoxProps) => {
  const { mainPageTemplateTitle, mainPageTemplateDescription } =
    useMainPageTemplateState();

  return (
    <div className="template-info-box" id="header" ref={headerRef}>
      {includeTitle && (
        <h4
          style={{
            margin: "5px 0",
            padding: 0,
          }}
        >
          {mainPageTemplateTitle}
        </h4>
      )}
      {includeDescription && (
        <p style={{ lineHeight: "1.2" }}>{mainPageTemplateDescription}</p>
      )}
    </div>
  );
};

export default InfoBox;
