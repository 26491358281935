import { Button } from "@progress/kendo-react-buttons";
import { Popup } from "@progress/kendo-react-popup";
import React from "react";
import { AbkIconsMap } from "../../../../../interfaces/abk9SvgIcons";
import ABKSearchBar from "./ABKSearchBar";
import { InputHandle } from "@progress/kendo-react-inputs";

type Props = {
  filterValue: string;
  onFilterChange: (newValue: string) => void;
  inputRef: React.RefObject<InputHandle>;
  isMobileGrid: boolean;
};
export default function ABKMobileSearchBar({
  filterValue,
  onFilterChange,
  inputRef,
}: Props) {
  const [shouldDisplaySearchBar, setShouldDisplaySearchBar] = React.useState(
    filterValue !== ""
  );
  const resetFilter = () => onFilterChange("");
  const anchor = React.useRef<Button>(null);

  return (
    <div className="k-d-flex k-pos-relative">
      <Button
        ref={anchor}
        svgIcon={AbkIconsMap.get("General.Search")}
        title="Suche"
        fillMode="link"
        className={`grid-toolbar-button ${shouldDisplaySearchBar ? "k-focus" : ""}`}
        onClick={() => {
          const newShouldDisplaySearchBar = !shouldDisplaySearchBar;
          setShouldDisplaySearchBar(newShouldDisplaySearchBar);

          const willClose = newShouldDisplaySearchBar === false;
          if (willClose) resetFilter();
        }}
      />
      {shouldDisplaySearchBar && (
        <Popup
          popupClass="pop-up-grid-search"
          /*
            z-index ist weniger als 99, um zu vermeiden, dass die Pop-up für
            die Suchleiste den Info-Hub bedeckt.
            Diese Zeile ist abhängig von der folgenden CSS-Regel, irgendwo anders im Code:
            .mobile-modal-container: { z-index: 99 }
          */
          style={{ zIndex: 98 }}
          show={true}
          anchor={anchor.current && anchor.current.element}
          anchorAlign={{ horizontal: "center", vertical: "top" }}
          popupAlign={{ horizontal: "center", vertical: "bottom" }}
          onOpen={() => inputRef.current?.focus()}
        >
          <ABKSearchBar
            filterValue={filterValue}
            onFilterChange={onFilterChange}
            inputRef={inputRef}
            shouldDisplayLabel={false}
          >
            <Button
              svgIcon={AbkIconsMap.get("Navigation.Close")}
              onClick={() => {
                setShouldDisplaySearchBar(false);
                resetFilter();
              }}
              fillMode="link"
            />
          </ABKSearchBar>
        </Popup>
      )}
    </div>
  );
}
