import { Verwalten } from "./Verwalten";
import useIsValidDB from "../../../../core/utility/useIsValidDB";
import { RequestType } from "../../hooks/useGetCont";

type Props = { containerType: RequestType };
export default function VerwaltenWrapper({ containerType }: Props) {
  const { isValidDB, db } = useIsValidDB();
  if (!isValidDB) return <></>;

  const validDB = db as string;
  return (
    <Verwalten
      key={`verwalten-${validDB}-${containerType}`}
      containerType={containerType}
      db={validDB}
    />
  );
}
