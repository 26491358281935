import { HELP_START, HELP_SYSINFO } from "../../../../constants/urls";
import { SvgIcon } from "@progress/kendo-react-common";
import * as Icons from "../../../../../abk-shared/interfaces/abk9SvgIcons";
import ABKPopUpButton from "../../../molecules/ABKPopUpButton";
import { TitlesHelp } from "../../../../constants/titles";
import { useNavigate } from "react-router-dom";
import ABKNavigationButtonItem from "../../../../../abk-shared/components/atoms/ABKNavigationButtonItem";

const HelpButton = ({ isDesktop }: { isDesktop: boolean }) => {
  const navigate = useNavigate();

  const items = [
    {
      text: "Hilfe-Center",
      onClick: () => navigate(HELP_START),
      icon: "Menu.Helpcenter",
    },
    {
      text: TitlesHelp.SystemInfo,
      onClick: () => navigate(HELP_SYSINFO),
      icon: "Menu.Help.SysInfo",
    },
  ];

  return (
    <ABKPopUpButton
      toolTipText="Hilfe & Info"
      buttonIsAnchor={true}
      insideButtonElement={
        isDesktop ? (
          <SvgIcon icon={Icons.Menu_Help} />
        ) : (
          <SvgIcon icon={Icons.Menu_Help} height={24} width={24} />
        )
      }
      insidePopupElement={
        <div>
          {items.map((item, index) => (
            <ABKNavigationButtonItem key={index} {...item} />
          ))}
        </div>
      }
    />
  );
};

export default HelpButton;
