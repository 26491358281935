import { Checkbox, Input } from "@progress/kendo-react-inputs";
import React from "react";
import ABKFormFieldInputWrapper from "../ABKFormFieldInputWrapper";
import ABKInfoPopup from "../ABKInfoPopup";
import ABKLabel from "../ABKLabel";
import ABKFormFieldCheckboxWrapper from "../ABKFormFieldCheckboxWrapper";
import ABKCheckboxLabel from "../ABKCheckboxLabel";
import "./style.css";

interface ABKFormCheckboxProps {
  editorId: string;
  tooltip?: string;
  label: string;
  value: boolean | undefined | React.ReactElement;
  setValue?: (value: boolean) => void;
  children?: React.ReactNode;
  classNameLabel?: string;
  useJSXForValue?: boolean;
}

const ABKFormCheckbox: React.FC<ABKFormCheckboxProps> = (props) => {
  const readOnly = !props.setValue;

  return (
    <ABKFormFieldCheckboxWrapper>
      <div className="checkbox-label-alignment">
      <ABKCheckboxLabel
              editorId={props.editorId}>
              {props.label}
              {props.children}
              {props.tooltip &&
                <ABKInfoPopup popupContent={props.tooltip} />
              }
            </ABKCheckboxLabel>
      {readOnly && props.useJSXForValue ? (
        <span className={props.classNameLabel}>{props.value}</span>
      ) : (
        <div>
          <Checkbox
            className={props.classNameLabel}
            id={props.editorId}
            name={props.editorId}
            // title={props.value as string | undefined}
            // label={props.label}
            value={props.value as boolean | undefined}
            // labelPlacement={"before"}
            {...(readOnly
              ? { readOnly: true }
              : {
                onChange: (e) =>
                  props.setValue && props.setValue(e.target.value as boolean),
              })}
          >
            {/* <ABKCheckboxLabel
              editorId={props.editorId}>
              {props.label}
              {props.children}
              {props.tooltip &&
                <ABKInfoPopup popupContent={props.tooltip} />
              }
            </ABKCheckboxLabel> */}
          </Checkbox>
        </div>
      )}
      </div>
      
    </ABKFormFieldCheckboxWrapper>
  );
};

export default ABKFormCheckbox;
