import germanMessages from "../de-DE.json";

const MessageNoRecords = () => (
  <div className="k-grid-norecords">
    <div
      className="k-grid-norecords-template"
      data-keyboardnavid="_no-records_cell"
    >
      {germanMessages.grid.noRecords}
    </div>
  </div>
);

export default MessageNoRecords;
