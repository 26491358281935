import { Button } from "@progress/kendo-react-buttons";
import { useNavigate } from "react-router-dom";
import isDBNotFoundError from "../../../../abk-shared/utility/isDBNotFoundError";
import {
  DATABASE_PLACEHOLDER,
  DEFAULT_DB,
  PORTAL_URL,
  STARTSEITE,
} from "../../../constants/urls";
import useCurrentDBStore from "../../../stores/useCurrentDBStore";

type Props = { error: unknown };
export default function ABKError404NotificationButton({ error }: Props) {
  const { currentDB } = useCurrentDBStore();
  const navigate = useNavigate();

  const isDBError = isDBNotFoundError(error);

  return (
    <Button
      themeColor="primary"
      className="k-w-min"
      style={{ textWrap: "wrap" }}
      onClick={() => {
        if (isDBError) {
          window.location.href = PORTAL_URL;
          return;
        }

        const db = currentDB?.DBNAME ?? DEFAULT_DB;
        navigate(STARTSEITE.replace(DATABASE_PLACEHOLDER, db), {
          // Wir wollen, dass der User nicht mehr auf die fehlerhafte Seite zugreifen kann
          replace: true,
        });
      }}
    >
      {isDBError ? "Zum Portal" : "Zur Startseite"}
    </Button>
  );
}
