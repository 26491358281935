import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { QUERY_KEY_PARAMETERLISTE } from "./useGetSingleParameterliste";
import { Versioned } from "../../../core/interfaces/Versioned";
import { ParameterListe } from "../interfaces/parameterlisten";
import formatParameterlisteWithEtag from "./formatParameterlisteWithEtag";

type MutationSaveParameterListe = {
  db: string;
  contId: string;
  parameterliste: Versioned<ParameterListe>;
};
export default function useSaveParameterListe() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      db,
      contId,
      parameterliste,
    }: MutationSaveParameterListe) => {
      return axios.put(
        `/api/db/${db}/cont/${contId}/pl/${parameterliste.value.DATAID}`,
        parameterliste.value,
        {
          headers: {
            "If-Match": parameterliste.etag,
            "Content-Type": "application/json",
          },
        }
      );
    },
    onSuccess: (response, variables) => {
      queryClient.setQueryData(
        [
          QUERY_KEY_PARAMETERLISTE,
          variables.db,
          variables.contId,
          variables.parameterliste.value.DATAID,
        ],
        formatParameterlisteWithEtag(response)
      );
    },
  });
}
