import "./style.css";
import { Notification } from "@progress/kendo-react-notification";
import { InfoItem } from "./QuickInfo";
import { ABKError } from "../../../interfaces/Error";
import React from "react";
import { FunctionOnNotificationClick } from "./SideBar";

export interface INotification {
  message: string | React.ReactElement;
  type: "success" | "none" | "error" | "warning" | "info" | undefined;
  url?: string;
  quickInfo?: InfoItem[];
  closable: boolean;
  notificationState?: "loading" | "success" | "error";
  ABKError?: ABKError;
  JSError?: any;
  renderNotification?: (
    onNotificationClick: FunctionOnNotificationClick | undefined
  ) => React.ReactElement;
  notificationButton?: React.ReactElement;
  quickInfoData?: Record<string, any>;
}

const NotificationBox = ({
  notifications,
  onCloseNotification,
  onNotificationClick,
}: {
  notifications: INotification[];
  onCloseNotification: (notification: INotification) => void;
  onNotificationClick?: FunctionOnNotificationClick;
}) => {
  return (
    <div className="notification-box">
      {notifications.map((notification, index) => {
        if (notification.renderNotification)
          return (
            <React.Fragment key={index}>
              {notification.renderNotification(onNotificationClick)}
            </React.Fragment>
          );

        return (
          <Notification
            key={index}
            type={{ style: notification.type, icon: true }}
            closable={notification.closable}
            onClose={() => onCloseNotification(notification)}
            className="notification"
          >
            <div className="notification-content">
              <span
                onClick={() =>
                  onNotificationClick && onNotificationClick(notification)
                }
                className="notification-message"
                title={
                  typeof notification.message === "string"
                    ? notification.message
                    : ""
                }
              >
                {notification.message}
              </span>
              {notification.notificationButton}
            </div>
          </Notification>
        );
      })}
    </div>
  );
};

export default NotificationBox;
