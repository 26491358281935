import { Outlet, useParams } from "react-router-dom";
import { useEffect } from "react";
import useDrawerStore from "../../core/stores/useDrawerStore";
import WrapperTemplate from "../../core/components/templates/WrapperTemplate";
import useGetContInfo from "../Startseite/hooks/useGetContInfo";
import ExtendedDrawerItemProps from "../../abk-shared/interfaces/ExtendedDrawerItemProps";
import FileTypeMap from "./Templates/FileTypeMap";
import generateDrawerItem from "./utils/generateDrawerItems";

const DateiLayout = () => {
  const params = useParams();
  const db = params.db;
  const contId = params.contId;
  const { setCurrentDrawerItems } = useDrawerStore();
  const { data } = useGetContInfo(db, contId);

  useEffect(() => {
    const contType = data?.value?.CONTTYPE; // "cpl", "cgs" oder "cek"

    const fileInfo = FileTypeMap.get(contType ?? "");

    const drawerItems: ExtendedDrawerItemProps[] = (fileInfo?.drawerItems.map(
      (item) => generateDrawerItem(item, db!, contId!)
    ) ?? []) as ExtendedDrawerItemProps[];

    setCurrentDrawerItems(drawerItems);
  }, [db, contId, setCurrentDrawerItems, data?.value?.CONTTYPE]);

  return (
    <WrapperTemplate
      includeTitle={true}
      includeDescription={true}
      appBarIncludesDropDown={true}
    >
      <Outlet />
    </WrapperTemplate>
  );
};

export default DateiLayout;
