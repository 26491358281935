import React, { ReactNode } from "react";
import "./style.css";
import ABKOptionsIconButton from "../../atoms/ABKOptionsIconButton";

interface SideBarTitleProps {
  title: string;
  hasOptions: boolean;
  options?: ReactNode;
}

const SideBarTitle = ({ title, hasOptions, options }: SideBarTitleProps) => {
  return (
    <div className="side-bar-title">
      <div className="title">{title}</div>
      {hasOptions && (
        <div className="menu-button">
          <ABKOptionsIconButton
            title="Optionen"
            fillMode={"link"}
            insidePopupElement={options ? options : <>No Options</>}
          />
        </div>
      )}
    </div>
  );
};

export default SideBarTitle;
