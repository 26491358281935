import { Loader } from "@progress/kendo-react-indicators";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Eintrag } from "../../interfaces/parameterlisten";
import PLEintraegeGrid from "./PLEintraegeGrid";
import useInitiatePageWithContainerData from "../useInitiatePageWithContainerData";
import EintragBearbeitenDialog from "./EintragBearbeitenDialog";
import { useEintragBearbeitenState } from "./EintragBearbeitenDialog/useEintragBearbeitenState";

type Props = { db: string; contId: string };
const EintraegePage = ({ db, contId }: Props) => {
  const { openDialog, setOpenDialog } = useEintragBearbeitenState();

  useInitiatePageWithContainerData(db, contId);

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["eintraege", contId],
    queryFn: async () => {
      const responsePL = await axios.get(`/api/db/${db}/cont/${contId}/pl`);
      const hasItems =
        Array.isArray(responsePL.data.Items) &&
        responsePL.data.Items.length > 0;
      if (!hasItems) return [];

      const url = `/api/db/${db}/cont/${contId}/pl/${responsePL.data.Items[0].DATAID}/items`;
      const response = await axios.get(url);
      const eintraege = response.data.Items as Eintrag[];
      if (eintraege) return eintraege;
      else return [];
    },
  });

  const defaultActions = [
    {
      icon: "Edit.Edit",
      text: "Bearbeiten",
      onClick: () => setOpenDialog(true),
    },
    {
      icon: "Grid.InsertEnd",
      text: "Unterhalb Einfügen",
      onClick: () => alert("Unterhalb Einfügen"),
    },
    {
      icon: "Grid.InsertBefore",
      text: "Oberhalb Einfügen",
      onClick: () => alert("Oberhalb Einfügen"),
    },
    {
      icon: "Grid.Delete",
      text: "Löschen",
      onClick: () => alert("Löschen"),
    },
    {
      icon: "Grid.MoveDown",
      text: "Nach unten verschieben",
      onClick: () => alert("Nach unten verschieben"),
    },
    {
      icon: "Grid.MoveUp",
      text: "Nach oben verschieben",
      onClick: () => alert("Nach oben verschieben"),
    },
  ];

  if (isLoading || !data) return <Loader />;

  if (isError) return <div>{error.message}</div>;

  return (
    <>
      <PLEintraegeGrid eintraege={data} defaultActions={defaultActions} />
      {openDialog && (
        <EintragBearbeitenDialog onClose={() => setOpenDialog(false)} />
      )}
    </>
  );
};

export default EintraegePage;
