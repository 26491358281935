import { ParameterListe } from "../../../../interfaces/parameterlisten";
import ABKControlledExpansionPanel from "../../../../../../abk-shared/components/atoms/ABKControlledExpansionPanel";
import AbkOenormEditor from "../../../../../../core/components/organisms/AbkOenormEditor";
import editorTools from "../../../../../../core/components/organisms/AbkOenormEditor/editorTools";

interface BeschreibungFormProps {
  pageState: ParameterListe;
  setPageState: (parameterliste: ParameterListe) => void;
  isOpen: boolean;
  updateSections: () => void;
  readOnly: boolean;
}

const BeschreibungForm = ({
  pageState,
  setPageState,
  isOpen,
  updateSections,
}: BeschreibungFormProps) => {
  return (
    <ABKControlledExpansionPanel
      title="Beschreibung"
      key="beschreibungform"
      expanded={isOpen}
      onAction={updateSections}
    >
      <AbkOenormEditor
        tools={[...editorTools.oenormTools, ...editorTools.tableTools]}
        value={pageState.Kenndaten.BESCH}
        setValue={(value) => {
          setPageState({
            ...pageState,
            Kenndaten: {
              ...pageState.Kenndaten,
              BESCH: value,
            },
          });
        }}
      ></AbkOenormEditor>
    </ABKControlledExpansionPanel>
  );
};

export default BeschreibungForm;
