import { useQuickInfoState } from "../../../../../abk-shared/stores/useQuickInfoState";
import { QuickinfoContent } from "../../../../../abk-shared/components/organisms/ABKSideBar/QuickInfo";

export default function useQuickInfoOnGridSelection(
  generateQuickInfoOneItem: (item: any) => Promise<QuickinfoContent>
) {
  const { setQuickInfo, resetQuickInfo } = useQuickInfoState();

  const onSelectionChange = async (itemSelected: any[]) => {
    const numberOfJobsSelected = itemSelected.length;
    switch (numberOfJobsSelected) {
      case 0:
        resetQuickInfo();
        break;
      case 1:
        const item = itemSelected[0];
        resetQuickInfo();
        if (item !== undefined) {
          const quickInfo = await generateQuickInfoOneItem(item);
          setQuickInfo(quickInfo);
        }
        break;
      default:
        resetQuickInfo();
        setQuickInfo(
          [
            {
              content: numberOfJobsSelected.toString(),
              title: "Anzahl Dateien",
            },
          ],
          itemSelected
        );
    }
  };

  return onSelectionChange;
}
