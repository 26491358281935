import { create } from 'zustand'
import * as Icons from '../../abk-shared/interfaces/abk9SvgIcons'
import ExtendedDrawerItemProps from '../interfaces/ExtendedDrawerItemProps'

interface DrawerState {
    drawerOpen: boolean
    toggleDrawer: () => void
    currentDrawerItems: ExtendedDrawerItemProps[]
    setCurrentDrawerItems: (items: ExtendedDrawerItemProps[]) => void
    drawerWidth: number
    setDrawerWidth: (width: number) => void
    increaseDrawerWidth: () => void
    decreaseDrawerWidth: () => void
    // navigateOnSelect: (e: DrawerSelectEvent) => void
}

const useDrawerStore = create<DrawerState>((set) => {
    const localStorageValue = localStorage.getItem('drawerOpen')
    const defaultDrawerOpen = localStorageValue ? JSON.parse(localStorageValue) : true

    const localStorageWidth = localStorage.getItem('drawerWidth')
    const defaultDrawerWidth = localStorageWidth ? JSON.parse(localStorageWidth) : 250

    return {
        drawerOpen: defaultDrawerOpen,
        toggleDrawer: () => {
            set((state) => {
                const newDrawerOpen = !state.drawerOpen
                localStorage.setItem('drawerOpen', JSON.stringify(newDrawerOpen))
                return { drawerOpen: newDrawerOpen }
            })
        },
        currentDrawerItems: [
            {
                text: "ABK-Dateien",
                svgIcon: Icons.Menu_Help_AdminInfo,
                selected: true,
                route: "/startseite/",
                title: "Startseite"
            },
            { text: "Projekte", svgIcon: Icons.Menu_Start_Projects, route: "/startseite/projekte", title: "Projekte" },
            { text: "Analysen", svgIcon: Icons.General_Analysis, route: "/startseite/analysen", title: "Analysen" },
            { separator: true },
        ],
        setCurrentDrawerItems: (items: ExtendedDrawerItemProps[]) => set(() => ({ currentDrawerItems: items })),
        drawerWidth: defaultDrawerWidth,
        setDrawerWidth: (width: number) => {
            set(() => {
                localStorage.setItem('drawerWidth', JSON.stringify(width))
                return { drawerWidth: width }
            })
        },
        increaseDrawerWidth: () => {
            set((state) => {
                const newWidth = state.drawerWidth + 10
                localStorage.setItem('drawerWidth', JSON.stringify(newWidth))
                return { drawerWidth: newWidth }
            })
        },
        decreaseDrawerWidth: () => {
            set((state) => {
                const newWidth = state.drawerWidth - 10
                localStorage.setItem('drawerWidth', JSON.stringify(newWidth))
                return { drawerWidth: newWidth }
            })
        },
    }
})

export default useDrawerStore;