import React from "react";
import ABKMobileModal from "../../atoms/ABKMobileModal";
import Sidebar, {
  FunctionOnNotificationClick,
} from "../../organisms/ABKSideBar/SideBar";
import { INotification } from "../../organisms/ABKSideBar/NotificationBox";

interface ABKMobileSidebarProps {
  open: boolean;
  onClose: () => void;
  notifications: INotification[];
  onCloseNotification: (notification: INotification) => void;
  infoHubOptions?: React.ReactNode;
  onNotificationClick?: FunctionOnNotificationClick;
}

const ABKMobileSidebar: React.FC<ABKMobileSidebarProps> = ({
  open,
  onClose,
  notifications,
  onCloseNotification,
  infoHubOptions,
  onNotificationClick,
}: ABKMobileSidebarProps) => {
  return (
    <ABKMobileModal open={open} onClose={onClose} position="right">
      <Sidebar
        notifications={notifications}
        onCloseNotification={onCloseNotification}
        infoHubOptions={infoHubOptions}
        onNotificationClick={onNotificationClick}
        isMobile={true}
      />
    </ABKMobileModal>
  );
};

export default ABKMobileSidebar;
