import React from "react";
import FormSubtitle from "./FormSubtitle";

export interface FormSectionProps {
  subTitle?: string;
  formElements: React.ReactNode[];
}

const FormSection = ({ subTitle, formElements }: FormSectionProps) => {
  return (
    <>
      {subTitle && <FormSubtitle subtitle={subTitle} />}

      <>
        {formElements.map((element, index) => (
          <React.Fragment key={index}>{element}</React.Fragment>
        ))}
      </>
    </>
  );
};

export default FormSection;
