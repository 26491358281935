import { create } from "zustand";
import { Eintrag } from "../../../interfaces/parameterlisten";

interface EintragBearbeitenState {
  currentEintrag: Eintrag | null;
  setCurrentEintrag: (eintrag: Eintrag) => void;
  pageState: Eintrag | null;
  setPageState: (eintrag: Eintrag) => void;
  openDialog: boolean;
  setOpenDialog: (open: boolean) => void;
}

export const useEintragBearbeitenState = create<EintragBearbeitenState>(
  (set) => ({
    currentEintrag: null,
    setCurrentEintrag: (eintrag: Eintrag) => set({ currentEintrag: eintrag }),
    pageState: null,
    setPageState: (eintrag: Eintrag) => set({ pageState: eintrag }),
    openDialog: false,
    setOpenDialog: (open: boolean) => set({ openDialog: open }),
  })
);
