import React from "react";
import { useQuickInfoState } from "../../../stores/useQuickInfoState";
import { INotification } from "./NotificationBox";
import _ from "lodash";

function useResetQuickInfoOnNotificationDelete(notifications: INotification[]) {
  const { variableDisplayed, resetQuickInfo } = useQuickInfoState();
  const previousNotificationsRef = React.useRef(notifications);

  React.useEffect(() => {
    const previousNotifications = previousNotificationsRef.current;

    if (notifications.length < previousNotifications.length) {
      const removedNotifications = previousNotifications.filter(
        (previousNotification) =>
          isNotificationRemoved(previousNotification, notifications)
      );

      if (removedNotifications.includes(variableDisplayed)) resetQuickInfo();
    }

    previousNotificationsRef.current = notifications;
  }, [notifications, variableDisplayed]);
}

const isNotificationRemoved = (
  previousNotification: INotification,
  notifications: INotification[]
) =>
  !notifications.some((notification) =>
    _.isEqualWith(
      notification,
      previousNotification,
      /*
        Vergleiche nicht die Funktionen, weil sie sich ändern können,
        und wir werden die gelöschten Notifications nicht finden.
      */
      (value, previousValue) => {
        if (
          typeof value === "function" ||
          typeof previousValue === "function"
        ) {
          return true;
        }
        return undefined;
      }
    )
  );

export default useResetQuickInfoOnNotificationDelete;
