import { useEffect, useState } from "react";
import { Input } from "@progress/kendo-react-inputs";
import ABKFormFieldInputWrapper from "../../../../abk-shared/components/atoms/ABKFormFieldInputWrapper";
import ABKLabel from "../../../../abk-shared/components/atoms/ABKLabel";

interface DateitypLabels {
  [key: string]: string;
}

const dateitypLabels: DateitypLabels = {
  cek: "Elementkatalog",
  cpl: "Parameterliste",
  cgs: "Gliederungssystem",
  clb: "Leistungsbeschreibung",
  cpd: "LB-Preise/Kennwerte (entspricht Inhalt onpr)",
  cart: "Artikeldatei",
  cab: "Adressbuch (auch für Firma/Benutzer/Abteilungen verwendet)",
  clv: "LV (entspricht Inhalt onlv), für jeweils 1 LV (Ausschreibungs-LV, Angebots-LV, Zusatzangebots-LV, etc.)",
  cdb: "Main-Datenbankcontainer",
};

const InputMitBeschriftung = ({ value }: { value: string }) => {
  const [beschriftung, setBeschriftung] = useState("");

  const setzeBeschriftung = (wert: string) => {
    if (dateitypLabels.hasOwnProperty(wert)) {
      setBeschriftung(dateitypLabels[wert]);
    } else {
      setBeschriftung("Beschreibung nicht verfügbar");
    }
  };

  useEffect(() => {
    setzeBeschriftung(value);
  }, [value]);

  return (
    <ABKFormFieldInputWrapper>
      <ABKLabel editorId="dateityp">Dateityp</ABKLabel>
      <Input
        type="text"
        id="dateityp"
        name="dateityp"
        value={beschriftung}
        readOnly={true}
      />
    </ABKFormFieldInputWrapper>
  );
};

export default InputMitBeschriftung;
