import { Outlet } from "react-router-dom";
import * as Icons from "../../abk-shared/interfaces/abk9SvgIcons";
import {
  HELP_ABOUT,
  HELP_ADMIN_HELP_SUFFIX,
  HELP_SYSINFO,
} from "../../core/constants/urls";
import useDrawerStore from "../../core/stores/useDrawerStore";
import { useEffect } from "react";
import useAppBarState from "../../core/stores/useAppBarState";
import { changeFavIcon } from "../../core/utility/favIcon";
import WrapperTemplate from "../../core/components/templates/WrapperTemplate";
import { TitlesHelp } from "../../core/constants/titles";

const HilfeLayout = () => {
  const { setAppBarTitle } = useAppBarState();
  const { setCurrentDrawerItems } = useDrawerStore();

  useEffect(() => {
    const drawerItems = [
      {
        text: TitlesHelp.AppInfo,
        svgIcon: Icons.Menu_Help_AppInfo,
        route: HELP_ABOUT,
        title: TitlesHelp.AppInfo,
      },
      {
        text: TitlesHelp.SystemInfo,
        svgIcon: Icons.Menu_Help_SysInfo,
        route: HELP_SYSINFO,
        title: TitlesHelp.SystemInfo,
      },
      {
        text: TitlesHelp.AdminHelp,
        svgIcon: Icons.Menu_Admin_ServerLog,
        route: HELP_ADMIN_HELP_SUFFIX,
        title: TitlesHelp.AdminHelp,
      },
    ];
    setAppBarTitle("Hilfe-Center");
    setCurrentDrawerItems(drawerItems);
    changeFavIcon("/assets/favicons/Menu.Helpcenter.Tabicon.svg");
  }, [setAppBarTitle, setCurrentDrawerItems]);

  return (
    <>
      <WrapperTemplate includeTitle includeDescription>
        <Outlet />
      </WrapperTemplate>
    </>
  );
};

export default HilfeLayout;
