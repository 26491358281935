import { Button } from "@progress/kendo-react-buttons";
import { AbkIconsMap } from "../../../interfaces/abk9SvgIcons";
import ABKPopUpButton from "../../molecules/ABKPopUpButton";
import { SVGIcon } from "@progress/kendo-svg-icons";

interface ABKOptionsIconProps {
  title: string;
  fillMode?: "link" | "flat" | "solid" | "outline" | "clear" | null | undefined;
  size?: "small" | "medium" | "large" | null | undefined;
  isVertical?: boolean;
  insidePopupElement: React.ReactNode;
  style?: React.CSSProperties;
  buttonClassName?: string;
  svgIcon?: SVGIcon | undefined;
}

const ABKOptionsIconButton = ({
  title = "Optionen",
  fillMode = "link",
  size = "small",
  isVertical = false,
  insidePopupElement,
  style,
  buttonClassName,
  svgIcon,
}: ABKOptionsIconProps) => {
  let icon = isVertical
    ? AbkIconsMap.get("Navigation.More")
    : AbkIconsMap.get("Navigation.More2");
  if (svgIcon) icon = svgIcon;

  return (
    <>
      <ABKPopUpButton
        buttonIsAnchor={true}
        buttonStyles={{}}
        hasBorderRight={false}
        insideButtonElement={
          <Button
            svgIcon={icon}
            title={title}
            fillMode={fillMode}
            size={size}
            style={style}
            className={buttonClassName}
          />
        }
        insidePopupElement={insidePopupElement}
        popUpProps={{}}
        toolTipText={title}
        hasAppBarButtonStyles={false}
      />
    </>
  );
};

export default ABKOptionsIconButton;
