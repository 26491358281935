import { GridContextMenuEvent } from "@progress/kendo-react-grid";
import { GenericObject } from "../../../../interfaces/GenericObject";
import { DATA_KEY } from "../constants";

export function isContextMenuOnGroupedColumnHeader(
  event: GridContextMenuEvent
) {
  /*
    "event.dataItem" ist wie folgendes, wenn es ein Grouped Column Header ist:
    {dataItem: {title: 'Besitzer', dir: 'asc', index: 0}}
  */
  const dataItem = event.dataItem;
  const hasKeys =
    dataItem.hasOwnProperty("dataItem") &&
    dataItem.dataItem.hasOwnProperty("title") &&
    dataItem.dataItem.hasOwnProperty("dir") &&
    dataItem.dataItem.hasOwnProperty("index");

  // Und "event.field" ist "undefined"
  const hasNoField = event.field === undefined;
  return hasKeys && hasNoField;
}

export function ungroupDataItems(dataItems: GenericObject[]) {
  const flattenedDataItems: GenericObject[] = [];
  for (const item of dataItems) {
    if (!isGroupedItem(item)) {
      flattenedDataItems.push(item);
      continue;
    }

    const flattenedChildren = ungroupDataItems(item.items);
    flattenedDataItems.push(...flattenedChildren);
  }

  return flattenedDataItems;
}

export const isGroupedItem = (item: GenericObject) =>
  item.hasOwnProperty("aggregates") && Array.isArray(item.items);

export function getValueOfFirstGroupedItem(item: GenericObject, field: string) {
  const firstItem = getFirstItemOfGroup(item);
  const value = firstItem[field][DATA_KEY.DISPLAYED];
  return value;
}

function getFirstItemOfGroup(item: GenericObject): any {
  if (!isGroupedItem(item)) return item;

  for (const itemInGroup of item.items) return getFirstItemOfGroup(itemInGroup);

  return item;
}
