import { Loader } from "@progress/kendo-react-indicators";
import { DB } from "../../../../abk-shared/interfaces/db";
import useGetJobsOfContainer from "../../../../core/queries/jobs/useGetJobsOfContainer";
import JobGrid from "../../../../core/components/organisms/JobGrid";

type Props = { currentDB: DB };
const DbProtocolPage = ({ currentDB }: Props) => {
  const { data, isLoading } = useGetJobsOfContainer(currentDB.DBNAME, "_main");

  if (isLoading || !data) return <Loader />;

  return <JobGrid jobs={data} />;
};

export default DbProtocolPage;
