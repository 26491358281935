import { Checkbox } from "@progress/kendo-react-inputs";
import { forEachColumns, isColumnShown } from "./columnUtils";
import { GridColumnPropsCustom } from "../../interfaces/GridColumns";

export type FunctionColumnToggleVisibility = (
  columnField: string | undefined
) => void;

export function toggleShowForColumnHavingField(
  columns: GridColumnPropsCustom[],
  columnField: string | undefined
) {
  forEachColumns(columns, (column, hasChildren) => {
    if (column.field !== columnField) return;

    const show = isColumnShown(column) ? false : true;
    column.show = show;
    if (!hasChildren) return;

    const children = column.children as GridColumnPropsCustom[];
    forEachColumns(children, (column) => {
      column.show = show;
    });
  });
}

const MARGIN_LEFT_REM = 1.25;

export function createColumnCheckboxes(
  columns: GridColumnPropsCustom[],
  onClick: FunctionColumnToggleVisibility
) {
  const columnCheckboxes: React.ReactElement[] = [];

  forEachColumns(columns, (column, _, depth) => {
    const marginLeftRem = MARGIN_LEFT_REM * depth;

    columnCheckboxes.push(
      <div key={column.field} style={{ marginLeft: `${marginLeftRem}rem` }}>
        <Checkbox
          checked={column.show ?? true}
          label={column.title}
          onClick={() => onClick(column.field)}
        />
      </div>
    );
  });

  return columnCheckboxes;
}
