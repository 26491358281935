import { Button } from "@progress/kendo-react-buttons";
import React from "react";
import ABKImageUploadButton from "../../../../../../../abk-shared/components/atoms/ABKImageUploadButton";
import ABKInfoPopup from "../../../../../../../abk-shared/components/atoms/ABKInfoPopup";
import ABKLabel from "../../../../../../../abk-shared/components/atoms/ABKLabel";
import { AbkIconsMap } from "../../../../../../../abk-shared/interfaces/abk9SvgIcons";
import {
  Kenndaten,
  LogoFormat,
  ParameterListe,
} from "../../../../../interfaces/parameterlisten";
import {
  compressFileToLessThan64kB,
  generateBase64ForImage,
  getLogoFormat,
  toBase64,
} from "./imageProcessing";
import LogoDisplayer from "./LogoDisplayer";
import downloadFile from "../../../../../../../core/utility/downloadFile";

type Props = {
  pageState: ParameterListe;
  setPageState: (parameterliste: ParameterListe) => void;
  kenndaten: Kenndaten;
  disabled: boolean;
};

const messageAvailableFormats = "Erlaubte Dateiformate: JPG, PNG, GIF";
export default function UploadLogo({
  pageState,
  setPageState,
  kenndaten,
  disabled,
}: Props) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const handleFileUpload = React.useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      setErrorMessage("");
      setIsLoading(true);

      const inputFile = event.target.files?.[0];
      const userCanceledFileSelection = !inputFile;
      if (userCanceledFileSelection) {
        setIsLoading(false);
        return;
      }

      // Setze das Logo zurück, falls es ein Logo vorher eingegeben war
      setPageState({
        ...pageState,
        Kenndaten: {
          ...kenndaten,
          LOGO: "",
          LOGOFORMAT: LogoFormat.EMPTY,
        },
      });

      const type = inputFile.type;
      const logoFormat = getLogoFormat(type);
      if (logoFormat === LogoFormat.EMPTY) {
        setErrorMessage(
          `Dateiformat nicht erlaubt. ${messageAvailableFormats}`
        );
        setIsLoading(false);
        return;
      }

      const { compressedFile, isCompressed } =
        await compressFileToLessThan64kB(inputFile);
      const fileBase64 = await toBase64(
        isCompressed ? (compressedFile as File) : inputFile
      );
      // Das Back-end kann nicht das Präfix speichern, weil es die `;` entfernen wird
      const fileBase64WithoutPrefix = (fileBase64 as string).split(
        ";base64,"
      )[1];

      setIsLoading(false);
      setPageState({
        ...pageState,
        Kenndaten: {
          ...kenndaten,
          LOGO: fileBase64WithoutPrefix,
          LOGOFORMAT: logoFormat,
        },
      });
    },
    [pageState, kenndaten]
  );

  const tooltip = `${messageAvailableFormats}; Maximale Dateigröße: 64kB. Dateien über 64kB werden automatisch verkleinert.`;

  const logoFormat = pageState.Kenndaten.LOGOFORMAT;
  const { hasImage, imageBase64 } = generateBase64ForImage(
    pageState?.Kenndaten?.LOGO,
    logoFormat
  );

  const downloadImage = React.useCallback(() => {
    const fileName = `Logo.${logoFormat.toLowerCase()}`;
    downloadFile(imageBase64, fileName);
  }, [imageBase64, logoFormat]);

  return (
    <div>
      <ABKLabel editorId="allgemein-status">
        Logo
        <ABKInfoPopup popupContent={tooltip} />
      </ABKLabel>
      <div className="wrapper-logo-buttons">
        <ABKImageUploadButton
          className="k-d-inline-block"
          onChange={handleFileUpload}
          disabled={disabled}
        />
        <Button
          onClick={() => {
            setPageState({
              ...pageState,
              Kenndaten: {
                ...kenndaten,
                LOGO: "",
                LOGOFORMAT: LogoFormat.EMPTY,
              },
            });
          }}
          svgIcon={AbkIconsMap.get("Edit.Delete")}
          title="Löschen"
          disabled={disabled || !hasImage}
        />
        <Button
          onClick={downloadImage}
          svgIcon={AbkIconsMap.get("General.Export")}
          title="Herunterladen"
          disabled={disabled || !hasImage}
        />
      </div>
      <div className="wrapper-logo-img">
        <LogoDisplayer
          isLoading={isLoading}
          hasImage={hasImage}
          imageBase64={imageBase64}
          errorMessage={errorMessage}
        />
      </div>
    </div>
  );
}
