import ABKSaveBalken from "../../../../../abk-shared/components/molecules/ABKSaveBalken";
import { useEintragBearbeitenState } from "./useEintragBearbeitenState";
import { ReactNode, useEffect, useState } from "react";
import { parTypePanelsMap } from "./panels/ParTypePanelsMap";
import ABKWindow from "../../../../../abk-shared/components/molecules/ABKWindow";
import "./windowStyles.css";
import { Eintrag } from "../../../interfaces/parameterlisten";
import _ from "lodash";
import useIsMobileTemplate from "../../../../../abk-shared/utility/useIsMobileTemplate";
import useSaveDataItem from "./useSaveDataItem";

type Props = {
  onClose: () => void;
};

const EintragBearbeitenDialog = ({ onClose }: Props) => {
  const { currentEintrag } = useEintragBearbeitenState();
  const { pageState, setPageState } = useEintragBearbeitenState();
  const isMobile = useIsMobileTemplate();

  const browserWindowHeight = isMobile
    ? window.innerHeight
    : window.innerHeight * 0.7;

  const panels: ReactNode[] =
    parTypePanelsMap.get(currentEintrag?.ITEMTYPE || "") || [];

  useEffect(() => {
    if (currentEintrag) setPageState(currentEintrag);
  }, [currentEintrag]);

  const saveDataItemMutation = useSaveDataItem({
    itemId: currentEintrag?.ITEMID || "",
    dbname: currentEintrag?.DBNAME || "",
    contid: currentEintrag?.CONTID || "",
    dataid: currentEintrag?.DATAID || "",
    eintrag: pageState as Eintrag,
  });

  return (
    <ABKWindow
      initialHeight={browserWindowHeight}
      title={`Eintrag bearbeiten`}
      onClose={onClose}
    >
      {panels.map((panel, index) => (
        <div key={index}>{panel}</div>
      ))}
      <div className="save-balken-container">
        <ABKSaveBalken
          edited={!_.isEqual(pageState, currentEintrag)}
          onSave={() => {
            saveDataItemMutation.mutate();
          }}
          onReset={() => setPageState(currentEintrag as Eintrag)}
          isInline={true}
        />
      </div>
    </ABKWindow>
  );
};

export default EintragBearbeitenDialog;
