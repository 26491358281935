import { create } from "zustand";

interface TabNavigationState {
  tabs: TabNavigationItem[];
  selectedTab: number;
  resetTabs: () => void;
  setTabs: (tabs: TabNavigationItem[]) => void;
  setSelectedTab: (tab: number) => void;
}

export interface TabNavigationItem {
  id: number;
  label: string;
  to: string;
}

const useTabNavigationStore = create<TabNavigationState>((set) => {
  return {
    tabs: [],
    selectedTab: 0,
    resetTabs: () => {
      set({ tabs: [], selectedTab: 0 });
    },
    setTabs: (tabs: TabNavigationItem[]) => {
      let selectedTab = 0;
      tabs.forEach((tab, index) => {
        if (window.location.pathname.endsWith(tab.to)) {
          selectedTab = index;
        }
      });
      set({ tabs: tabs, selectedTab: selectedTab });
    },
    setSelectedTab: (selectedTab: number) => {
      set({ selectedTab: selectedTab });
    },
  };
});

export default useTabNavigationStore;
