import React, { useEffect, useState } from "react";
import { Window, WindowMoveEvent } from "@progress/kendo-react-dialogs";
import { WindowProps } from "@progress/kendo-react-dialogs";
import "./style.css";
import useIsMobileTemplate from "../../../utility/useIsMobileTemplate";

const windowMoveEventHandler = function (event: WindowMoveEvent) {
  // Der Code behebt den Bug,  der bei Bewegung des Windows den RichTextEditor markiert-.
  if (event.end === true) {
    document.body.style.userSelect = "auto";
    return;
  }
  document.body.style.userSelect = "none";
};

const ABKWindow = React.forwardRef<Window, WindowProps>((props, ref) => {
  const isMobile = useIsMobileTemplate();
  const [initialWidth, setInitialWidth] = useState(
    isMobile ? window.innerWidth : props.initialWidth || 800
  );

  useEffect(() => {
    const updateWidth = () => {
      if (window.innerWidth <= 768) {
        setInitialWidth(window.innerWidth);
      } else {
        setInitialWidth(props.initialWidth || 800);
      }
    };

    updateWidth();
    window.addEventListener("resize", updateWidth);

    return () => window.removeEventListener("resize", updateWidth);
  }, [props.initialWidth]);

  return (
    <Window
      {...props}
      className={`${props.className} abk-dialog`}
      ref={ref}
      initialWidth={initialWidth}
      onResize={windowMoveEventHandler}
      onMove={windowMoveEventHandler}
    />
  );
});

export default ABKWindow;
