import { useCallback, useEffect, useState } from "react";
import { Versioned } from "../../../core/interfaces/Versioned";
import _ from "lodash";
import { QueryObserverResult, RefetchOptions } from "@tanstack/react-query";
import { AxiosError } from "axios";
import useNotificationStore from "../../../core/stores/useNotificationStore";
import { Table } from "dexie";
import { useLiveQuery } from "dexie-react-hooks";

interface Draft<DataType> {
  etag: string;
  edited: DataType;
  sections: Map<string, boolean>;
}

interface UseDraftsProps<
  PageDataType,
  DraftType extends Draft<PageDataType>,
  KeyType,
> {
  is404: boolean;
  isFetching: boolean;
  initialPageState: Versioned<PageDataType> | undefined;
  initialSections?: Map<string, boolean>;
  backend: {
    doSave: (data: Versioned<PageDataType>) => Promise<unknown>;
    doRefetch: (
      options?: RefetchOptions
    ) => Promise<QueryObserverResult<Versioned<PageDataType>, Error>>;
  };
  drafts: {
    key: (draft: PageDataType) => KeyType;
    new: (
      data: Versioned<PageDataType>,
      sections: Map<string, boolean>
    ) => DraftType;
    query?: () => Promise<DraftType | "loading" | undefined>;
    table: Table<DraftType, KeyType>;
  };
}

/**
 * The hook to add Drafts logic to any Form / Page State
 * @param {boolean} is404 - If the initial page state is a 404 page
 * @param {boolean} isFetching - If the data is being refetched
 * @param {Versioned<FormDataType>} initialPageState - The initial State of a page which comes from the backend
 * @param {Map<string, boolean>} initialSections - The initial sections of the page (this is relevant for pages with multiple sections, i.e. ExpansionPanels)
 * @param {{doSave, doRefetch}} backend - functions to save and refetch data
 * @param {key, new, query?, table} - functions to manage the draft states and the table the drafts operate on
 */
const useDrafts = <
  FormDataType,
  DraftType extends Draft<FormDataType>,
  DraftKeyType,
>({
  is404,
  isFetching,
  initialPageState,
  initialSections,
  backend,
  drafts,
}: UseDraftsProps<FormDataType, DraftType, DraftKeyType>) => {
  // State variables for the drafts
  const [pageState, setPageState] = useState<Versioned<FormDataType>>();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [hasDraft, setHasDraft] = useState<boolean>(false);
  const [createdDraft, setCreatedDraft] = useState<boolean>(false);
  const [updatedDraft, setUpdatedDraft] = useState<boolean>(false);
  const [deletedDraft, setDeletedDraft] = useState<boolean>(false);
  const [lastUpdatedDraft, setLastUpdatedDraft] = useState<number>();

  const { addError } = useNotificationStore();

  const draft = useLiveQuery(
    async () => {
      if (drafts.query) {
        return drafts.query();
      }
      if (!initialPageState) {
        return "loading";
      }
      return drafts.table.get(drafts.key(initialPageState.value));
    },
    [initialPageState],
    "loading"
  );

  const [sections, setSections] = useState<Map<string, boolean>>(
    initialSections || new Map<string, boolean>()
  );

  // ---------------------------------------------------------
  // Helper function which checks if a specific state is different from the initial state
  // ---------------------------------------------------------
  const isChanged = useCallback(
    (versioned: Versioned<FormDataType>) => {
      return (
        !!initialPageState &&
        !_.isEqual(initialPageState.value, versioned.value)
      );
    },
    [initialPageState]
  );

  // ---------------------------------------------------------
  // Helper function which creates a new state from a versioned state
  // ---------------------------------------------------------
  const currentStateFrom = (versioned: Versioned<FormDataType>) => {
    return _.cloneDeep(versioned);
  };

  const isEdited = !!pageState && isChanged(pageState);

  // ---------------------------------------------------------
  // This effect will initialize the form from the draft
  // if it exist or from the loaded container
  // ---------------------------------------------------------
  useEffect(() => {
    if (draft === "loading" || (!initialPageState && !is404) || loaded) {
      return;
    }

    if (!draft && is404) {
      return;
    }

    let current = draft?.edited || initialPageState?.value;

    if (initialSections) {
      if (draft) {
        setSections(draft.sections);
      } else {
        setSections(initialSections);
      }
    }

    if (current) {
      current = _.cloneDeep(current);
      setPageState(
        currentStateFrom({
          value: current,
          etag: draft?.etag || initialPageState?.etag || "impossible fallback",
        })
      );
      setHasDraft(!!draft);
      setCreatedDraft(false);
      setUpdatedDraft(false);
      setDeletedDraft(false);
      setLastUpdatedDraft(undefined);
      setLoaded(true);
    }
  }, [draft, initialPageState, is404]);

  // ---------------------------------------------------------
  // This effect will deal with refetched container after save
  // ---------------------------------------------------------
  useEffect(() => {
    if (!loaded || !initialPageState || draft) {
      return;
    }

    if (isFetching) {
      return;
    }

    setPageState(currentStateFrom(initialPageState));
  }, [initialPageState, loaded, draft, isFetching]);

  // ---------------------------------------------------------
  // This effect manages updates to the draft
  // ---------------------------------------------------------
  useEffect(() => {
    if (!loaded) {
      return;
    }
    if (draft === "loading") {
      return;
    }
    if (hasDraft && !draft) {
      setDeletedDraft(true);
      setHasDraft(false);
      return;
    }
    if (!hasDraft && draft) {
      setHasDraft(true);
      setCreatedDraft(true);
      return;
    }
    if (hasDraft && draft) {
      setUpdatedDraft(true);
      return;
    }
  }, [draft, hasDraft, loaded]);

  // ---------------------------------------------------------
  // This effect only handle deleted drafts
  // ---------------------------------------------------------
  useEffect(() => {
    if (!initialPageState || !pageState) {
      return;
    }
    if (deletedDraft) {
      if (!document.hasFocus()) {
        setPageState(currentStateFrom(initialPageState));
        backend.doRefetch({ throwOnError: false });
      }
      setDeletedDraft(false);
    }
  }, [deletedDraft, pageState, isChanged, initialPageState]);

  // ---------------------------------------------------------
  // This effect only handles newly created or updated drafts from other tabs
  // ---------------------------------------------------------
  useEffect(() => {
    if (draft === "loading") {
      return;
    }
    if (!pageState) {
      return;
    }
    if (draft && (createdDraft || updatedDraft)) {
      if (!document.hasFocus() || lastUpdatedDraft === undefined) {
        setPageState(
          currentStateFrom({
            etag: draft.etag,
            value: draft.edited,
          })
        );
        setSections(draft.sections);
      }
      setCreatedDraft(false);
      setUpdatedDraft(false);
    }
  }, [pageState, draft, createdDraft, updatedDraft, lastUpdatedDraft]);

  // ---------------------------------------------------------
  // Helper function for saving the draft
  // ---------------------------------------------------------
  const handleSave = useCallback(async () => {
    if (draft === "loading" || !draft) {
      console.error("called save, but draft to save is unavailable");
      return;
    }

    if (pageState) {
      try {
        await backend.doSave(pageState);

        drafts.table.delete(drafts.key(draft.edited));
      } catch (error: any) {
        if (error instanceof AxiosError) {
          if (error.response?.status === 401) {
            // todo check if we can deactivate common error handling for this case
            return;
          }

          if (
            error.response?.status === 409 ||
            error.response?.status === 404
          ) {
            await backend.doRefetch({ throwOnError: false });
          }
        }
      }
    }
  }, [
    pageState,
    draft,
    initialPageState,
    drafts.table,
    backend.doRefetch,
    backend.doSave,
  ]);

  // ---------------------------------------------------------
  // Helper function for updating the sections
  // ---------------------------------------------------------
  const updateSections = (newSections: Map<string, boolean>) => {
    setSections(newSections);
    if (draft && draft !== "loading") {
      drafts.table.put({
        ...draft,
        sections: newSections,
      });
    }
  };

  // ---------------------------------------------------------
  // Helper function for updating the page state
  // ---------------------------------------------------------
  const updatePageState = (update: Partial<FormDataType>) => {
    if (is404) {
      // TODO: 404 leads to not being able to view the draft because we don't have the dataId
      return;
    }

    if (!initialPageState || !pageState) {
      return;
    }

    if (!document.hasFocus()) {
      // don't react to changes if the current window doesn't have the focus
      // this avoids a loop between two or more tabs/windows in the same browser
      // that are working on the same file
      return;
    }

    let updated: Versioned<FormDataType> = {
      value: {
        ...pageState!.value,
        ...update,
      },
      etag: pageState!.etag,
    };

    setPageState(updated);

    if (isChanged(updated)) {
      const newDraft: DraftType = drafts.new(updated, sections);
      setLastUpdatedDraft(Date.now());
      if (draft) {
        drafts.table.put(newDraft);
      } else {
        drafts.table.add(newDraft);
      }
    } else {
      setLastUpdatedDraft(undefined);
      drafts.table.delete(drafts.key(updated.value));
    }
  };

  // ---------------------------------------------------------
  // Callback function for resetting
  // ---------------------------------------------------------
  const handleReset = useCallback(() => {
    if (pageState && initialPageState) {
      setPageState(currentStateFrom(_.cloneDeep(initialPageState)));
      if (draft && draft !== "loading") {
        drafts.table.delete(drafts.key(draft.edited));
      }
    }
  }, [pageState, initialPageState, drafts.table]);

  // ---------------------------------------------------------
  // Conflict message for when something goes wrong
  // ---------------------------------------------------------
  const conflictMessage = is404
    ? "Die Datei wurde zwischenzeitlich gelöscht!"
    : initialPageState?.etag !== (draft as DraftType)?.etag
      ? "Die Datei wurde zwischenzeitlich geändert."
      : undefined;

  return {
    // Sections
    sections,
    updateSections,

    // state
    loaded,
    pageState,
    conflictMessage,

    // Draft functions
    isEdited,
    handleReset,
    handleSave,
    updatePageState,
  };
};

export default useDrafts;
