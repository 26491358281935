import { EditorToolsSettings, EditorTools } from "@progress/kendo-react-editor";

const customFontSizeSettings: EditorToolsSettings.StyleDropDownListSettings = {
  ...EditorToolsSettings.fontSize,
  items: [
    { text: "8px", value: "8px" },
    { text: "10px", value: "10px" },
    { text: "12px", value: "12px" },
    { text: "14px", value: "14px" },
    { text: "16px", value: "16px" },
    { text: "18px", value: "18px" },
    { text: "24px", value: "24px" },
    { text: "36px", value: "36px" },
  ],
};

// Erstelle das Schriftgrößen-Dropdown-Tool
const CustomFormatSize = EditorTools.createStyleDropDownList(
  customFontSizeSettings
);

const FontSizeTool = (props: any) => {
  return (
    <CustomFormatSize
      {...props}
      style={{
        width: "220px",
        ...props.style,
      }}
    />
  );
};

export default FontSizeTool;
