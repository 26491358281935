import {
  AppBar,
  AppBarSection,
  AppBarSpacer,
} from "@progress/kendo-react-layout";
import "./style.css";
import MenuButton from "./MenuButton";
import AbkColors from "../../../constants/AbkColors";
import { NavigationButtonItemProps } from "../../atoms/ABKNavigationButtonItem";
import React from "react";

interface Props {
  menuButtonItems?: NavigationButtonItemProps[];
  title: string;
  appbarSectionRight?: JSX.Element[];
  logo?: React.ReactElement;
  isDesktop: boolean;
}

const AbkAppBar = ({
  menuButtonItems,
  title,
  appbarSectionRight,
  logo,
  isDesktop,
}: Props) => {
  const isLandscape = window.matchMedia("(orientation: landscape)").matches;

  return (
    <div style={{ backgroundColor: AbkColors.green, color: AbkColors.white }}>
      <AppBar themeColor="inherit">
        <MenuButton items={menuButtonItems} isDesktop={isDesktop} />
        <AppBarSection>{logo}</AppBarSection>
        <AppBarSpacer style={{ width: 4 }} />
        {(isDesktop || isLandscape) && (
          <AppBarSection>
            <h1 className="appbar-title">{title}</h1>
          </AppBarSection>
        )}
        <AppBarSpacer />
        <AppBarSection className="app-bar-secion-right">
          {appbarSectionRight?.map((element, index) => (
            <React.Fragment key={index}>{element}</React.Fragment>
          ))}
        </AppBarSection>
      </AppBar>
      {!isDesktop && !isLandscape && (
        <div className="heading-mobile">
          <AppBarSection>
            <h1
              style={{
                fontSize: title.length > 46 ? "0.8rem" : "1rem",
                fontWeight: "normal",
              }}
              className="appbar-title"
            >
              {title}
            </h1>
          </AppBarSection>
        </div>
      )}
    </div>
  );
};

export default AbkAppBar;
