import {
  DropDownListChangeEvent,
  ListItemProps,
} from "@progress/kendo-react-dropdowns";
import ABKExpansionPanel from "../../../../../../abk-shared/components/atoms/ABKExpansionPanel";
import ABKFormDropdown from "../../../../../../abk-shared/components/atoms/ABKFormDropdown";
import ABKFormFieldInput from "../../../../../../abk-shared/components/atoms/ABKFormFieldInput";
import ABKForm from "../../../../../../abk-shared/components/molecules/ABKForm";
import { Eintrag } from "../../../../interfaces/parameterlisten";
import { useEintragBearbeitenState } from "../useEintragBearbeitenState";
import parTypeMapper, {
  hideParList,
  hideParMapper,
  parTypeList,
} from "../../maps";
import ABKMultipleInputsLine from "../../../../../../abk-shared/components/molecules/ABKMultipleInputsLine";
import React from "react";

const AllgemeinPanel = () => {
  const { pageState, setPageState } = useEintragBearbeitenState();

  return (
    <ABKExpansionPanel title="Allgemein">
      <ABKForm
        sections={[
          {
            formElements: [
              <ABKMultipleInputsLine
                elementLeft={
                  <ABKFormDropdown
                    label="Kategorie"
                    value={pageState?.ITEMTYPE}
                    valueRender={parTypeValueRender}
                    data={parTypeList}
                    itemRender={parTypeItemRender}
                    editorId="PARTYP"
                    onChange={(event: DropDownListChangeEvent) =>
                      setPageState({
                        ...pageState,
                        ITEMTYPE: event.value,
                      } as Eintrag)
                    }
                  />
                }
                elementRight={
                  <ABKFormFieldInput
                    label="Bezeichnung"
                    value={pageState?.BEZ}
                    editorId="BEZ"
                    setValue={(value: string) =>
                      setPageState({ ...pageState, BEZ: value } as Eintrag)
                    }
                  />
                }
              />,

              <ABKMultipleInputsLine
                elementLeft={
                  <ABKFormDropdown
                    label="Ausblenden"
                    value={pageState?.SHOWIF}
                    data={hideParList}
                    valueRender={hideParValueRender}
                    itemRender={hideParItemRender}
                    editorId="HIDEPAR"
                    onChange={(event: DropDownListChangeEvent) =>
                      setPageState({
                        ...pageState,
                        SHOWIF: event.value,
                      } as Eintrag)
                    }
                  />
                }
              />,
            ],
          },
        ]}
      />
    </ABKExpansionPanel>
  );
};

const parTypeItemRender = (
  li: React.ReactElement<HTMLLIElement>,
  itemProps: ListItemProps
) => {
  const mappedName = parTypeMapper.get(itemProps.dataItem);
  const itemChildren = <span>{mappedName}</span>;
  return React.cloneElement(li, li.props, itemChildren);
};

const parTypeValueRender = (
  element: React.ReactElement<HTMLSpanElement>,
  value: any
) => {
  if (!value) {
    return element;
  }
  const children = [<span>{parTypeMapper.get(value)}</span>];

  return React.cloneElement(
    element,
    {
      ...element.props,
    },
    children
  );
};

const hideParValueRender = (
  element: React.ReactElement<HTMLSpanElement>,
  value: any
) => {
  if (!value) {
    return element;
  }
  const children = [<span>{hideParMapper.get(value)}</span>];
  return React.cloneElement(element, element.props, children);
};

const hideParItemRender = (
  li: React.ReactElement<HTMLLIElement>,
  itemProps: ListItemProps
) => {
  const mappedName = hideParMapper.get(itemProps.dataItem);
  const itemChildren = <span>{mappedName}</span>;
  return React.cloneElement(li, li.props, itemChildren);
};

export default AllgemeinPanel;
