import { useMutation } from "@tanstack/react-query";
import { Eintrag } from "../../../interfaces/parameterlisten";
import axios from "axios";
import { useQueryClient } from "@tanstack/react-query";
import { useEintragBearbeitenState } from "./useEintragBearbeitenState";
import _ from "lodash";
const useSaveDataItem = ({
  itemId,
  dbname,
  contid,
  dataid,
  eintrag,
}: {
  itemId: string;
  dbname: string;
  contid: string;
  dataid: string;
  eintrag: Eintrag;
}) => {
  const queryClient = useQueryClient();
  const { setCurrentEintrag, setOpenDialog } = useEintragBearbeitenState();
  const fetchDataItem = async (itemId: string, eintrag: Eintrag) => {
    const response = await axios.put(
      `/api/db/${dbname}/cont/${contid}/pl/${dataid}/items/${itemId}`,
      eintrag,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  };

  return useMutation({
    mutationKey: ["saveDataItem"],
    mutationFn: () => fetchDataItem(itemId, eintrag),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["eintraege", contid] });
      console.log("data", data);
      setCurrentEintrag(_.cloneDeep(data));
    },
  });
};

export default useSaveDataItem;
