import { OnRefChangeType } from "react-resize-detector/build/types/types";
import useMutationObserver from "../../../../../utility/useMutationObserver";
import { WIDTH_COLUMN_SELECTED_FIELD } from "./COLUMN_SELECTED_FIELD";

function useHideDropClueIfOutsideGrid(
  wrapperGridRef: OnRefChangeType<HTMLDivElement>
) {
  useMutationObserver(
    (mutationsList) => {
      if (wrapperGridRef.current == null) return;

      for (const mutation of mutationsList) {
        if (mutation.type !== "childList") continue;

        mutation.addedNodes.forEach((node) => {
          const nodeIsDropClue =
            node.nodeType === 1 &&
            (node as any).classList &&
            (node as HTMLElement).classList.contains("k-grouping-dropclue");

          if (!nodeIsDropClue) return;

          if (wrapperGridRef.current == null) return; // Return for Typescript
          const dropClue = node as HTMLDivElement;

          const wrapperGridRectangle =
            wrapperGridRef.current.getBoundingClientRect();
          const dropClueRectangle = dropClue.getBoundingClientRect();

          const marginToShowTheDropClue = 3;
          const leftPositionAfterSelectedFields =
            wrapperGridRectangle.left +
            WIDTH_COLUMN_SELECTED_FIELD -
            marginToShowTheDropClue;

          const isInsideGrid =
            dropClueRectangle.top >= wrapperGridRectangle.top &&
            dropClueRectangle.left >= leftPositionAfterSelectedFields &&
            dropClueRectangle.bottom <= wrapperGridRectangle.bottom &&
            dropClueRectangle.right <= wrapperGridRectangle.right;

          if (!isInsideGrid) dropClue.style.display = "none";
        });
      }
    },
    { childList: true, subtree: true }
  );
}

export default useHideDropClueIfOutsideGrid;
