import { GridColumnProps } from "@progress/kendo-react-grid";
import React from "react";
import { State } from "@progress/kendo-data-query";
import { GridColumnPropsCustom } from "../../interfaces/GridColumns";
import { getColumnFromIndex } from "./columnUtils";

function useExpandLastColumnIfGridTooWide(
  grid: React.MutableRefObject<any>,
  columnsDisplayed: GridColumnPropsCustom[],
  setColumns: (columns: GridColumnPropsCustom[]) => void,
  dataState: State
) {
  const [shouldExpandLastColumn, setShouldExpandLastColumn] =
    React.useState(false);
  const columnsDuringLastResize = React.useRef<GridColumnProps[]>([]);

  React.useEffect(() => {
    if (!shouldExpandLastColumn) return;
    if (!grid.current?.offsetWidth) return;

    const { gridIsWider, remainingSpaceToFill } = isGridWiderThanColumns(
      grid.current.offsetWidth,
      columnsDuringLastResize.current,
      dataState
    );

    if (gridIsWider) {
      resizeLastColumnToFillEmptySpace(
        columnsDisplayed,
        remainingSpaceToFill,
        columnsDuringLastResize.current
      );

      setColumns([...columnsDisplayed]);
    }

    setShouldExpandLastColumn(false);
  }, [shouldExpandLastColumn]);

  return { setShouldExpandLastColumn, columnsDuringLastResize };
}

function isGridWiderThanColumns(
  gridWidth: number,
  columns: GridColumnPropsCustom[],
  dataState: State
) {
  let sumOfColumnWidths = columns.reduce(addColumnWidthToSum, 0);

  const numberOfGroups = dataState.group?.length ?? 0;
  const groupColumnWidth = 32;
  const offsetWidthForGroupedData = numberOfGroups * groupColumnWidth;
  sumOfColumnWidths += offsetWidthForGroupedData;

  const scrollBarWidthString = getComputedStyle(document.body).getPropertyValue(
    "--kendo-scrollbar-width"
  );
  const scrollBarWidth = scrollBarWidthString
    ? parseInt(scrollBarWidthString)
    : 17;

  const gridVisibleWidth = gridWidth - scrollBarWidth;
  const gridIsWider = gridVisibleWidth > sumOfColumnWidths;

  let remainingSpaceToFill = 0;
  if (gridIsWider) remainingSpaceToFill = gridVisibleWidth - sumOfColumnWidths;
  return { gridIsWider, remainingSpaceToFill };
}

function addColumnWidthToSum(sum: number, column: GridColumnPropsCustom) {
  const columnChildren = column.children as GridColumnPropsCustom[] | undefined;

  if (!Array.isArray(columnChildren)) {
    const columnWidth = getColumnWidth(column);
    sum += columnWidth;
    return sum;
  }

  for (const children of columnChildren) {
    const childrenWidth = getColumnWidth(children);
    sum += childrenWidth;
  }
  return sum;
}

function getColumnWidth(column: GridColumnPropsCustom | undefined) {
  if (column == null) return 0;

  let widthFromColumn = column.width;
  if (typeof widthFromColumn === "string")
    widthFromColumn = parseInt(widthFromColumn);

  const width = widthFromColumn || column.manualWidth || column.minWidth || 0;

  return width;
}

function resizeLastColumnToFillEmptySpace(
  columns: GridColumnPropsCustom[],
  remainingSpaceToFill: number,
  columnsDuringLastResize: GridColumnProps[]
) {
  const { parentColumnIndex, childrenColumnIndex } = getLastColumn(columns);
  if (parentColumnIndex === -1) return;

  const lastColumnInResize = getColumnFromIndex(
    columnsDuringLastResize,
    parentColumnIndex,
    childrenColumnIndex
  );
  const lastColumnWidth = getColumnWidth(lastColumnInResize);

  const newWidth = lastColumnWidth + remainingSpaceToFill;
  const lastColumn = getColumnFromIndex(
    columns,
    parentColumnIndex,
    childrenColumnIndex
  );
  if (lastColumn) lastColumn.manualWidth = newWidth;
}

function getLastColumn(columns: GridColumnPropsCustom[]) {
  let highestOrderIndex = 0;

  const result = {
    parentColumnIndex: -1,
    childrenColumnIndex: -1,
  };

  for (let i = 0; i < columns.length; i++) {
    const column = columns[i];
    const columnChildren = column.children as GridColumnPropsCustom[];

    if (!Array.isArray(columnChildren)) {
      const columnOrderIndex = column.orderIndex;
      if (columnOrderIndex === undefined) continue;

      if (columnOrderIndex > highestOrderIndex) {
        highestOrderIndex = columnOrderIndex;
        result.parentColumnIndex = i;
        result.childrenColumnIndex = -1;
      }
      continue;
    }

    for (let j = 0; j < columnChildren.length; j++) {
      const children = columnChildren[j];
      const childrenOrderIndex = children.orderIndex;
      if (childrenOrderIndex === undefined) continue;

      if (childrenOrderIndex > highestOrderIndex) {
        highestOrderIndex = childrenOrderIndex;
        result.parentColumnIndex = i;
        result.childrenColumnIndex = j;
      }
    }
  }

  return result;
}

export default useExpandLastColumnIfGridTooWide;
