import { createBrowserRouter, Navigate } from "react-router-dom";
import StartseiteLayout from "./Layout/Layout";
import NotFound from "./NotFound";
import DateiInfoPage from "../modules/Datei/pages/Allgemein";
import StartseiteIndex from "../modules/Startseite/pages";
import DateiLayout from "../modules/Datei/Layout";
import Cat from "../core/assets/images/cat.png";
import DateiIndexPage from "../modules/Datei";
import ModulesLayout from "../modules";
import AdminLayout from "../modules/Admin/layout";
import DatabasesPage from "../modules/Admin/pages/databases";
import HilfeLayout from "../modules/Hilfe/layout";
import UserLayout from "../modules/User/layout";
import SysInfoPage from "../modules/Hilfe/pages/SysInfoPage";
import UserProfilPage from "../modules/User/UserProfilPage";
import AppInfo from "../modules/Hilfe/pages/AppInfo";
import {
  ADMIN_DB_PROTOCOL_SUFFIX,
  FILE_AUFBAU_SUFFIX,
  FILE_EINTRAEGE_SUFFIX,
  FILE_GRAFIKEN_SUFFIX,
  FILE_KENNDATEN_SUFFIX,
  FILE_PIKTOGRAMME_SUFFIX,
  FILE_PROTOCOL_SUFFIX,
  FILE_WEITEREGLIEDERUNGSSYSTEME_SUFFIX,
  FILE_WEITEREPARAMETERLISTEN_SUFFIX,
  HELP_ADMIN_HELP_SUFFIX,
  LAST_USED,
  LOCAL_BROWSER_CONFIG_SUFFIX,
} from "../core/constants/urls";
import DbStatusPage from "../modules/Admin/pages/DbStatusPage";
import ServerStatusPage from "../modules/Admin/pages/ServerStatusPage";
import MainPageTemplate from "../core/components/templates/MainPageTemplate";
import { RequestType } from "../modules/Startseite/hooks/useGetCont";
import TabsLayout from "./Layout/TabsLayout";
import ServerInfoPage from "../modules/Admin/pages/ServerInfoPage";
import ServerLogPage from "../modules/Admin/pages/ServerLogPage";
import VerwaltenWrapper from "../modules/Startseite/pages/Verwalten";
import ErstellenWrapper from "../modules/Startseite/pages/Erstellen/ErstellenWrapper";
import OeffnenWrapper from "../modules/Startseite/pages/OpenPage/OeffnenWrapper";
import WrapperTemplate from "../core/components/templates/WrapperTemplate";
import DbProtocolPageWrapper from "../modules/Admin/pages/DbProcotolPage";
import DateiProtocolPageWrapper from "../modules/Datei/pages/DateiProtocolPage";
import ImportWrapper from "../modules/Startseite/pages/Import";
import Aufbau from "../modules/Datei/pages/Aufbau";
import Einträge from "../modules/Datei/pages/Einträge";
import Grafiken from "../modules/Datei/pages/Grafiken";
import Piktogramme from "../modules/Datei/pages/Piktogramme";
import WeitereGliederungssysteme from "../modules/Datei/pages/WeitereGliederungssysteme";
import WeitereParameterlisten from "../modules/Datei/pages/WeitereParameterlisten";
import KenndatenWrapper from "../modules/Datei/pages/Kenndaten";
import AdminHelpPage from "../modules/Hilfe/pages/AdminHelp";
import LocalBrowserConfiguration from "../modules/User/LocalBrowserConfiguration";

const Router = createBrowserRouter([
  {
    path: "/",
    children: [
      {
        index: true,
        element: (
          <>
            <MainPageTemplate includeTitle includeDescription />
            <Navigate to="/db/_default_" replace />
          </>
        ),
      },
      // DB Routes
      {
        path: "db/:db",
        element: <ModulesLayout />,
        children: [
          {
            index: true,
            element: (
              <>
                <WrapperTemplate includeDescription includeTitle />
                <Navigate to="start" replace />
              </>
            ),
          },
          // File Routes
          {
            path: "file",
            children: [
              { index: true, element: <DateiIndexPage /> },
              {
                path: ":contId",
                element: <DateiLayout />,
                children: [
                  { index: true, element: <Navigate to="info" replace /> },
                  { path: "info", element: <DateiInfoPage /> },
                  {
                    path: FILE_KENNDATEN_SUFFIX,
                    element: <KenndatenWrapper />,
                    /*
                      "children" ist für die Vorversion, auskommentiert, um einen Spur für die Vorversion
                      der Gliederungssysteme zu behalten.
                      Wenn die Vorversion für die Gliederungssystem implementiert ist,
                      dann kann der folgende Code gelöscht werden, mit diesem Kommentar.
                    */
                    // children: [
                    //   {
                    //     path: FILE_KENNDATEN_VORVERSION_SUFFIX,
                    //     element: <KenndatenWrapper isPreviousVersion={true} />,
                    //   },
                    // ],
                  },
                  { path: FILE_AUFBAU_SUFFIX, element: <Aufbau /> },
                  { path: FILE_EINTRAEGE_SUFFIX, element: <Einträge /> },
                  { path: FILE_GRAFIKEN_SUFFIX, element: <Grafiken /> },
                  { path: FILE_PIKTOGRAMME_SUFFIX, element: <Piktogramme /> },
                  {
                    path: FILE_WEITEREGLIEDERUNGSSYSTEME_SUFFIX,
                    element: <WeitereGliederungssysteme />,
                  },
                  {
                    path: FILE_WEITEREPARAMETERLISTEN_SUFFIX,
                    element: <WeitereParameterlisten />,
                  },

                  {
                    path: FILE_PROTOCOL_SUFFIX,
                    element: <DateiProtocolPageWrapper />,
                  },
                  { path: "cat", element: <img alt="cat" src={Cat} /> },
                ],
              },
            ],
          },
          // Start routes
          {
            path: "start",
            element: <StartseiteLayout />,
            children: [
              { index: true, element: <Navigate to="open" replace /> },
              {
                path: "open",
                element: <TabsLayout routePrefix="open" />,
                children: [
                  {
                    index: true,
                    element: (
                      <OeffnenWrapper
                        containerType={RequestType.ZuletztVerwendeteContainer}
                      />
                    ),
                  },
                  {
                    path: LAST_USED,
                    element: (
                      <OeffnenWrapper
                        containerType={RequestType.ZuletztVerwendeteContainer}
                      />
                    ),
                  },
                  {
                    path: "favorites",
                    element: (
                      <OeffnenWrapper
                        containerType={RequestType.MeineFavoriten}
                      />
                    ),
                  },
                  {
                    path: "own",
                    element: (
                      <OeffnenWrapper
                        containerType={RequestType.MeineContainer}
                      />
                    ),
                  },
                  {
                    path: "all",
                    element: (
                      <OeffnenWrapper
                        containerType={RequestType.AlleContainer}
                      />
                    ),
                  },
                ],
              },
              {
                path: "new",
                element: <TabsLayout routePrefix="new" />,
                children: [
                  {
                    index: true,
                    element: (
                      <ErstellenWrapper
                        containerType={RequestType.ZuletztVerwendeteVorlagen}
                      />
                    ),
                  },
                  {
                    path: LAST_USED,
                    element: (
                      <ErstellenWrapper
                        containerType={RequestType.ZuletztVerwendeteVorlagen}
                      />
                    ),
                  },
                  {
                    path: "all",
                    element: (
                      <ErstellenWrapper
                        containerType={RequestType.AlleVorlagen}
                      />
                    ),
                  },
                ],
              },
              {
                path: "files",
                element: <TabsLayout routePrefix="files" />,
                children: [
                  {
                    index: true,
                    element: (
                      <VerwaltenWrapper
                        containerType={RequestType.MeineFavoriten}
                      />
                    ),
                  },
                  {
                    path: LAST_USED,
                    element: (
                      <VerwaltenWrapper
                        containerType={RequestType.MeineFavoriten}
                      />
                    ),
                  },
                  {
                    path: "favorites",
                    element: (
                      <VerwaltenWrapper
                        containerType={RequestType.MeineFavoriten}
                      />
                    ),
                  },
                  {
                    path: "own",
                    element: (
                      <VerwaltenWrapper
                        containerType={RequestType.MeineContainer}
                      />
                    ),
                  },
                  {
                    path: "all",
                    element: (
                      <VerwaltenWrapper
                        containerType={RequestType.AlleContainer}
                      />
                    ),
                  },
                ],
              },
              { path: "import", element: <ImportWrapper /> },
            ],
          },
          // Admin Routes
          {
            path: "admin",
            element: <AdminLayout />,
            children: [
              { index: true, element: <Navigate to="org" replace /> },
              { path: "org", element: <h1>Admin Org Page</h1> },
              { path: "departments", element: <h1>Admin Departments Page</h1> },
              { path: "db-status", element: <DbStatusPage /> },
              {
                path: ADMIN_DB_PROTOCOL_SUFFIX,
                element: <DbProtocolPageWrapper />,
              },
              {
                path: "server-status",
                element: <ServerStatusPage />,
              },
              {
                path: "server-info",
                element: <ServerInfoPage />,
              },
              {
                path: "server-log",
                element: <ServerLogPage />,
              },
              { path: "databases", element: <DatabasesPage /> },
              { path: "licenses", element: <h1>Admin Lizenzen Page</h1> },
              {
                path: "users",
                element: <h1>Admin Users Page</h1>,
                children: [
                  {
                    path: ":userid",
                    element: <h1>Admin User Page</h1>,
                    children: [
                      {
                        path: "profile",
                        element: <h1>Admin User Profile Page</h1>,
                      },
                    ],
                  },
                ],
              },
              { path: "sysinfo", element: <h1>Admin Sysinfo Page</h1> },
            ],
          },
          // User Routes
          {
            path: "user",
            element: <UserLayout />,
            children: [
              { index: true, element: <Navigate to="profile" replace /> },
              { path: "profile", element: <UserProfilPage /> },
              {
                path: LOCAL_BROWSER_CONFIG_SUFFIX,
                element: <LocalBrowserConfiguration />,
              },
              {
                path: "options",
                children: [
                  { index: true, element: <h1>User Options Page</h1> },
                  { path: "view", element: <h1>User Options Ansicht Page</h1> },
                  {
                    path: "browser",
                    element: <h1>User Lokale Browser Options Page</h1>,
                  },
                  {
                    path: "ava",
                    element: <h1>User AVA Options Page</h1>,
                  },
                ],
              },
            ],
          },
        ],
      },
      // Old Routes
      {
        path: "startseite",
        element: <StartseiteLayout />,
        children: [{ index: true, element: <StartseiteIndex /> }],
      },

      // Help Routes
      {
        path: "help",
        element: <HilfeLayout />,
        children: [
          { index: true, path: "appinfo", element: <AppInfo /> },
          { path: "version", element: <h1>Help Version Page</h1> },
          { path: "sysinfo", element: <SysInfoPage /> },
          {
            path: HELP_ADMIN_HELP_SUFFIX,
            element: <AdminHelpPage />,
          },
          { path: "support", element: <h1>Help Support Page</h1> },
          { path: "impressum", element: <h1>Help Impressum Page</h1> },
          { path: "datenschutz", element: <h1>Help Datenschutz Page</h1> },
        ],
      },
      { path: "*", element: <NotFound /> },
      /*{path: 'dateiinfo/:db/datei/:contId', element: <DateiInfoPage/>,  },*/
    ],
  },
]);

export default Router;
