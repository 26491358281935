import React from "react";
import "./style.css";
import SideBarTitle from "./SideBarTitle";

const InfoHub = ({ infoHubOptions }: { infoHubOptions: React.ReactNode }) => {
  return (
    <>
      <SideBarTitle
        title="Info-Hub"
        hasOptions={true}
        options={infoHubOptions}
      />
    </>
  );
};

export default InfoHub;
