import { Notification } from "@progress/kendo-react-notification";
import InsideNotification from "./InsideNotification";
import { ABKNotificationCounterProps } from "../../../../abk-shared/components/atoms/ABKNotificationCounter";
import { INotification } from "../../../../abk-shared/components/organisms/ABKSideBar/NotificationBox";
import { Link } from "react-router-dom";
import { getFileInfoUrlForContainer } from "../../../utility/cont";
import { getDBFromJob } from "../../templates/MainPageTemplate/generateSidebarElements/job/generateJobNotification";
import { Command, Job, Status } from "../../../interfaces/Job";

enum statuses {
  success = "success",
  error = "error",
  loading = "loading",
}

export interface ABKJobNotificationProps {
  job: Job;
  containerName: string;
  notificationState: "loading" | "success" | "error";
  counterProps?: ABKNotificationCounterProps;
  buttonProps?: {
    text: string;
    onClick: () => void;
  };
  onClose?: () => void;
  jobText: string;
  onNotificationClick?: ((notification: INotification) => void) | undefined;
}

const ABKJobNotification = ({
  job,
  containerName,
  notificationState: status,
  counterProps,
  buttonProps,
  onClose,
  jobText,
}: ABKJobNotificationProps) => {
  let notificationType: {
    style?: "none" | "success" | "error" | "warning" | "info";
    icon?: boolean;
  } = { icon: false };
  let containerLink: React.ReactNode = null;

  const isFinishedImportJob =
    job.COMMAND === Command.Import && job.STATUS === Status.OK;
  if (isFinishedImportJob) {
    const database = getDBFromJob(job);
    containerLink = (
      <Link
        to={getFileInfoUrlForContainer(
          database,
          job.DefaultFile?.CONTID ?? job.CONTID
        )}
        className="tb-native-html-element-a"
        onClick={(event) => event.stopPropagation()}
      >
        {containerName}
      </Link>
    );
  }

  switch (status) {
    case statuses.success:
      notificationType = { style: "success", icon: true };
      break;
    case statuses.error:
      notificationType = { style: "error", icon: true };
      break;
    case statuses.loading:
      notificationType = { icon: false };
      break;
  }

  return (
    <Notification
      type={notificationType}
      closable={onClose !== undefined}
      className="notification"
      onClose={(event) => {
        event.syntheticEvent.stopPropagation();
        if (onClose) onClose();
      }}
    >
      <InsideNotification
        containerName={isFinishedImportJob ? containerLink : containerName}
        jobText={jobText}
        hasLoader={notificationType.icon === false}
        counterProps={counterProps}
        buttonProps={buttonProps}
      />
    </Notification>
  );
};

export default ABKJobNotification;
