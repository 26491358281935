import "./style.css";
import { useSwipeable } from "react-swipeable";

interface Props {
  open: boolean;
  onClose: () => void;
  position: "left" | "right";
  children: React.ReactNode;
}

const ABKMobileModal = ({ open, onClose, position, children }: Props) => {
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (position === "left") onClose();
    },
    onSwipedRight: () => {
      if (position === "right") onClose();
    },
    trackMouse: true,
  });

  return (
    <div
      {...handlers}
      className={`mobile-modal-container ${open ? "open" : ""} ${position}`}
    >
      <div className="mobile-modal-overlay" onClick={onClose}></div>
      <div className="mobile-modal-content">{children}</div>
    </div>
  );
};

export default ABKMobileModal;
