import OenormBold from "./ToolsCustomized/OenormBold";
import OenormItalic from "./ToolsCustomized/OenormItalic";
import oenormUnderline from "./ToolsCustomized/OenormUnderline";
import FontSizeTool from "./ToolsCustomized/FontSizeTool";
import FormatBlockTool from "./ToolsCustomized/FormatBlockTool";
import { InsertImage } from "./insertImageTool";
import CustomTableBorderButton from "./CustomTableBorderButton";
import * as React from "react";
import { EditorTools, EditorToolsSettings } from "@progress/kendo-react-editor";
import Undo = EditorTools.Undo;
import Redo = EditorTools.Redo;
import Subscript = EditorTools.Subscript;
import Superscript = EditorTools.Superscript;
import AlignLeft = EditorTools.AlignLeft;
import AlignCenter = EditorTools.AlignCenter;
import AlignRight = EditorTools.AlignRight;
import AlignJustify = EditorTools.AlignJustify;
import OrderedList = EditorTools.OrderedList;
import UnorderedList = EditorTools.UnorderedList;
import Indent = EditorTools.Indent;
import Outdent = EditorTools.Outdent;
import FontName = EditorTools.FontName;
import BackColor = EditorTools.BackColor;
import ForeColor = EditorTools.ForeColor;
import FindAndReplace = EditorTools.FindAndReplace;
import InsertTable = EditorTools.InsertTable;
import AddRowBefore = EditorTools.AddRowBefore;
import AddRowAfter = EditorTools.AddRowAfter;
import AddColumnBefore = EditorTools.AddColumnBefore;
import AddColumnAfter = EditorTools.AddColumnAfter;
import DeleteRow = EditorTools.DeleteRow;
import DeleteColumn = EditorTools.DeleteColumn;
import DeleteTable = EditorTools.DeleteTable;
import SplitCell = EditorTools.SplitCell;
import MergeCells = EditorTools.MergeCells;
import CleanFormatting = EditorTools.CleanFormatting;

// From: https://www.telerik.com/kendo-react-ui/components/editor/tools/#toc-customizing-built-in-tools
const CustomBackColor = (props: any) => (
  <BackColor
    {...props}
    colorPickerProps={{
      ...EditorToolsSettings.backColor.colorPickerProps,
      paletteSettings: {
        ...EditorToolsSettings.backColor.colorPickerProps.paletteSettings,
        palette: "basic",
      },
    }}
  />
);

const CustomForeColor = (props: any) => (
  <ForeColor
    {...props}
    colorPickerProps={{
      ...EditorToolsSettings.foreColor.colorPickerProps,
      paletteSettings: {
        ...EditorToolsSettings.foreColor.colorPickerProps.paletteSettings,
        palette: "basic",
      },
    }}
  />
);

const editorTools = {
  oenormTools: [
    [Undo, Redo],
    [OenormBold, OenormItalic, oenormUnderline],
    [Subscript, Superscript],
    [AlignLeft, AlignCenter, AlignRight, AlignJustify],
    [UnorderedList, OrderedList],
    [FindAndReplace],
    [InsertTable],
    FormatBlockTool,
  ],

  commonTools: [
    [Outdent, Indent],
    FontName,
    FontSizeTool,
    CustomBackColor,
    CustomForeColor,
    [CleanFormatting],

    [InsertImage],
  ],
  tableTools: [
    [CustomTableBorderButton],
    [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter],
    [DeleteRow, DeleteColumn, DeleteTable],
    [MergeCells, SplitCell],
  ],
};
export default editorTools;
