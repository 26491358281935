import ExtendedDrawerItemProps from "../../../core/interfaces/ExtendedDrawerItemProps";
import { replaceDBAndContInURL } from "../../../core/utility/cont";
import * as Icons from "../../../abk-shared/interfaces/abk9SvgIcons";
import {
  FILE_INFO,
  FILE_AUFBAU,
  FILE_EINTRAEGE,
  FILE_GRAFIKEN,
  FILE_KENNDATEN,
  FILE_PROTOCOL,
  FILE_PIKTOGRAMME,
  FILE_WEITEREGLIEDERUNGSSYSTEME,
  FILE_WEITEREPARAMETERLISTEN,
} from "../../../core/constants/urls";

const generateDrawerItem = (linkName: string, db: string, contId: string) => {
  const mappedName = DrawerItemsMap[linkName];

  if (!mappedName) {
    return null;
  }

  return {
    text: mappedName.text,
    svgIcon: mappedName.svgIcon,
    selected: mappedName.selected,
    route: replaceDBAndContInURL(mappedName.route, db, contId),
    title: mappedName.title,
  } as ExtendedDrawerItemProps;
};

const DrawerItemsMap: Record<string, ExtendedDrawerItemProps> = {
  "Datei-Info": {
    text: "Datei-Info",
    svgIcon: Icons.Menu_File_FileInfo,
    selected: true,
    route: FILE_INFO,
    title: "Datei-Info",
  },
  "Datei-Protokoll": {
    text: "Datei-Protokoll",
    svgIcon: Icons.Menu_File_FileLog,
    route: FILE_PROTOCOL,
    title: "Datei-Protokoll",
  },
  Kenndaten: {
    text: "Kenndaten",
    svgIcon: Icons.Dummy_Missing,
    route: FILE_KENNDATEN,
    title: "Kenndaten",
  },

  Einträge: {
    text: "Einträge",
    svgIcon: Icons.Dummy_Missing,
    route: FILE_EINTRAEGE,
    title: "Einträge",
  },

  Grafiken: {
    text: "Grafiken",
    svgIcon: Icons.Dummy_Missing,
    route: FILE_GRAFIKEN,
    title: "Grafiken",
  },

  Piktogramme: {
    text: "Piktogramme",
    svgIcon: Icons.Dummy_Missing,
    route: FILE_PIKTOGRAMME,
    title: "Piktogramme",
  },

  "Weitere Regelwerke": {
    text: "Weitere Regelwerke",
    svgIcon: Icons.Dummy_Missing,
    route: FILE_WEITEREGLIEDERUNGSSYSTEME,
    title: "Weitere Regelwerke",
  },

  "Weitere Parameterlisten": {
    text: "Weitere Parameterlisten",
    svgIcon: Icons.Dummy_Missing,
    route: FILE_WEITEREPARAMETERLISTEN,
    title: "Weitere Parameterlisten",
  },

  Aufbau: {
    text: "Aufbau",
    svgIcon: Icons.Dummy_Missing,
    route: FILE_AUFBAU,
    title: "Aufbau",
  },

  "Weitere Gliederungssysteme": {
    text: "Weitere Gliederungssysteme",
    svgIcon: Icons.Dummy_Missing,
    route: FILE_WEITEREGLIEDERUNGSSYSTEME,
    title: "Weitere Gliederungssysteme",
  },
};

export type DrawerItemsKeys = keyof typeof DrawerItemsMap;

export default generateDrawerItem;
