import useCurrentDBStore from "../../../../core/stores/useCurrentDBStore";
import DbStatusPage from "./DbStatusPage";

const DbStatusPageWrapper = () => {
  const { currentDB } = useCurrentDBStore();
  if (!currentDB) return <></>;

  return <DbStatusPage key={`db-status-${currentDB.DBNAME}`} />;
};

export default DbStatusPageWrapper;
