import { Breadcrumb } from "@progress/kendo-react-layout";
import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import URLMap from "./URLMap";
import { SvgIcon } from "@progress/kendo-react-common";
import * as Icons from "../../../../abk-shared/interfaces/abk9SvgIcons";
import { STARTSEITE } from "../../../constants/urls";
import useMainPageTemplateState, {
  MAIN_PAGE_TEMPLATE,
} from "../../../stores/useMainPageTemplateState";
import createBreadcrumbLink from "./createBreadcrumbLink";
import useCurrentDBStore from "../../../stores/useCurrentDBStore";

const StartBreadCrumb = {
  id: "startseite",
  text: "Startseite",
  icon: <SvgIcon icon={Icons.Menu_Start} style={{ marginRight: 5 }} />,
  path: STARTSEITE,
};

export default function Breadcrumbs() {
  const params = useParams();
  let database = params.db;
  const contId = params.contId;
  const { currentDB } = useCurrentDBStore();
  if (!database) database = currentDB?.DBNAME;

  const location = useLocation();
  const [breadcrumbs, setBreadcrumbs] = useState([StartBreadCrumb]);

  const { setMainPageTemplateTitle, setMainPageTemplateDescription } =
    useMainPageTemplateState();

  useEffect(() => {
    const segments = location.pathname.split("/").filter(Boolean);
    const breadcrumbData = segments
      .map((segment) => URLMap[segment as keyof typeof URLMap])
      .filter((it) => it);
    if (breadcrumbData.length > 0) {
      const lastElement = breadcrumbData[breadcrumbData.length - 1];

      breadcrumbData[breadcrumbData.length - 1] = {
        ...lastElement,
        disabled: true,
      } as any;
    }

    setBreadcrumbs([StartBreadCrumb, ...(breadcrumbData as any)]);

    const title = (breadcrumbData[breadcrumbData.length - 1] as any)?.title;
    /*
      Wir schreiben den "title", nur wenn es vorhanden ist.
      Wenn es nicht vorhanden ist, das bedeutet, dass das Kind-Component
      ein "setMainPageTemplateTitle" macht.
    */
    if (title) setMainPageTemplateTitle(title);

    setMainPageTemplateDescription(
      breadcrumbData[breadcrumbData.length - 1]?.description ||
        MAIN_PAGE_TEMPLATE.DESCRIPTION
    );
  }, [location]);

  return (
    <React.Fragment>
      <div className="breadcrumbs">
        <Breadcrumb
          size="medium"
          data={breadcrumbs}
          breadcrumbLink={(event) =>
            createBreadcrumbLink(event, database, contId)
          }
        />
      </div>
    </React.Fragment>
  );
}
