import { useQuery } from "@tanstack/react-query";
import { SysInfo } from "../../../core/interfaces/SysInfo";
import axios, { AxiosRequestConfig } from "axios";

const useGetVersion = (asHTML = false) => {
  const fetchSysInfo = async () => {
    try {
      const config: AxiosRequestConfig = {};

      if (asHTML) {
        config.headers = {
          Accept: "text/html",
        };
      }

      const response = await axios.get("/api/sysinfo", config);

      return response.data as SysInfo;
    } catch (error) {
      console.error("Fetching sysinfo failed:", error);
      throw error;
    }
  };

  return useQuery({
    queryKey: ["sysinfo"],
    queryFn: fetchSysInfo,
  });
};

export default useGetVersion;
