import Rive from "@rive-app/react-canvas";
import { Loader } from "@progress/kendo-react-indicators";

export const ABKPageLoader = () => (
  // <Rive src="../../../assets/animations/abk_loader.riv" />
  <div
    style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
  >
    <Loader type="converging-spinner" />
  </div>
);
