import "./style.css";
import { Label, LabelProps } from "@progress/kendo-react-labels";

type Props = LabelProps & { children: React.ReactNode };

export default function ABKLabel({ children, ...props }: Props) {
  return (
    <Label
      {...props}
      editorId={props.editorId}
      className="abk-form-field-input-label"
    >
      {children}
    </Label>
  );
}
