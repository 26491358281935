import { GridColumnProps } from "@progress/kendo-react-grid";
import { GridColumnPropsCustom } from "../../interfaces/GridColumns";
import COLUMN_SELECTED_FIELD from "./COLUMN_SELECTED_FIELD";

export function forEachColumns(
  columns: GridColumnPropsCustom | GridColumnPropsCustom[],
  callback: (
    column: GridColumnPropsCustom,
    hasChildren: boolean,
    depth: number
  ) => unknown,
  depth = 0
) {
  if (!Array.isArray(columns)) return callback(columns, false, depth);

  columns.forEach((column) => {
    const columnChildren = column.children;
    const hasChildren = columnHasChildren(column);
    if (!hasChildren) return callback(column, hasChildren, depth);

    callback(column, hasChildren, depth);
    forEachColumns(
      columnChildren as GridColumnPropsCustom[],
      callback,
      depth + 1
    );
  });
}

export const isColumnShown = (column: GridColumnPropsCustom) =>
  column.show === true || column.show == null;

const columnHasChildren = (column: GridColumnPropsCustom) =>
  !!column.children && column.children.length > 0;

export function editOrderIndexOfColumns(
  columns: GridColumnPropsCustom[],
  reorderedColumns: GridColumnProps[],
  lockedColumnFields: string[]
) {
  for (let i = 0; i < columns.length; i++) {
    const column = columns[i];

    const isColumnLocked =
      column.field && lockedColumnFields.includes(column.field);
    if (!isColumnLocked) column.orderIndex = reorderedColumns[i].orderIndex;

    const columnChildren = column.children as GridColumnProps[] | undefined;
    const reorderedColumnChildren = reorderedColumns[i].children as
      | GridColumnProps[]
      | undefined;

    if (Array.isArray(columnChildren)) {
      for (let j = 0; j < columnChildren.length; j++) {
        const children = columnChildren[j] as GridColumnProps;

        const isChildrenLocked =
          children.field && lockedColumnFields.includes(children.field);
        if (isChildrenLocked) continue;

        const reorderedIndex = reorderedColumnChildren?.[j]?.orderIndex;
        if (reorderedIndex !== undefined) children.orderIndex = reorderedIndex;
      }
    }
  }
}

export function getColumnIndexFromId(
  columns: GridColumnProps[],
  targetColumnId: string | undefined
) {
  const result = {
    parentColumnIndex: -1,
    childrenColumnIndex: -1,
  };

  for (let i = 0; i < columns.length; i++) {
    const column = columns[i];
    if (column.id === targetColumnId) {
      result.parentColumnIndex = i;
      return result;
    }

    const columnChildren = column.children as GridColumnProps[] | undefined;
    if (Array.isArray(columnChildren)) {
      for (let j = 0; j < columnChildren.length; j++) {
        const children = columnChildren[j];
        if (children.id === targetColumnId) {
          result.parentColumnIndex = i;
          result.childrenColumnIndex = j;
          return result;
        }
      }
    }
  }

  return result;
}

export function getWidthResizedOfColumn(
  columns: GridColumnProps[],
  parentColumnIndex: number,
  childrenColumnIndex: number,
  fallbackWidth: number
) {
  const targetColumn = getColumnFromIndex(
    columns,
    parentColumnIndex,
    childrenColumnIndex
  );

  const widthResized = targetColumn?.width;
  if (widthResized === undefined) return fallbackWidth;

  if (typeof widthResized === "number") return widthResized;

  const widthResizedNumber = parseInt(widthResized);
  const isValidNumber = !Number.isNaN(widthResizedNumber);
  if (isValidNumber) return widthResizedNumber;

  return fallbackWidth;
}

export function getColumnFromIndex(
  columns: GridColumnPropsCustom[],
  parentColumnIndex: number,
  childrenColumnIndex: number
) {
  if (childrenColumnIndex === -1)
    return columns[parentColumnIndex] as GridColumnPropsCustom | undefined;

  return columns[parentColumnIndex].children?.[childrenColumnIndex] as
    | GridColumnPropsCustom
    | undefined;
}

export function createColumnsDisplayedFromColumns(
  columns: GridColumnPropsCustom[],
  isSelectionEnabled: boolean
) {
  const shownColumns: GridColumnPropsCustom[] = getShownColumns(columns);

  if (isSelectionEnabled) return [COLUMN_SELECTED_FIELD, ...shownColumns];

  return shownColumns;
}

export function getShownColumns(columns: GridColumnPropsCustom[]) {
  const shownColumns: GridColumnPropsCustom[] = [];

  for (const column of columns) {
    if (!isColumnShown(column)) continue;

    if (!columnHasChildren(column)) {
      shownColumns.push({ ...column });
      continue;
    }

    const newChildren: GridColumnPropsCustom[] = [];
    const children = column.children as GridColumnPropsCustom[];
    for (const child of children) {
      if (!isColumnShown(child)) continue;

      newChildren.push({ ...child });
    }

    shownColumns.push({
      ...column,
      children: newChildren,
    });
  }

  return shownColumns;
}

export function createColumnsFromColumnsDisplayed(
  columns: GridColumnPropsCustom[],
  columnsDisplayed: GridColumnPropsCustom[]
) {
  const columnsCreated: GridColumnPropsCustom[] = [];

  for (const column of columns) {
    const correspondingColumnDisplayed = getColumnDisplayedCorrespondingToField(
      columnsDisplayed,
      column.field
    );

    const newColumn = correspondingColumnDisplayed
      ? correspondingColumnDisplayed
      : column;

    if (!columnHasChildren(column)) {
      columnsCreated.push({ ...newColumn });
      continue;
    }

    const childrenCreated = mergeChildrenWithColumnsDisplayed(
      column.children as GridColumnPropsCustom[],
      columnsDisplayed
    );

    columnsCreated.push({
      ...newColumn,
      children: childrenCreated,
    });
  }

  return columnsCreated;
}

function getColumnDisplayedCorrespondingToField(
  columnsDisplayed: GridColumnPropsCustom[],
  field: string | undefined
) {
  let correspondingColumnDisplayed = null;

  forEachColumns(columnsDisplayed, (columnDisplayed) => {
    if (columnDisplayed.field === field)
      correspondingColumnDisplayed = columnDisplayed;
  });

  return correspondingColumnDisplayed as GridColumnPropsCustom | null;
}

function mergeChildrenWithColumnsDisplayed(
  children: GridColumnPropsCustom[],
  columnsDisplayed: GridColumnPropsCustom[]
) {
  const childrenCreated = [];

  for (const child of children as GridColumnPropsCustom[]) {
    const correspondingChildDisplayed = getColumnDisplayedCorrespondingToField(
      columnsDisplayed,
      child.field
    );

    const newChild = correspondingChildDisplayed
      ? correspondingChildDisplayed
      : child;

    childrenCreated.push({
      ...newChild,
    });
  }

  return childrenCreated;
}
