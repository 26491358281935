import { convertHtmlToOenorm } from "./CustomViewHtml";
import "./style.css";
import ABKWindow from "../../../../../abk-shared/components/molecules/ABKWindow";

interface CustomHTMLDialogProps {
  value: string;
  setShowDialogWindow: (value: boolean) => void;
}

const stripHtmlTags = (str: string): string => {
  return str.replace(/<(\w+)\s*><\/\1\s*>/gm, "");
};

const CustomHTMLDialog = ({
  setShowDialogWindow,
  value,
}: CustomHTMLDialogProps) => {
  const plainText = stripHtmlTags(convertHtmlToOenorm(value));

  return (
    <ABKWindow
      className={"HTMLWindow"}
      title={"HTML-Ansicht"}
      onClose={() => setShowDialogWindow(false)}
      initialWidth={800}
      initialHeight={600}
    >
      {plainText ? <pre className="html-content">{plainText}</pre> : null}
    </ABKWindow>
  );
};

export default CustomHTMLDialog;
