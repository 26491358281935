import ABKExpansionPanel from "../../../../abk-shared/components/atoms/ABKExpansionPanel";
import ABKFormFieldInput from "../../../../abk-shared/components/atoms/ABKFormFieldInput";

export default function AdminHelpPage() {
  const tenantName = window.location.hostname;
  const hrefAllgemein = `https://editor.swagger.io/?url=https://${tenantName}/api/files/openapi.yaml`;
  const hrefParameterlisten = `https://editor.swagger.io/?url=https://${tenantName}/api/files/openapi_pl.yaml`;

  return (
    <div className="k-min-w-full">
      <ABKExpansionPanel title="REST-API-Dokumentation" defaultExpanded={true}>
        <ABKFormFieldInput
          editorId="allgemein-rest-api-documentation"
          label="Allgemein"
          useJSXForValue={true}
          value={
            <a href={hrefAllgemein} target="_blank" rel="noreferrer">
              {hrefAllgemein}
            </a>
          }
        />
        <ABKFormFieldInput
          editorId="parameterlisten-rest-api-documentation"
          label="Parameterlisten"
          useJSXForValue={true}
          value={
            <a href={hrefParameterlisten} target="_blank" rel="noreferrer">
              {hrefParameterlisten}
            </a>
          }
        />
      </ABKExpansionPanel>
    </div>
  );
}
