import { GridColumnMenuCheckboxFilter } from "@progress/kendo-react-grid";

export const ABKColumnMenu = (props: any) => {
  const { data } = props;

  return (
    <div>
      <GridColumnMenuCheckboxFilter
        {...props}
        /*  column={{ ...props.column, field: "dateFilterValue" }}*/
        data={data}
        expanded={true}
        searchBox={() => null}
      />
    </div>
  );
};
