import { useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import useAppBarState from "../../core/stores/useAppBarState";
import useDrawerStore from "../../core/stores/useDrawerStore";
import {
  DATABASE_PLACEHOLDER,
  FILES,
  IMPORT,
  OPEN,
  TEMPLATES,
} from "../../core/constants/urls";
import { changeFavIcon } from "../../core/utility/favIcon";
import {
  Menu_Start_FileNew,
  Menu_Start_FileManage,
  General_Import,
  Menu_Start_FileOpen,
} from "../../abk-shared/interfaces/abk9SvgIcons";
import WrapperTemplate from "../../core/components/templates/WrapperTemplate";
import useCurrentDBStore from "../../core/stores/useCurrentDBStore";
import { DB } from "../../abk-shared/interfaces/db";

const Layout = () => {
  const { setAppBarTitle } = useAppBarState();
  const { setCurrentDrawerItems } = useDrawerStore();

  const params = useParams();
  const dbInUrl = params.db ?? "";

  const { currentDB } = useCurrentDBStore();
  const isValidDB = !!currentDB && currentDB.DBNAME === dbInUrl;
  const dbName = isValidDB ? (currentDB as DB).DBNAME : dbInUrl;

  useEffect(() => {
    const drawerItems = [
      {
        text: "Öffnen",
        svgIcon: Menu_Start_FileOpen,
        selected: true,
        route: OPEN.replace(DATABASE_PLACEHOLDER, dbName),
        title: "Öffnen",
      },
      {
        text: "Erstellen",
        svgIcon: Menu_Start_FileNew,
        selected: true,
        route: TEMPLATES.replace(DATABASE_PLACEHOLDER, dbName),
        title: "Erstellen",
      },
      {
        text: "Verwalten",
        svgIcon: Menu_Start_FileManage,
        route: FILES.replace(DATABASE_PLACEHOLDER, dbName),
        title: "Verwalten",
      },
      {
        text: "Importieren",
        svgIcon: General_Import,
        route: IMPORT.replace(DATABASE_PLACEHOLDER, dbName),
        title: "Importieren",
      },
    ];

    setAppBarTitle("Startseite");
    setCurrentDrawerItems(drawerItems);
    changeFavIcon("/favicon.ico");
  }, [dbName, setAppBarTitle, setCurrentDrawerItems]);

  return (
    <>
      <WrapperTemplate includeDescription includeTitle>
        <Outlet />
      </WrapperTemplate>
    </>
  );
};

export default Layout;
