import { Navigate, useLocation, useOutlet } from "react-router-dom";
import { useEffect } from "react";
import "./style.css";
import { LAST_USED } from "../../core/constants/urls";
import useTabNavigationStore from "../../core/stores/useTabNavigationStore";

const TabsLayout = ({ routePrefix }: { routePrefix: string }) => {
  const { setTabs, resetTabs } = useTabNavigationStore();
  const outlet = useOutlet();
  const location = useLocation();
  const tabs =
    routePrefix === "new"
      ? [
          {
            id: 0,
            label: "Zuletzt verwendet",
            to: `${routePrefix}/${LAST_USED}`,
          },
          { id: 1, label: "Alle Vorlagen", to: `${routePrefix}/all` },
        ]
      : [
          {
            id: 0,
            label: "Zuletzt verwendet",
            to: `${routePrefix}/${LAST_USED}`,
          },
          { id: 1, label: "Favoriten", to: `${routePrefix}/favorites` },
          { id: 2, label: "Eigene", to: `${routePrefix}/own` },
          { id: 3, label: "Alle", to: `${routePrefix}/all` },
        ];

  useEffect(() => {
    setTabs(tabs);
    return () => resetTabs();
  }, []);

  useEffect(() => {
    resetTabs();
    setTabs(tabs);
  }, [location, routePrefix]);

  return <>{outlet || <Navigate to={LAST_USED} />}</>;
};

export default TabsLayout;
