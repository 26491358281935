import { useEffect } from "react";
import { useQuickInfoState } from "../../../abk-shared/stores/useQuickInfoState";
import useAppBarState from "../../../core/stores/useAppBarState";
import { changeFavIcon } from "../../../core/utility/favIcon";
import useGetContInfo from "../../Startseite/hooks/useGetContInfo";
import { formatDateTimeToBrowserDefault } from "../../../abk-shared/utility/dateUtils";

export default function useInitiatePageWithContainerData(
  db: string,
  contId: string
) {
  const { setAppBarTitle } = useAppBarState();
  const { setQuickInfo } = useQuickInfoState();

  const { data: containerAndEtag } = useGetContInfo(db, contId);

  useEffect(() => {
    const container = containerAndEtag?.value;
    if (container) {
      setAppBarTitle(container.BEZ);

      if (container.ContTypeInfo && container.ContTypeInfo.ICONID) {
        changeFavIcon(
          "/assets/icons/" + container.ContTypeInfo.ICONID + ".svg"
        );
      }

      setQuickInfo([
        {
          title: "Dateiname",
          content: container.BEZ,
        },
        {
          title: "Besitzer",
          content: container.OWNER,
        },
        {
          title: "Änderungsdatum",
          content: formatDateTimeToBrowserDefault(container.LASTCHANGED),
        },
        {
          title: "Anmerkung",
          content: container.INTANMERK || "Keine Anmerkung vorhanden",
        },
      ]);
    }
  }, [containerAndEtag, setAppBarTitle, setQuickInfo]);
}
