import { useIsValidDbAndContIdInURL } from "../../../../core/utility/useIsValidDB";
import Allgemein from "./allgemein";
import "./DateiInfoPage.css";

const FileInfoWrapper = () => {
  const { isValid, db, contId } = useIsValidDbAndContIdInURL();
  if (!isValid) return <></>;

  return (
    <Allgemein
      key={`file-info-${db}-${contId}`}
      database={db as string}
      contId={contId as string}
    />
  );
};

export default FileInfoWrapper;
