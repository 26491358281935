import { ReactNode } from "react";
import AllgemeinPanel from "./AllgemeinPanel";
import KommentarPanel from "./KommentarPanel";
import AuswahllistePanel from "./AuswahllistePanel";
import ZuordnungFunktionenPanel from "./ZuordnungFunktionenPanel";
import ZuordnungProduktgruppenPanel from "./ZuordnungProduktgruppenPanel";
import ZahlPanel from "./ZahlPanel";
import ParameterPanel from "./ParameterPanel";

export const parTypePanelsMap: Map<string, ReactNode[]> = new Map([
  ["Head", [<AllgemeinPanel />, <KommentarPanel />]],
  [
    "Set",
    [
      <AllgemeinPanel />,
      <KommentarPanel />,
      <ZuordnungFunktionenPanel />,
      <ZuordnungProduktgruppenPanel />,
    ],
  ],
  [
    "Num",
    [
      <AllgemeinPanel />,
      <ZahlPanel />,
      <AuswahllistePanel />,
      <KommentarPanel />,
      <ParameterPanel />
    ],
  ],
  ["Text", [<AllgemeinPanel />, <AuswahllistePanel />, <ParameterPanel />]],
  ["Memo", [<AllgemeinPanel />, <KommentarPanel />, <ParameterPanel />]],
  ["Date", [<AllgemeinPanel />, <KommentarPanel />, <ParameterPanel />]],
  ["Bool", [<AllgemeinPanel />, <KommentarPanel />, <ParameterPanel />]],
]);
