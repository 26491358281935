import React, { useCallback, useRef } from "react";

interface ResizeHandleProps {
  resizeable: boolean;
  drawerWidth: number;
  setDrawerWidth: React.Dispatch<React.SetStateAction<number>>;
}

const ResizeHandle = ({
  drawerWidth,
  setDrawerWidth,
  resizeable,
}: ResizeHandleProps) => {
  const resizeHandleRef = useRef<HTMLDivElement>(null);

  const startResizing = useCallback(
    (mouseDownEvent: MouseEvent) => {
      const startX = mouseDownEvent.clientX;
      const startWidth = drawerWidth;

      const handleMouseMove = (mouseMoveEvent: MouseEvent) => {
        mouseMoveEvent.preventDefault();
        const currentX = mouseMoveEvent.clientX;
        const diffX = currentX - startX;
        const newWidth = Math.max(100, Math.min(500, startWidth + diffX)); // Constrain between 100 and 500 for example
        setDrawerWidth(newWidth);
        localStorage.setItem("drawerWidth", newWidth.toString());
      };

      const handleMouseUp = () => {
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
      };

      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    },
    [drawerWidth, setDrawerWidth]
  );

  React.useEffect(() => {
    if (resizeable) {
      const handle = resizeHandleRef.current;
      if (handle) {
        handle.addEventListener("mousedown", startResizing);
      }

      return () => {
        if (handle) {
          handle.removeEventListener("mousedown", startResizing);
        }
      };
    }
  }, [startResizing, resizeable]);

  if (resizeable) {
    return <div className="resize-handle" ref={resizeHandleRef}></div>;
  } else {
    return <div className="resize-handle-placeholder" />;
  }
};

export default ResizeHandle;
