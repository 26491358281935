import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { DB } from "../../../../abk-shared/interfaces/db";

interface dbGridProps {
  databases: DB[];
}

const DBGrid = ({ databases }: dbGridProps) => {
  return (
    <Grid data={databases} style={{ height: "400px" }}>
      <GridColumn field="DBNAME" title="Name" />
      <GridColumn field="DBTYPE" title="Typ" />
      <GridColumn field="SERVER" title="Server" />
      <GridColumn field="DATABASE" title="Datenbank" />
      <GridColumn field="DBSTATUS" title="Status" />
    </Grid>
  );
};

export default DBGrid;
